import {
  Button,
  Card,
  FlexBox,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip
} from '@cimpress/react-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ConditionSelector from './ConditionSelector';
import {Condition, PipelineStep, StepDefinition, StepParameter} from '../types/pipeline';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {PropertyBag} from '../types/property-info';
import {TreeSelect} from 'antd';
import {DataNode} from 'antd/lib/tree';
import TextInput from 'react-autocomplete-input';
import {button, buttonDanger} from '../types/button-styles';

function EditablePipelineStep({
  s,
  propertyBags,
  variablePropertyBags,
  fileTypes,
  stepDefinitions,
  hideReorderUp,
  hideReorderDown,
  headerName,
  reorderStep,
  updateStepCondition,
  removeStepCondition,
  getParameters,
  removeSelf,
  addStepCondition,
  updateStepActionType,
  updateStepActionValue,
  copySelf,
  disabled
}:
  {
    s: PipelineStep,
    propertyBags: PropertyBag[],
    variablePropertyBags: PropertyBag[],
    fileTypes: string[],
    stepDefinitions: StepDefinition[],
    hideReorderUp: boolean,
    hideReorderDown: boolean,
    headerName: string,
    reorderStep: (directionMoved: number) => void,
    updateStepCondition: (conditionIndex: number, condition: Condition) => void,
    removeStepCondition: (conditionIndex: number) => void,
    getParameters: (step: PipelineStep) => StepParameter[],
    removeSelf: () => void,
    addStepCondition: () => void,
    updateStepActionType: (newStepType: any) => void,
    updateStepActionValue: (paramName: string, newValue: string) => void,
    copySelf: () => void,
    disabled: boolean
  }): ReactElement {
  const [treeData, setTreeData] = useState([] as DataNode[]);

  useEffect(() => {
    setTreeData(variablePropertyBags.map(pb => {
      return {
        title: pb.propertyBagName,
        value: pb.propertyBagName,
        selectable: false,
        children: pb.properties.map(p => {
          return {
            title: p.propertyName,
            value: `${pb.propertyBagName}|${p.propertyName}`
          };
        })
      } as any;
    }));
  }, [variablePropertyBags]);

  const pipeLineVariableOptions = useMemo(() => variablePropertyBags.flatMap(pb => pb.properties.map(p => `{PIPELINE_VARIABLE:${pb.propertyBagName}:${p.propertyName}}`)), [variablePropertyBags]);

  const getPropertyBagNameFromTreeData = (value: string | undefined): string | undefined => {
    return value ? value.split('|')[0] : undefined;
  };

  const getPropertyNameFromTreeData = (value: string | undefined): string | undefined => {
    return value ? value.split('|')[1] : undefined;
  };

  return (<>
    <Card header={headerName}>
      <FlexBox alignItems="flex-start" justifyContent="space-between">
        <h6>If these conditions are met:</h6>
        {!disabled && <FlexBox className="button-group" justifyContent="right">
          <Button
            size="sm"
            variant="primary"
            className={buttonDanger}
            onClick={() => {
              removeSelf();
            }
            }>
            <FontAwesomeIcon icon="trash"/> Remove Step
          </Button>
          <Tooltip contents="Duplicate Step (Insert Below)">
            <Button size="sm" onClick={copySelf}><FontAwesomeIcon
              icon="copy"/></Button>
          </Tooltip>
          <Tooltip contents="Move Up">
            <Button size="sm" disabled={hideReorderUp}
                    onClick={() => reorderStep(-1)}><FontAwesomeIcon
              icon="arrow-up"/></Button>
          </Tooltip>
          <Tooltip contents="Move Down">
            <Button size="sm" disabled={hideReorderDown}
                    onClick={() => reorderStep(1)}><FontAwesomeIcon
              icon="arrow-down"/></Button>
          </Tooltip>
        </FlexBox>}
      </FlexBox>
      <br/>
      {s.conditions.length == 0 &&
      <><Card>This step&apos;s action will always be executed because there are no
        conditions.</Card><br/></>
      }
      {s.conditions.map((c, conditionIndex) =>
        <>
          <ConditionSelector key={conditionIndex}
                             condition={c}
                             updateStepCondition={(condition) => updateStepCondition(conditionIndex, condition)}
                             removeStepCondition={() => removeStepCondition(conditionIndex)}
                             propertyBags={propertyBags}
                             conditions={s.conditions}
                             fileTypes={fileTypes}
                             disabled={disabled}/>
          <br/>
        </>
      )}
      <FlexBox justifyContent="flex-end">
        {!disabled && <Button variant="secondary" className={button}
                size="sm"
                disabled={disabled}
                onClick={() => {
                  addStepCondition();
                }}>
          <FontAwesomeIcon icon="plus"/> Add Condition
        </Button>}
      </FlexBox>
      <h6>Take this action:</h6>
      <Card>
        <Select
          isClearable
          label="Select step action type"
          isDisabled={disabled}
          value={s.id ? {
            label: s.id as string,
            value: s.id as string
          } : undefined}
          options={stepDefinitions.map(step => {
            return {
              label: step.id,
              value: step.id
            };
          })}
          onChange={(value: any) => {
            const newStepType = value?.value ?? undefined;
            updateStepActionType(newStepType);
          }}
        />
        <hr/>
        {getParameters(s).length > 0 ? <h6>Step Parameters</h6> :
          <h5>This step has no parameters.</h5>}
        {getParameters(s).map(param => {
          const paramValue = s.parameters[param.name];
          let selectComponent: ReactElement;
          if (paramValue?.startsWith('$PIPELINE_VARIABLE')) {
            const matches = paramValue?.match(/^\$PIPELINE_VARIABLE:(.+):(.+)$/);
            const propertyBagName = matches && matches?.length === 3 ? matches[1] : undefined;
            const propertyName = matches && matches?.length === 3 ? matches[2] : undefined;

            selectComponent =
              <TreeSelect
                treeData={treeData}
                value={matches && matches?.length === 3 ? `${propertyBagName}|${propertyName}` : undefined}
                size="large"
                placeholder="Select a Pipeline Variable"
                style={{width: '100%'}}
                showSearch={true}
                treeDefaultExpandAll={true}
                allowClear={true}
                disabled={disabled}
                onChange={value => {
                  const newName = getPropertyNameFromTreeData(value);
                  const newBag = getPropertyBagNameFromTreeData(value);
                  updateStepActionValue(param.name, `$PIPELINE_VARIABLE:${newBag}:${newName}`);
                }}
              />;
          } else if (param.supportsLoadBalancing && paramValue?.includes(';')) {
            const folders = paramValue?.split(';');
            selectComponent = <>
              {folders?.map((value, i) => <TextInput trigger={['$']}
                                                     options={{'$': pipeLineVariableOptions}}
                                                     maxOptions={0}
                                                     regex={'^[A-Za-z0-9\\-_\\.:{}]+$'}
                                                     rightAddon={<Button className={buttonDanger} disabled={disabled} onClick={() => {
                                                       const f = paramValue.split(';');
                                                       f.splice(i, 1);
                                                       updateStepActionValue(param.name, f.join(';'));
                                                     }}><FontAwesomeIcon icon="minus"/> Remove</Button>}
                                                     Component={TextField} spacer="" matchAny
                                                     value={value}
                                                     disabled={disabled}
                                                     onChange={(v: any) => {
                                                       const f = paramValue.split(';');
                                                       f[i] = v;
                                                       updateStepActionValue(param.name, f.join(';'));
                                                     }}
                                                     key={i}
              />)}
            </>;
          } else {
            selectComponent = <TextInput trigger={['$']}
                                         options={{'$': pipeLineVariableOptions}}
                                         maxOptions={0}
                                         regex={'^[A-Za-z0-9\\-_\\.:{}]+$'}
                                         Component={TextField} spacer="" matchAny
                                         value={paramValue}
                                         disabled={disabled}
                                         onChange={(v: any) => {
                                           updateStepActionValue(param.name, v);
                                         }}
            />;
          }

          return <>
            <RadioGroup onChange={(_, v) => {
              if (v === 'custom') {
                updateStepActionValue(param.name, '');
              } else {
                updateStepActionValue(param.name, '$PIPELINE_VARIABLE');
              }
            }} valueSelected={paramValue?.startsWith('$PIPELINE_VARIABLE') ? 'variable' : 'custom'}
                        inline name={param.name}>
              <Radio label="Custom Value" value="custom" disabled={disabled}/>
              <Radio label="Predefined Value" value="variable" disabled={disabled}/>
            </RadioGroup>
            {param.name}
            {selectComponent}
            {param.supportsLoadBalancing &&
              <Button className={button} variant="primary" disabled={disabled} onClick={() => {
                updateStepActionValue(param.name, paramValue + ';');
              }}><FontAwesomeIcon icon="plus"/> Add (Load Balance)</Button>
            }
          </>;
        })}
      </Card>
    </Card>
    <br/>
  </>);
}

export default EditablePipelineStep;
