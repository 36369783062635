import {
  Alert,
  Button,
  Card,
  Checkbox,
  Copy,
  FlexBox,
  Label,
  Modal,
  Select,
  Spinner,
  TabCard,
  TextField,
  Tooltip
} from '@cimpress/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {ReactElement, useMemo, useState} from 'react';
import ReactJson from 'react-json-view';
import {get, post, postWithResponse} from '../clients/AuthClient';
import { ModalAlert } from '../types/modal-alert';
import {
  JobContainer,
  MatchingPipeline,
  Pipeline,
  StepToTake
} from '../types/pipeline';
import _ from 'lodash';
import {TenantDisplayMetadata} from './TenantSelector';
import {PropertyBag} from '../types/property-info';
import PipelineSimulator from './PipelineSimulator';
import {button} from '../types/button-styles';

function JobContainerSimulator({ tenantMetadata, showAlert, startEditing, pipelines, propertyBags }:
  { tenantMetadata?: TenantDisplayMetadata, showAlert: (alert: ModalAlert) => void,
    startEditing: (id: string) => void, pipelines: Pipeline[], propertyBags: PropertyBag[] }): ReactElement {
  const [simulateJobContainer, showSimulateDialog] = useState(false);
  const [entityIdentifierToSimulate, setEntityIdentifierToSimulate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [jobContainer, setJobContainer] = useState(undefined as JobContainer | undefined);
  const [matchingPipeline, setMatchingPipeline] = useState(undefined as MatchingPipeline | undefined);
  const [includeDisabled, setIncludeDisabled] = useState(false);
  const [pipelineSubmitted, setPipelineSubmitted] = useState(false);
  const [jobContainerType, setJobContainerType] = useState('rip');
  const [searchEntity, showSearchDialog] = useState(false);
  const [entityIdentifierToSearch, setEntityIdentifierToSearch] = useState('');

  const jobContainerTypes = useMemo(() => ['rip', 'send-to-press'].map(type => {return {label: _.startCase(type), value: type};}), []);

  const postJobContainer = (jobContainer: any) => {
    postWithResponse<any, MatchingPipeline | undefined>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata?.tenant}/pipelines/findMatching?includeDisabled=${includeDisabled}`, jobContainer).then(pipeline => {
      setMatchingPipeline(pipeline);
    }).catch(() => {
      setMatchingPipeline(undefined);
    });
  };

  const simulate = (): any => {
    setIsLoading(true);
    setJobContainer(undefined);
    switch (tenantMetadata?.production_system_backend) {
      case 'viper':
        get<JobContainer>(`${process.env.REACT_APP_PRESSINTEGRATION_SERVICE_URL}/v1/Tenant/${tenantMetadata?.tenant}/JobContainer/${jobContainerType}/?gangNbr=${entityIdentifierToSimulate}`).then(jobContainer => {
          setJobContainer(jobContainer);
          postJobContainer(jobContainer);
        }).catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an Error from the Press Integration Service',
            type: 'danger'
          });
        }).finally(() => setIsLoading(false));
        break;
      case 'paperless':
        get<any>(`${process.env.REACT_APP_PAPERLESS_RIP_ADAPTER_URL}/v1/${tenantMetadata?.tenant}/getJobContainer/${jobContainerType}?jobId=${entityIdentifierToSimulate}`).then(jobContainer => {
          setJobContainer(jobContainer);
          postJobContainer(jobContainer);
        }).catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an Error from the Paperless Rip Adapter',
            type: 'danger'
          });
        }).finally(() => setIsLoading(false));
        break;
      default:
        showAlert({
          message: <div>This operation is not supported for tenant ${tenantMetadata?.tenant}</div>,
          title: 'Job Container simulation not supported',
          type: 'danger'
        });
        setIsLoading(false);
    }
  };

  const getStepToTakeComponent = (stepToTake: StepToTake, i: number, EnqueueStepsToTake: (stepsToTake: StepToTake[]) => void) => {
    return <Card header={<FlexBox>
      {`Step ${i + 1}: ${stepToTake.id}`}
      <FlexBox justifyContent="flex-end">
        <Tooltip contents="Run step">
          <Button
            variant="anchor"
            size="lg"
            icon={<FontAwesomeIcon icon="play" />}
            onClick={() => {
              EnqueueStepsToTake([stepToTake]);
            }}
          />
        </Tooltip>
      </FlexBox>
    </FlexBox>} key={i}>
      <h4>Input Files</h4>
      {stepToTake.inputFiles.map((inputFile, j) => {
        return <Tooltip key={j} tooltipInnerStyle={{wordBreak: 'break-all'}}
                        contents={<><h5>{inputFile.location}</h5>
                       <Copy value={inputFile.location} variant="button">
                         <FontAwesomeIcon icon="copy" /> Copy
                       </Copy>
                     </>}>
              <Label style={{whiteSpace: 'normal'}} text={<><strong>{inputFile.type}|</strong>{inputFile.name}</> as any} status="info" size="lg" />
        </Tooltip>;
      })}
      <h4>Parameters</h4>
      {Object.keys(stepToTake.parameters).length > 0 ? Object.keys(stepToTake.parameters).map((parameter, k) => {
        return <Tooltip key={k} contents={<Copy value={stepToTake.parameters[parameter]} variant="button">
            <FontAwesomeIcon icon="copy" /> Copy
          </Copy>} tooltipInnerStyle={{maxWidth: '50vw'}}>
          <Label style={{whiteSpace: 'normal'}} text={<><strong>{parameter}|</strong>{stepToTake.parameters[parameter]}</> as any} status="info" size="lg" />
        </Tooltip>;
      }) : <h5>No Parameters</h5>}
    </Card>;
  };

  const EnqueueStepsToTake = (stepsToTake: StepToTake[]) => {
    setIsLoading(true);
    post<any>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata?.tenant}/steps/enqueue`, {
      stepsToTake: stepsToTake,
      callbackUrl: null,
      entityReference: jobContainer?.entityReference ?? '',
      tags: ['simulation']
    }).then(() => {
      setPipelineSubmitted(true);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Pipelines Service',
        type: 'danger'
      });
    }).finally(() => setIsLoading(false));
  };

  const NoMatchingMessage = () => includeDisabled ? 'No pipelines match this job container.' : 'No enabled pipelines match this job container.';

  const getPipelineSubmittedAlert = () => {
    return pipelineSubmitted && <Alert status="success"
                                 message={<>Successfully submitted simulation. Track the status in&nbsp;
                                   <a href={`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/hangfire/tags/search/${jobContainer?.entityReference.toLowerCase()},simulation`}>
                                     Hangfire
                                   </a>.
                                 </>} onDismiss={() => setPipelineSubmitted(false)}/>;
  };

  const tabs = [{
    name: 'Simulate Job Container',
    block:
      <>
        <TextField label="Enter an entity identifier to simulate" onChange={event => setEntityIdentifierToSimulate(event.target.value)} value={entityIdentifierToSimulate}/>
        <Select
          onChange={(value) => value? setJobContainerType(value.value) : setJobContainerType('')}
          label="Job Container Type"
          options={jobContainerTypes}
          value={{label: _.startCase(jobContainerType), value: jobContainerType}}
        />
        <Checkbox label="Include disabled pipelines" checked={includeDisabled} onChange={() => setIncludeDisabled(!includeDisabled)} />
        <Button className={button} disabled={!entityIdentifierToSimulate} onClick={simulate} blockLevel>Simulate!</Button>
        {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size="medium" />
        </FlexBox>}
        {jobContainer &&
        <>
          <br />
          {matchingPipeline ?
            <>
              <Alert status="info" message={
                <>
                  The pipeline <b>&quot;{matchingPipeline.name}&quot;</b> matched this job container. <Button className={button} variant="link" size="sm" onClick={() => {
                  startEditing(matchingPipeline.id);
                  showSimulateDialog(false);
                  setEntityIdentifierToSimulate('');
                  setMatchingPipeline(undefined);
                }} >
                  <FontAwesomeIcon icon="link" /> Visit pipeline
                </Button>
                </>
              } dismissible={false} />
              {getPipelineSubmittedAlert()}
              <Button className={button} onClick={() => EnqueueStepsToTake(matchingPipeline?.stepsToTake)} blockLevel>Run all steps</Button>
              <br />
              {
                matchingPipeline.stepsToTake.map((stepToTake, i) => {
                  return (<>{getStepToTakeComponent(stepToTake, i, EnqueueStepsToTake)}<br/></>);
                })
              }
            </>
            : <Alert status="warning" message={NoMatchingMessage()} dismissible={false} />}
          <ReactJson src={jobContainer} theme="monokai" />
        </>}
      </>,
    href: '#'
  }, {
    name: 'Simulate Pipeline',
    block: <PipelineSimulator tenantMetadata={tenantMetadata} pipelines={pipelines} propertyBags={propertyBags}
                              jobContainerTypes={jobContainerTypes} getStepToTakeComponent={getStepToTakeComponent}
                              showAlert={showAlert} />,
    href: '#'
  }];

  return (
    <>
      <FlexBox className="button-group" justifyContent="flex-start">
        <Button className={button} onClick={() => showSimulateDialog(true)}><FontAwesomeIcon icon="box" /> Simulate</Button>
        <Button className={button} onClick={() => showSearchDialog(true)}><FontAwesomeIcon icon="search" /> Search Completed Pipelines</Button>
      </FlexBox>
      <Modal
        size="lg"
        show={searchEntity}
        title="Search Completed Pipelines"
        closeButton
        closeOnOutsideClick
        onRequestHide={() => {
          showSearchDialog(false);
          setEntityIdentifierToSearch('');
        }}
      >
        <TextField label="Search for a job reference"
                   onChange={(event) => setEntityIdentifierToSearch(event.target.value)}
                   value={entityIdentifierToSearch}
        />
        <Button className={button} disabled={!entityIdentifierToSearch}
                blockLevel
                onClick={() => {
                  window.open(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/hangfire/tags/search/${entityIdentifierToSearch.toLowerCase()},${tenantMetadata?.tenant.replaceAll('_', '-')}`);
                  showSearchDialog(false);
                  setEntityIdentifierToSearch('');
                }}
        >
          Search!
        </Button>
      </Modal>
      <Modal
        size="lg"
        style={{'cursor' : 'auto'}}
        show={simulateJobContainer}
        title="Simulate"
        closeButton
        closeOnOutsideClick
        onRequestHide={() => {
          showSimulateDialog(false);
          setJobContainer(undefined);
          setEntityIdentifierToSimulate('');
          setMatchingPipeline(undefined);
        }}
      >
        <TabCard tabs={tabs}/>
      </Modal>
    </>
  );
}

export default JobContainerSimulator;
