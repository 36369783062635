/**
 * Configuration for rip limiting
 */
import {SelectOption} from './select';

export interface RipLimitConfig {
  /**
   * The tenant
   */
  tenant: string;
  /**
   * The RIP limit
   */
  limit: number;
  /**
   * The line number
   */
  lineNbr: number;
  /**
   * Statuses to consider in-progress
   */
  inProgressStatuses: number[];
}

export const AvailableStatuses: SelectOption[] = [
  {
    value: '207',
    label: 'Sent to Press'
  },
  {
    value: '205',
    label: 'Loaded Onto Press'
  },
  {
    value: '226',
    label: 'Loaded Onto Press Backside'
  },
  {
    value: '227',
    label: 'Loaded Onto Press Frontside'
  },
  {
    value: '215',
    label: 'RIP Complete'
  },
  {
    value: '215',
    label: 'Half RIP Complete'
  },
  {
    value: '251',
    label: 'Printing In Progress'
  },
  {
    value: '216',
    label: 'Label Printed'
  }
];
