import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

const auth = new centralizedAuth({
  clientID: 'GzmU3WAkYYRVStchcU4V7rx4ri84NF4l'
});

function redirectPath(): string {
  return window.location.pathname + window.location.search;
}

auth.on('tokenExpired', () => {
  auth.login({ forceLogin: true, nextUri: redirectPath() });
});

function initializeAuth(): Promise<void> {
  return auth.ensureAuthentication({
    nextUri: redirectPath()
  }).catch((error: Error) => console.error(error));
}

function getUserProfile(): {name: string, email: string} {
  return auth.getProfile();
}

export { auth, initializeAuth, getUserProfile };
