export interface Size {
  heightInch: number,
  widthInch: number
}

export interface Dimensions {
  topInch: number,
  leftInch: number,
  bottomInch: number,
  rightInch: number,
}

export interface NewLabelSheetLayout {
  name: string,
  page: Size,
  label: Size,
  margin: Dimensions,
  rowSpacingInch: number,
  columnSpacingInch: number,
  rowCount: number,
  columnCount: number,
  header?: Size,
  headerLocation?: number,
  headerHorizontalOffsetInch?: number,
  headerVerticalOffsetInch?: number,
  headerTextFontSize?: number
}

export interface LabelSheetLayout extends NewLabelSheetLayout {
  id: string,
}

export enum DisplayUnit {
  inch,
  centimeter
}
