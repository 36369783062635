export const printMethods = ['Ipp', 'Raw', 'Lpr'] as const;
export type PrintMethod = typeof printMethods[number];

export const aggregationTypes = ['Batch', 'Line'] as const;
export type AggregationType = typeof aggregationTypes[number];

interface AdditionalFilter {
  key: string,
  type: string,
  defaultValue: boolean | string,
}

export const additionalFilters: AdditionalFilter[] = [
  {
    key: 'isJit',
    type: 'boolean',
    defaultValue: true,
  },
];

export interface NewLineLabelConfig {
  name: string,
  lineNbrs: number[],
  layoutId?: string,
  readyStatuses: string[],
  additionalFilters: Record<string, string | boolean>,
  aggregationTypes: AggregationType[],
  incompleteSheetThresholdSeconds: number,
  printerAddress?: string,
  printMethod?: PrintMethod,
  queueName?: string
}

export interface LineLabelConfig extends NewLineLabelConfig {
  id: string,
}
