import React, {ReactElement, useEffect, useState} from 'react';
import {Pipeline} from '../types/pipeline';
import {
  Alert,
  Button,
  Card,
  DragAndDrop,
  Droppable,
  FlexBox,
  withDraggable
} from '@cimpress/react-components';
import IconDragDrop from '@cimpress-technology/react-streamline-icons/lib/IconDragDrop';
import * as _ from 'lodash';
import {InjectedDraggableProps} from '@cimpress/react-components/lib/DragAndDrop/withDraggable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {post} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';

function ReorderPipelines({
  pipelines, tenant, prettyTenant, refreshPipelines, stopReordering,
  showAlert, setIsLoading
}:
  {
    pipelines: Pipeline[], tenant: string | undefined, prettyTenant: string | undefined,
    refreshPipelines: () => Promise<void>, stopReordering: () => void,
    showAlert: (alert: ModalAlert) => void, setIsLoading: (isLoading: boolean) => void
  }): ReactElement {

  const [draggablePipelines, setDraggablePipelines] = useState([] as Pipeline[]);

  useEffect(() => {
    setDraggablePipelines(_.cloneDeep(pipelines));
  }, [pipelines]);

  interface IPipelineComponent {
    item: Pipeline;
  }

  const PipelineComponent: React.ComponentType<IPipelineComponent & InjectedDraggableProps> = ({item: item}) => {

    return (
      <div>
        {item.name}
      </div>
    );
  };

  const DraggableItem = withDraggable(PipelineComponent);

  // a little function to help us with reordering the result
  const reorder = (list: Pipeline[], startIndex: number, endIndex: number): Pipeline[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: { source: any; destination: any; }) => {
    const {source, destination} = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const items = reorder(
      draggablePipelines,
      source.index,
      destination.index,
    );
    setDraggablePipelines(items);
  };

  const saveOrder = (): void => {
    setIsLoading(true);
    post<string[]>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines/reorder`, draggablePipelines.map(p => p.id))
      .then(() => {
        stopReordering();
        return refreshPipelines();
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Pipelines Service',
          type: 'danger'
        });
      }).finally(() => {
      setIsLoading(false);
    });
  };

  const cancelOrder = (): void => {
    stopReordering();
    refreshPipelines();
  };

  return (
    <div className="container">
      <Card header={
        <FlexBox>
          <FlexBox className="button-group">
            <Button style={{ fontSize: '0.8rem', fontWeight: '500'}} variant="secondary" size="sm" onClick={cancelOrder}>
              <FontAwesomeIcon icon="backward"/> Back
            </Button>
            <h6 style={{marginTop: '0.5rem'}}>Reorder Pipelines for {prettyTenant}</h6>
          </FlexBox>
          <FlexBox justifyContent="flex-end">
            <Button style={{ fontSize: '0.8rem', fontWeight: 500 }} variant="primary" size="sm" onClick={saveOrder}>
              <FontAwesomeIcon icon="save"/> Save
            </Button>

          </FlexBox>
        </FlexBox>
      }>
        <Alert message="Pipelines are evaluated in order. Drag the pipelines to the order you want them to be evaluated in."
               status="info" dismissible={false}/>
        <DragAndDrop onDragEnd={onDragEnd}>
          <FlexBox>
            <div>
              <Droppable droppableId="leftDroppable" isEmpty={draggablePipelines.length === 0}
                         emptyPlaceholder={<div> Drag something into the first droppable </div>}>
                {draggablePipelines.map((pipeline, index) => <DraggableItem
                  draggableId={pipeline.id} index={index} key={pipeline.id} item={pipeline}
                  icon={IconDragDrop}/>)}
              </Droppable>
            </div>
          </FlexBox>
        </DragAndDrop>
      </Card>
    </div>
  );
}

export default ReorderPipelines;
