import {Spool, Thread} from '@cimpress-technology/mipi-react-components';

export interface AdminSpool extends Spool {
  originalThread?: Thread;
}

export const UninitializedThread: Thread = {
  mcpThreadId: 'mcp0000'
};

export const GetUninitializedSpools = (): AdminSpool[] => {
  return [
    {
      id: 1,
      thread: UninitializedThread
    },
    {
      id: 2,
      thread: UninitializedThread
    },
    {
      id: 3,
      thread: UninitializedThread
    },
    {
      id: 4,
      thread: UninitializedThread
    },
    {
      id: 5,
      thread: UninitializedThread
    },
    {
      id: 6,
      thread: UninitializedThread
    },
    {
      id: 7,
      thread: UninitializedThread
    },
    {
      id: 8,
      thread: UninitializedThread
    },
    {
      id: 9,
      thread: UninitializedThread
    },
    {
      id: 10,
      thread: UninitializedThread
    },
    {
      id: 11,
      thread: UninitializedThread
    },
    {
      id: 12,
      thread: UninitializedThread
    },
    {
      id: 13,
      thread: UninitializedThread
    },
    {
      id: 14,
      thread: UninitializedThread
    },
    {
      id: 15,
      thread: UninitializedThread
    }
  ];
};
