import React, { ReactElement, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  FlexBox,
  InlineEdit, Modal,
  Select,
  TextField,
  Robot,
  Spinner,
} from '@cimpress/react-components';
import TenantSelector from '../components/TenantSelector';
import { deleteResource, get, patch, post } from '../clients/AuthClient';
import { PlateMaker } from '../types/plate-maker';
import { OffsetEquipment } from '../types/offset-equipment';
import { SelectOption } from '../types/select';
import * as _ from 'lodash';
import { ModalAlert } from '../types/modal-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {button, buttonDanger, buttonSuccess} from '../types/button-styles';


function PlateMakerSetup({showAlert} : {showAlert: (alert: ModalAlert) => void}): ReactElement {
  const [tenant, setTenant] = useState('');

  const tenantSelectionChange = (tenant: string) => {
    setTenant(tenant);
  };

  useEffect(() => {
    document.title = 'Plate Maker Setup';
  }, []);

  return (
    <div className="container">
      <Card header="Plate Maker Setup">
        <div className="col-md-12">
          <TenantSelector tenantSelected={tenantSelectionChange} />
        </div>
      </Card>
      <br />
      {!tenant ?
        <Card>
          <FlexBox justifyContent="center">
            <Robot status="warning" size="lg" />
          </FlexBox>
          <Alert
            status="info"
            message="Select a tenant to edit configuration"
            dismissible={false}
          />
        </Card> :
        <PlateMakerList tenant={tenant} showAlert={showAlert} />
      }
    </div>
  );
}

function PlateMakerList({ tenant, showAlert }: { tenant: string, showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [plateMakers, setPlateMakers] = useState([] as PlateMaker[]);
  const [offsetEquipments, setOffsetEquipments] = useState([] as OffsetEquipment[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingPlateMaker, setIsCreatingPlateMaker] = useState(false);
  const [newPlateMakerName, setNewPlateMakerName] = useState('');
  const [plateMakerAssociations, setPlateMakerAssociations] = useState(new Map<string, string[]>());

  const tenantSelectionChange = () => {
    if (!tenant) {
      setPlateMakers([]);
      return;
    }
    setIsLoading(true);
    get<PlateMaker[]>(`${process.env.REACT_APP_OFFSET_SERVICE_URL}/v1/Tenant/${tenant}/PlateMakers`)
      .then(fetchedPlateMakers => {
        fetchedPlateMakers.sort((a, b) => a.name.localeCompare(b.name));
        setPlateMakers(fetchedPlateMakers);
        setPlateMakerAssociations(new Map(fetchedPlateMakers.map(p => [p.plateMakerId, p.equipments.map(e => e.offsetEquipmentId)])));
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Offset Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
    get<OffsetEquipment[]>(`${process.env.REACT_APP_OFFSET_SERVICE_URL}/v1/Tenant/${tenant}/PlateMakers/OffsetEquipments`)
      .then(offsetEquipments => {
        offsetEquipments.sort((a, b) => a.name.localeCompare(b.name));
        setOffsetEquipments(offsetEquipments);
      }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Offset Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    tenantSelectionChange();
  }, [tenant]);

  const savePlateMaker = ({ value, name }: { value?: string, name?: string }) => {
    setIsLoading(true);
    patch(`${process.env.REACT_APP_OFFSET_SERVICE_URL}/v1/Tenant/${tenant}/PlateMakersAdmin?name=${value}&plateMakerId=${name}`, {})
      .then(() => {
        tenantSelectionChange();
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Offset Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const deletePlateMaker = (plateMakerId: string) => {
    setIsLoading(true);
    deleteResource(`${process.env.REACT_APP_OFFSET_SERVICE_URL}/v1/Tenant/${tenant}/PlateMakersAdmin?plateMakerId=${plateMakerId}`)
      .then(() => {
        tenantSelectionChange();
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Offset Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const createNewPlateMaker = () => {
    setIsLoading(true);
    post(`${process.env.REACT_APP_OFFSET_SERVICE_URL}/v1/Tenant/${tenant}/PlateMakersAdmin?name=${newPlateMakerName}`, null).then(() => {
      tenantSelectionChange();
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Offset Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
      setIsCreatingPlateMaker(false);
    });
  };

  const offsetEquipmentSelectionChanged = (selection: any, plateMakerId: string) => {
    const newAssociations = new Map(plateMakerAssociations);
    newAssociations.set(plateMakerId, selection?.map((s : SelectOption) => s.value) ?? []);
    setPlateMakerAssociations(newAssociations);
  };

  const convertOffsetEquipmentIds = (offsetEquipmentIds: string[]): SelectOption[] => {
    const equipments = offsetEquipmentIds.map(o => offsetEquipments.find(oe => oe.equipmentId === o) ?? {name: 'Unknown Equipment', equipmentId: o});
    return equipments.map(e => {
      return {
        label: e.name,
        value: e.equipmentId
      };
    });
  };

  const plateMakerHasChanged = (plateMakerId: string): boolean => {
    const currentAssociations = plateMakerAssociations.get(plateMakerId) ?? [];
    const plateMaker = plateMakers.find(p => p.plateMakerId === plateMakerId);
    if (!plateMaker) {
      return true;
    }
    const originalAssociations = plateMaker.equipments.map(e => e.offsetEquipmentId);
    return !_.isEqual(currentAssociations, originalAssociations);
  };

  const saveAssociations = (plateMakerId: string) => {
    const currentAssociations = plateMakerAssociations.get(plateMakerId) ?? [];
    const plateMaker = plateMakers.find(p => p.plateMakerId === plateMakerId);
    if (!plateMaker) {
      return;
    }
    const originalAssociations = plateMaker.equipments.map(e => e.offsetEquipmentId);
    const deletedEquipments = _.difference(originalAssociations, currentAssociations);
    const addedEquipments = _.difference(currentAssociations, originalAssociations);
    const savePromises: Promise<void>[] = [];
    if (deletedEquipments.length > 0) {
      savePromises.push(...deletedEquipments.map(e => {
        return deleteResource(`${process.env.REACT_APP_OFFSET_SERVICE_URL}/v1/Tenant/${tenant}/PlateMakersAdmin/Associations?plateMakerId=${plateMakerId}&offsetEquipmentId=${e}`);
      }));
    }
    if (addedEquipments.length > 0) {
      savePromises.push(...addedEquipments.map(e => {
        return post(`${process.env.REACT_APP_OFFSET_SERVICE_URL}/v1/Tenant/${tenant}/PlateMakersAdmin/Associations?plateMakerId=${plateMakerId}&offsetEquipmentId=${e}`, null);
      }));
    }
    setIsLoading(true);
    Promise.all(savePromises).then(() => {
      tenantSelectionChange();
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Offset Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Card>
      {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size='large' />
        </FlexBox>
      }
      <div>
        <FlexBox className="button-group" justifyContent="flex-end">
          <Button className={button} variant="primary" onClick={() => {
            setNewPlateMakerName('');
            setIsCreatingPlateMaker(true);
          }}>
            <FontAwesomeIcon icon="plus" /> Add
          </Button>
          <Button className={button} variant="primary" onClick={() => tenantSelectionChange()}>
            <FontAwesomeIcon icon="sync" /> Refresh</Button>
        </FlexBox>
      </div>
      <br />
      {plateMakers.map(p =>
        <>
          <Card key={p.plateMakerId}>
            {/*<span className="list-group-item" key={p.plateMakerId}>*/}
            <div className="row">
              <div className="col-md-12">
                <InlineEdit
                  name={p.plateMakerId}
                  value={p.name}
                  size='h4'
                  onSave={savePlateMaker}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Select
                  isClearable
                  isSearchable
                  status={plateMakerHasChanged(p.plateMakerId) ? 'success' : undefined}
                  isMulti={true as any}
                  onChange={(value) => offsetEquipmentSelectionChanged(value, p.plateMakerId)}
                  label="Offset Equipments"
                  options={offsetEquipments.map(o => {
                    return {
                      label: o.name,
                      value: o.equipmentId
                    };
                  })}
                  value={convertOffsetEquipmentIds(plateMakerAssociations.get(p.plateMakerId) ?? []) as any}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <Button variant="primary" className={buttonDanger} size="sm" onClick={() => deletePlateMaker(p.plateMakerId)}>
                  <FontAwesomeIcon icon="trash" /> Delete Plate Maker
                </Button>
              </div>
              <div className="col-sm-6">
                <FlexBox alignItems="right" justifyContent="right">
                  <Button variant="primary" className={button} size="sm" disabled={!plateMakerHasChanged(p.plateMakerId)} onClick={() => saveAssociations(p.plateMakerId)}>
                    <FontAwesomeIcon icon="save" /> Save Associations
                  </Button>
                </FlexBox>
              </div>
            </div>
            {/*</span>*/}
          </Card>
          <br/>
        </>
      )}
      <Modal
        status="info"
        show={isCreatingPlateMaker}
        onRequestHide={() => setIsCreatingPlateMaker(false)}
        title="Add a new Plate Maker"
        closeButton={true}
        footer={(
          <div className="button-group">
            <Button className={buttonSuccess} onClick={() => createNewPlateMaker()}>
              Save
            </Button>
            <Button className={buttonDanger} onClick={() => setIsCreatingPlateMaker(false)}>
              Close
            </Button>
          </div>
        )}
      >
        <form onSubmit={() => createNewPlateMaker()}>
          <TextField
            name="simple"
            label="New Plate Maker Name"
            value={newPlateMakerName}
            onChange={e => setNewPlateMakerName(e.target.value)}
            autoFocus={true}
          />
        </form>
        {isLoading &&
          <FlexBox justifyContent="center">
            <Spinner size='medium' />
          </FlexBox>
        }
      </Modal>
    </Card>);
}

export default PlateMakerSetup;
