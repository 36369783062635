import {colors} from '@cimpress/react-components';
import {css} from '@emotion/css';

export const button = css`
  font-size: 0.9em;
`;

export const buttonInfo = css`
  font-size: 0.9em;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  background-color: ${colors.info.base};
  border-color: ${colors.info.base};
  &:hover {
    background-color: ${colors.info.darker};
    border-color: ${colors.info.darker};
  }
`;

export const buttonSuccess = css`
  font-size: 0.9em;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  background-color: ${colors.success.base};
  border-color: ${colors.success.base};
  &:hover {
    background-color: ${colors.success.darker};
    border-color: ${colors.success.darker};
  }
`;

export const buttonWarning = css`
  font-size: 0.9em;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  background-color: ${colors.warning.base};
  border-color: ${colors.warning.base};
  &:hover {
    background-color: ${colors.warning.darker};
    border-color: ${colors.warning.darker};
  }
`;

export const buttonDanger = css`
  font-size: 0.9em;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  background-color: ${colors.danger.base};
  border-color: ${colors.danger.base};
  &:hover {
    background-color: ${colors.danger.darker};
    border-color: ${colors.danger.darker};
  }
`;

export const addPipelineDropdown = css`
  border: 1px solid ${colors.info.base};
  &:hover {
    border: 1px solid ${colors.info.darker};
  }
  button {
    font-size: 0.9em;
  }
`;
