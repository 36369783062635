import Header from '@cimpress-technology/react-platform-header';
import {Modal, colors, Button} from '@cimpress/react-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';
import BarudanTrayApp from './routes/BarudanTrayApp';
import FluidCardList from './components/FluidCardList';
import EmbroideryPressSetup from './routes/EmbroideryPressSetup';
import React, { ReactElement, useEffect, useState } from 'react';
import { auth } from './Auth';
import PlateMakerSetup from './routes/PlateMakerSetup';
import SettingsAdministration from './routes/SettingsAdministration';
import { ModalAlert } from './types/modal-alert';
import TestJobSetup from './routes/TestJobSetup';
import EquipmentAdministration from './routes/EquipmentAdministration';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import PipelineManager from './routes/PipelineManager';
import RipLimitSetup from './routes/RipLimitSetup';
import { get } from './clients/AuthClient';
import DecorationTechnologyAdmin from './routes/DecorationTechnologyAdmin';
import PressIntTools from './routes/PressIntTools';
import LabelSheetsSetup from './routes/LabelSheetsSetup';
import CardHeader from './components/CardHeader';
import {button} from './types/button-styles';

library.add(fas);

function CustomHeader(props: RouteComponentProps) {
  const { location } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [profile, setProfile] = useState(undefined);
  const [isMipiUser, setIsMipiUser] = useState(false);
  const [isMisDeveloper, setIsMisDeveloper] = useState(false);

  useEffect(() => {
    auth.on('tokenExpired', () => {
      setIsLoggedIn(false);
      setAuthToken('');
      setProfile(undefined);
    });

    auth.on('authenticated', () => {
      setIsLoggedIn(true);
      setAuthToken(auth.getAccessToken());
      setProfile(auth.getProfile());
      get<any>('https://api.cimpress.io/auth/access-management/v1/principals/self/groups').then(response => {
        if (response.groups.some((g: any) => g.id.toString() === '3268')) {
          setIsMipiUser(true);
        }
        if (response.groups.some((d: any) => d.id.toString() === '161107')) {
          setIsMisDeveloper(true);
        }
      }).catch(() => {
        setIsMipiUser(false);
        setIsMisDeveloper(false);
      });
    });
  }, []);

  const getLinks = (): any[] => {
    const links = [
        {
          id: 'homeLink',
          type: 'default',
          content: <Link to='/'>Home</Link>,
          isActive: location.pathname === '/'
        },
        {
          id: 'settingsLink',
          type: 'default',
          content: <Link to='/settings'>Settings</Link>,
          isActive: location.pathname === '/settings'
        },
        {
          id: 'equipmentLink',
          type: 'default',
          content: <Link to='/equipment'>Equipment</Link>,
          isActive: location.pathname === '/equipment'
        },
        {
          id: 'pipelineManagerLink',
          type: 'default',
          content: <Link to='/pipelineManager'>Pipeline Manager</Link>,
          isActive: location.pathname === '/pipelineManager'
        },
        {
          id: 'ripLimitSetupLink',
          type: 'default',
          content: <Link to='/ripLimitSetup'>RIP Limits</Link>,
          isActive: location.pathname === '/ripLimitSetup'
        },
        {
          id: 'pressSetupLink',
          type: 'default',
          content: <Link to='/embroideryPressSetup'>Embroidery Setup</Link>,
          isActive: location.pathname === '/embroideryPressSetup'
        },
        {
          id: 'plateMakerlink',
          type: 'default',
          content: <Link to='/offsetPlateMaker'>Plate Maker Setup</Link>,
          isActive: location.pathname === '/offsetPlateMaker'
        },
        {
          id: 'testJobsLink',
          type: 'default',
          content: <Link to='/testJobSetup'>Test Job Setup</Link>,
          isActive: location.pathname === '/testJobSetup'
        },
        {
          id: 'labelSheetsLink',
          type: 'default',
          content: <Link to='/labelSheets'>Label Sheets</Link>,
          isActive: location.pathname === '/labelSheets'
        },
        {
          id: 'barudanTrayAppLink',
          type: 'default',
          content: <Link style={{color: colors.info.darkest}} to='/barudanTrayApp'>Barudan Tray</Link>,
          isActive: location.pathname === '/barudanTrayApp'
        },
      ];
    if (isMipiUser) {
      links.push({
        id: 'decorationTechnologyAdminLink',
        type: 'default',
        content: <Link to='/decorationTechnologyAdmin'>Decoration Technology Admin</Link>,
        isActive: location.pathname === '/decorationTechnologyAdmin'
      });
    }
    if (isMisDeveloper){
      links.push({
        id: 'pressIntToolsLink',
        type: 'default',
        content: <Link to='/pressIntTools'>Press Int Tools</Link>,
        isActive: location.pathname === '/pressIntTools'
      });
    }
    return links;
  };

  return (
    <Header
      mailToAddress="PressIntegration@cimpress.com"
      documentationLink="https://cimpress-support.atlassian.net/wiki/spaces/MIPI/pages/1937834754/Admin+UI+Documentation"
      showLeftNav={false}
      responsive={true}
      appTitle="Press Integration Administration"
      profile={profile}
      onLogInClicked={() =>
        auth.login({
          nextUri: '/',
        })
      }
      onLogOutClicked={() => auth.logout('/')}
      isLoggedIn={isLoggedIn}
      accessToken={authToken}
      appLinks={getLinks()}
    />
  );
}

function AlertModal({alert} : {alert: ModalAlert}) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
  }, [alert]);

  return (
    <Modal
      status={alert.type}
      show={isOpen}
      onRequestHide={() => setIsOpen(false)}
      title={alert.title}
      closeButton={true}
      footer={(
        <Button className={button} onClick={() => setIsOpen(false)}>
          Close
        </Button>
      )}
    >
      {alert.message}
    </Modal>
  );
}

function Home() {
  useEffect(() => {
    document.title = 'Press Integration Administration';
  }, []);

  return (
    <div className="container">
      <FluidCardList columns={2}
        cards={[
          {
            header: <CardHeader svgName="settings-1-l" />,
            title: 'Settings',
            link: <Link to='/settings'><Button variant="primary">Go to Settings</Button></Link>,
            description: 'Change, add, or delete settings on the Press Integration settings service',
            docLink: 'https://cimpress-support.atlassian.net/wiki/spaces/MIPI/pages/1773080351/Settings+service'
          },
          {
            header: <CardHeader svgName="factory-l" />,
            title: 'Equipment',
            link: <Link to='/equipment'><Button variant="primary">Go to Equipment</Button></Link>,
            description: 'Change, add, or delete equipment on the equipment model'
          },
          {
            header: <CardHeader svgName="flow-chart-1-l" />,
            title: 'Pipeline Manager',
            link: <Link to='/pipelineManager'><Button variant="primary">Go to Pipeline Manager</Button></Link>,
            description: 'Change, add, or delete pipelines',
            docLink: 'https://cimpress-support.atlassian.net/wiki/spaces/MIPI/pages/7986020524/Pipelines'
          },
          {
            header: <CardHeader svgName="filter" />,
            title: 'RIP Limiter',
            link: <Link to='/ripLimitSetup'><Button variant="primary">Go to RIP Limiter</Button></Link>,
            description: 'Change, add, or delete RIP limits for Viper lines'
          },
          {
            header: <CardHeader svgName="paint-palette-l" />,
            title: 'Embroidery Press Setup',
            link: <Link to='/embroideryPressSetup'><Button variant="primary">Go to Embroidery Press Setup</Button></Link>,
            description: 'Configure embroidery press spool colors and local press IP addresses'
          },
          {
            header: <CardHeader svgName="print-picture-l" />,
            title: 'Offset Plate Maker',
            link: <Link to='/offsetPlateMaker'><Button variant="primary">Go to Offset Plate Maker Setup</Button></Link>,
            description: 'Configure offset plate makers and plate maker mappings'
          },
          {
            header: <CardHeader svgName="checklist-l" />,
            title: 'Test Jobs',
            link: <Link to='/testJobSetup'><Button variant="primary">Go to Test Job Setup</Button></Link>,
            description: 'Configure test jobs to be sent from Press Integration UIs like the garment and RIP Schedule screen'
          },
          {
            header: <CardHeader svgName="window-application-1-l" />,
            title: 'Barudan Tray',
            link: <Link to='/barudanTrayApp'><Button variant="primary">Get the Barudan Tray App</Button></Link>,
            description: 'Download the Barudan Tray App and see documentation for how to set it up'
          },
          {
            header: <CardHeader svgName="print-picture-l" />,
            title: 'Label Sheets',
            link: <Link to='/labelSheets'><Button variant="primary">Go to Label Sheets Setup</Button></Link>,
            description: 'Configure label sheets'
          },
        ]} />
    </div>
  );
}

const HeaderWithRouter = withRouter(CustomHeader);

function App(): ReactElement {
  const [currentAlert, showAlert] = useState(null as any as ModalAlert);

  useEffect(() => {
    document.title = 'Press Integration Administration';
  }, []);

  return (
    <>
      {currentAlert && <AlertModal alert={currentAlert} />}
      <Router>
        <HeaderWithRouter />
        <Switch>
          <Route path="/barudanTrayApp">
            <BarudanTrayApp showAlert={showAlert} />
          </Route>
          <Route path="/embroideryPressSetup">
            <EmbroideryPressSetup showAlert={showAlert} />
          </Route>
          <Route path="/offsetPlateMaker">
            <PlateMakerSetup showAlert={showAlert} />
          </Route>
          <Route path="/settings">
            <SettingsAdministration showAlert={showAlert} />
          </Route>
          <Route path="/testJobSetup">
            <TestJobSetup showAlert={showAlert} />
          </Route>
          <Route path="/equipment">
            <EquipmentAdministration showAlert={showAlert} />
          </Route>
          <Route path="/pipelineManager">
            <PipelineManager showAlert={showAlert} />
          </Route>
          <Route path="/ripLimitSetup">
            <RipLimitSetup showAlert={showAlert} />
          </Route>
          <Route path="/decorationTechnologyAdmin">
            <DecorationTechnologyAdmin showAlert={showAlert} />
          </Route>
          <Route path="/pressIntTools">
            <PressIntTools showAlert={showAlert} />
          </Route>
          <Route path="/labelSheets">
            <LabelSheetsSetup showAlert={showAlert} />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
