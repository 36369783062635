import React, {ReactElement, useEffect, useState} from 'react';
import {auth} from '../Auth';
import {FlexBox, Select, Table, Spinner} from '@cimpress/react-components';
import Uploader from '@cimpress-technology/react-platform-uploader';
import {TestJobFile} from '../types/test-jobs';
import {UploadsResponse} from '../types/uploads-response';
import {get} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import {SelectOption} from '../types/select';

function TestJobFileUploader({tenant, isLoading, setIsLoading, newTestJobFiles, setNewTestJobFiles, showAlert}: {
    tenant: string,
    isLoading: boolean,
    setIsLoading: (isLoading: boolean) => void,
    newTestJobFiles: TestJobFile[],
    setNewTestJobFiles: (files: TestJobFile[]) => void,
    showAlert: (alert: ModalAlert) => void
}) : ReactElement {
  const [uploadsResponse, setUploadsResponse] = useState([] as UploadsResponse[]);
  const [fileTypes, setFileTypes] = useState([] as SelectOption[]);

  useEffect(() => {
    getFileTypes(tenant);
  },[tenant]);

  function getThumbnailUrl(fileUrl: string) {
    return fileUrl.replace('?', '/thumb?');
  }

  function onUploadFileSuccess(response: UploadsResponse[]) {
    const mergedUploadsResponses = [...uploadsResponse, ...response];
    setUploadsResponse(mergedUploadsResponses);
    setNewTestJobFiles(mergedUploadsResponses.map(response => ({
      fileUrl: response.url,
      fileName: response.name,
      thumbnailUrl: getThumbnailUrl(response.url),
    })));
    setIsLoading(false);
  }

  function getFileTypes(tenant: string): Promise<any> {
    return get<string[]>(`${process.env.REACT_APP_PRESSINTEGRATION_SERVICE_URL}/v1/Tenant/${tenant}/JobContainer/FileTypes`).then(fileTypes => {
      setFileTypes(fileTypes.map(filetype => ({'label': filetype, 'value': filetype} as SelectOption)));
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an error from Press Integration Service',
        type: 'danger'
      });
    });
  }

  function updateFileTypeInTestJob(row: any, value: string) {
    setNewTestJobFiles(newTestJobFiles.map(testJob => {
      if(testJob.fileUrl === row.original.url){
        testJob.fileType = value;
      }
      return testJob;
    }));
  }

  return(<>
    <h3>Upload Test Files</h3>
    {<Uploader uploadTenant="press-integration"
               accessToken={auth.getAccessToken()}
               provideThumbnails={false}
               onUploadFileStart={() => setIsLoading(true)}
               onUploadFileSuccess={onUploadFileSuccess}
               onUploadFileError={() => setIsLoading(false)}
               deleteAfterDays={'never'}
    />}
    {isLoading ?
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox> :
      uploadsResponse.length > 0 &&
      <div>
        <h3>Selected Files</h3>
        <Table
          columns={
            [
              {Header: 'Name', accessor: 'name'},
              {Header: 'Size (Bytes)', accessor: 'size'},
              // eslint-disable-next-line react/display-name
              {Header: 'File Type', accessor: 'fileType', Cell: row => <Select key={row} options={fileTypes} onChange={e => updateFileTypeInTestJob(row, e?.value ?? '')}/>}
            ]
          }
          data={uploadsResponse}
          isCondensed={false}
          isBordered={true}
          hasHover={true}
        />
      </div>
    }
  </>);
}
export default TestJobFileUploader;
