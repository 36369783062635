import {
  Alert,
  Button,
  Card,
  FlexBox,
  Select,
  Robot,
  Spinner,
} from '@cimpress/react-components';
import React, {ReactElement, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {get, patch} from '../clients/AuthClient';
import TenantSelector from '../components/TenantSelector';
import {EmbroideryEquipment} from '../types/equipment';
import {ModalAlert} from '../types/modal-alert';
import {SelectOption} from '../types/select';
import {
  AdminSpool,
  GetUninitializedSpools,
  UninitializedThread
} from '../types/spools';
import {
  Spool,
  SpoolBoard,
  SpoolStyle
} from '@cimpress-technology/mipi-react-components';

interface IsacordThreadColorIngredient {
  name: string;
  colorName: string;
  manufacturersId: string;
  r: number;
  g: number;
  b: number;
  isExtendedPallet: boolean;
}

function EmbroideryPressSetup({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [tenant, setTenant] = useState('');
  const [allEquipments, setAllEquipments] = useState([] as EmbroideryEquipment[]);
  const [selectedEquipment, setSelectedEquipment] = useState(null as unknown as SelectOption);
  const [spools, setSpools] = useState(GetUninitializedSpools());
  const [threadColorMap, setThreadColorMap] = useState(new Map<string, IsacordThreadColorIngredient>());
  const [allThreadColors, setAllThreadColors] = useState([] as IsacordThreadColorIngredient[]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    document.title = 'Embroidery Press Setup';
    get<IsacordThreadColorIngredient[]>(`${process.env.REACT_APP_DYNAMICRECIPE_SERVICE_URL}/v1/recipeType/embroidery/ingredientType/isacordThreadColor/ingredient`).then(threads => {
      setAllThreadColors(threads);
      setThreadColorMap(new Map(threads.map(t => [t.manufacturersId, t])));
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Dynamic Recipe Service',
        type: 'danger'
      });
    });
  }, []);

  useEffect(() => {
    updateEquipments(tenant);
  }, [location.search]);

  const tenantSelectionChange = (tenant: string) => {
    setTenant(tenant);
    setSelectedEquipment(null as unknown as SelectOption);
    updateEquipments(tenant);
  };

  const updateEquipments = (tenant: string): Promise<void> => {
    if (!tenant) {
      return new Promise<void>(() => Promise.resolve());
    }
    setIsLoading(true);
    return get<EmbroideryEquipment[]>(`${process.env.REACT_APP_EMBROIDERY_SERVICE_URL}/Tenant/${tenant}/Equipments`).then(equipments => {
      setAllEquipments(equipments);
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('equipmentId')) {
        const eq = equipments.find(e => e.id.toString() === queryParams.get('equipmentId'));
        if (eq) {
          setEquipment({
            label: eq.name,
            value: eq.id.toString()
          }, tenant);
        }
      }
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Embroidery Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  function getColorCode(manufacturerId: string) {
    return manufacturerId.replace('mcp', '');
  }

  const equipmentSelectionChange = (selectedEquipment: any) => {
    setEquipment(selectedEquipment, tenant);
  };

  const setEquipment = (selectedEquipment: SelectOption, selectedTenant: string) => {
    setSelectedEquipment(selectedEquipment);
    const queryParams = new URLSearchParams(location.search);

    if (!selectedEquipment) {
      queryParams.delete('equipmentId');
      history.push({
        search: queryParams.toString()
      });
      return;
    }

    if (queryParams.get('equipmentId') !== selectedEquipment.value) {
      queryParams.set('equipmentId', selectedEquipment.value);
      history.push({
        search: queryParams.toString()
      });
    }

    document.title = `Embroidery Press Setup - ${selectedEquipment.label}`;

    setIsLoading(true);
    get<AdminSpool[]>(`${process.env.REACT_APP_EMBROIDERY_SERVICE_URL}/Tenant/${selectedTenant}/Equipments/${selectedEquipment.value}/Spools`).then(spools => {
      let newSpools = spools.length === 0 ? GetUninitializedSpools() : spools;
      newSpools = newSpools.map(s => {
        s.originalThread = s.thread;
        return s;
      });
      setSpools(newSpools);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Embroidery Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getSpool = (spool: Spool): SelectOption => {
    const threadColorName = threadColorMap.get(getColorCode(spool.thread.mcpThreadId))?.colorName ?? 'Unknown Color';
    return {
      label: `${getColorCode(spool.thread.mcpThreadId)} - ${threadColorName}`,
      value: getColorCode(spool.thread.mcpThreadId)
    };
  };

  const threadChange = (selectedSpool: any, spoolIdx: number) => {
    const manufacturerId = selectedSpool.value;
    const newSpools = [...spools];
    newSpools[spoolIdx].thread = {
      mcpThreadId: `mcp${manufacturerId}`
    };
    setSpools(newSpools);
  };

  const getThreadChanges = (): Spool[] => {
    return spools.filter(s => s.originalThread?.mcpThreadId !== s.thread.mcpThreadId);
  };

  const resetSpoolBoard = () => {
    const newSpools = spools.map(s => {
      s.thread = s.originalThread ?? s.thread;
      return s;
    });
    setSpools(newSpools);
  };

  function getFill(spool: Spool) {
    const threadColor = threadColorMap.get(getColorCode(spool.thread.mcpThreadId));
    // fall back to pink if thread color not found.
    return `rgb(${threadColor?.r ?? 255}, ${threadColor?.g ?? 0}, ${threadColor?.b ?? 255})`;
  }

  const getSpoolStyle = (spool: AdminSpool): SpoolStyle => {
    const isChange = spool.thread.mcpThreadId !== spool.originalThread?.mcpThreadId;
    return {
      strokeColor: isChange ? 'orange' : 'lightgrey',
      strokePattern: isChange ? '5,5' : '',
      fillColor: getFill(spool),
      animate: spool.thread === UninitializedThread
    };
  };

  const save = () => {
    const threadChanges = getThreadChanges();
    if (threadChanges.length <= 0) {
      return;
    }
    setIsLoading(true);
    const threadChangeList = threadChanges.map(t => {
      return {
        spoolNumber: t.id,
        mcpThreadId: t.thread.mcpThreadId
      };
    });
    patch(`${process.env.REACT_APP_EMBROIDERY_SERVICE_URL}/Tenant/${tenant}/Equipments/${selectedEquipment.value}/Spools`, threadChangeList)
      .then(() => {
        return updateEquipments(tenant).then(() => {
          equipmentSelectionChange(selectedEquipment);
        });
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Embroidery Service',
          type: 'danger'
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="container">
      <Card header="Embroidery Press Setup">
        <div className="row">
          <div className="col-sm-6">
            <TenantSelector tenantSelected={tenantSelectionChange}/>
          </div>
          <div className="col-sm-6">
            <Select
              isClearable
              label="Select equipment"
              isDisabled={!tenant || !spools}
              value={selectedEquipment}
              options={allEquipments.map(e => {
                return {
                  label: e.name,
                  value: e.id.toString()
                };
              })}
              onChange={equipmentSelectionChange}
            />
          </div>
        </div>
      </Card>
      <br/>
      <Card header="Spools">
        {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size="large"/>
        </FlexBox>}
        {(!!selectedEquipment && !isLoading) && <div className="row">
          <div className="col-md-5">
            {spools.map((spool, index) => {
              return <Select
                key={spool.id}
                status={spool.originalThread?.mcpThreadId !== spool.thread.mcpThreadId ? 'success' : undefined}
                label={`Spool ${spool.id}`}
                value={getSpool(spool)}
                onChange={newThread => threadChange(newThread, index)}
                options={allThreadColors.map(t => {
                  return {
                    label: `${t.manufacturersId} - ${t.colorName}`,
                    value: t.manufacturersId
                  };
                })}
                isOptionDisabled={(option: any) => {
                  return !!spools.find(s => getColorCode(s.thread.mcpThreadId) == option.value);
                }}
              />;
            })}
          </div>
          <div className="col-md-7">
            <SpoolBoard type="barudan" currentSpools={spools} getSpoolStyle={getSpoolStyle} />
            <Button variant="primary" blockLevel={true} disabled={getThreadChanges().length <= 0}
                    onClick={resetSpoolBoard}>
              Reset Spool Board
            </Button>
          </div>
        </div>}
        {selectedEquipment === null &&
        <div>
          <FlexBox justifyContent="center">
            <Robot status="warning" size="lg"/>
          </FlexBox>
          <Alert
            status="info"
            message="Select an equipment to edit configuration"
            dismissible={false}
          />
        </div>}
      </Card>
      <br/>
      {!!selectedEquipment && <div className="row">
        <div className="col-md-12">
          <Card>
            {(!spools.some(s => s.thread === UninitializedThread)) ?
              <Button variant="primary" blockLevel={true}
                      disabled={getThreadChanges().length <= 0} onClick={save}>
                Save Changes
              </Button> : <Alert status="danger"
                                 message="You must set up spools before you can save."
                                 dismissible={false}/>}
          </Card>
        </div>
      </div>}
      <br/>
    </div>
  );
}

export default EmbroideryPressSetup;
