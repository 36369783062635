import { v4 as uuid } from 'uuid';
import './FluidCardList.scss';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import { Tooltip } from '@cimpress/react-components';
import React, { ReactElement } from 'react';

const Card = ({ columns, header, title, link, description, bodyContent, docLink }:
  {
    columns: number, header?: ReactElement, title: string, link: ReactElement,
    description: string, bodyContent?: string, docLink?: string
  }) =>
  <div className={`col-xs-6 col-sm-${12 / (columns - 1)} col-md-${12 / columns}`} style={{ paddingRight: '15px', paddingLeft: '15px' }}>
    <div className='card'>
      {docLink && <div style={{ position: 'absolute', right: '4px', top: '2px' }}>
        <a href={docLink} target='_blank' rel="noreferrer">
          <Tooltip contents={'Learn More'} >
            <IconBookOpen size='2x' style={{ pointerEvents: 'none' }} weight='light' />
          </Tooltip>
        </a>
      </div>}
      {header && <div className='card-header'>{header}</div>}
      {title && <h5 className={'title'}>{title}</h5>}
      {description && <p className={'description'}>{description}</p>}
      {bodyContent && <div style={{ padding: 15 }}>{bodyContent}</div>}
      {link && <div className={'link'}>{link}</div>}
    </div>
  </div>;


const FluidCardList = ({ cards, columns }: { cards: { header: ReactElement, title: string, link: ReactElement, description: string, bodyContent?: string, docLink?: string }[], columns: number }): ReactElement =>
  <div className={'fluid-card-list'}>
    <div className={'flex-container'}>
      {cards.map(card => <Card key={uuid()} columns={columns} {...card} />)}
    </div>
  </div>;

export default FluidCardList;
