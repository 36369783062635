import {ModalAlert} from '../types/modal-alert';
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {
  additionalFilters,
  AggregationType,
  aggregationTypes,
  LineLabelConfig,
  NewLineLabelConfig,
  PrintMethod,
  printMethods
} from '../types/line-label-config';
import {
  Button,
  Card,
  Checkbox,
  Modal,
  Select,
  TextField,
  Spinner,
} from '@cimpress/react-components';
import {Col, Row} from 'antd';
import {deleteResource, patch, post} from '../clients/AuthClient';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LabelSheetLayout} from '../types/label-sheet-layout';
import {LineWithName} from '../types/equipment';
import {SelectOption} from '../types/select';
import {PrintRequest} from '../types/print-request';
import {button, buttonDanger, buttonSuccess, buttonWarning} from '../types/button-styles';

//These are the current statuses we support, can add more if needed
const statuses = ['RIP', 'RIP Complete'];
const statusOptions: SelectOption[] = statuses.map(s => ({value: s, label: s}));

//List of test label sheets in assets
const testLabelSheets = ['test-a4.pdf', 'test-letter.pdf'];

const printMethodOptions: SelectOption<PrintMethod>[] = printMethods.map(printMethod => ({
  value: printMethod,
  label: printMethod
}));
const supportsQueue = (printMethod: string | undefined) => printMethod === 'Lpr';

const aggregationOptions: SelectOption<AggregationType>[] = aggregationTypes.map(t => ({value: t, label: t}));

const additionalFilterOptions: SelectOption[] = additionalFilters.map(f => ({value: f.key, label: _.startCase(f.key)}));

const getInitialConfig = (c: LineLabelConfig | undefined): NewLineLabelConfig => {
  return c ?? {
    name: '',
    lineNbrs: [],
    readyStatuses: [],
    additionalFilters: {},
    aggregationTypes: [],
    printerAddress: '',
    incompleteSheetThresholdSeconds: 600
  };
};

function LineLabelConfigCard({tenant, labelSheetLayouts, lines, originalLineLabelConfig, isAdding, refresh, showAlert, finishAdding}:
  {
    tenant: string,
    labelSheetLayouts: LabelSheetLayout[],
    lines: LineWithName[],
    originalLineLabelConfig?: LineLabelConfig,
    isAdding: boolean,
    refresh: () => void,
    showAlert: (alert: ModalAlert) => void,
    finishAdding?: () => void,
  }): ReactElement {

  const [isEditing, setIsEditing] = useState(isAdding);
  const [isLoading, setIsLoading] = useState(false);
  const [lineLabelConfig, setLineLabelConfig] = useState<NewLineLabelConfig>(() => getInitialConfig(originalLineLabelConfig));
  const [showTestLabelSheets, setShowTestLabelSheets] = useState(false);

  function editConfig(edit: (c: NewLineLabelConfig) => void): void {
    setLineLabelConfig(oldConfig => {
      const newConfig = _.cloneDeep(oldConfig);
      edit(newConfig);
      return newConfig;
    });
  }

  function editProperty<K extends keyof NewLineLabelConfig>(key: K, value: NewLineLabelConfig[K]): void {
    editConfig(c => {
      c[key] = value;
    });
  }

  const lineOptions: SelectOption[] = useMemo(() => {
    return lines.map(l => {
      return {
        label: l.lineName,
        value: l.lineNbr.toString()
      };
    });
  }, [lines]);

  const selectedLines: SelectOption[] = useMemo(() => {
    return lineLabelConfig.lineNbrs.map(l => {
      const option = lineOptions.find(lo => +lo.value === l);
      return option ?? {label: 'Deleted Line', value: l.toString()};
    });
  }, [lineLabelConfig, lineOptions]);

  const labelLayoutOptions: SelectOption[] = useMemo(() => {
    return labelSheetLayouts.map(l => {
      return {
        label: l.name,
        value: l.id
      };
    });
  }, [labelSheetLayouts]);

  const selectedLabelLayout: SelectOption | undefined = useMemo(() =>
    labelLayoutOptions.find(llo => llo.value === lineLabelConfig.layoutId),
    [lineLabelConfig, labelLayoutOptions]);

  const selectedReadyStatuses: SelectOption[] = useMemo(() => {
    return lineLabelConfig.readyStatuses.map(s => {
      const option = statusOptions.find(so => so.value === s);
      return option ?? {label: s, value: s};
    });
  }, [lineLabelConfig]);

  const selectedPrintMethod: SelectOption | undefined = useMemo(() => printMethodOptions.find(p => p.value === lineLabelConfig.printMethod),
    [lineLabelConfig]);

  const selectedAggregationOptions: SelectOption<AggregationType>[] = useMemo(() => {
    return lineLabelConfig.aggregationTypes.map(at => {
      const option = aggregationOptions.find(ao => ao.value === at);
      return option ?? {label: at, value: at};
    });
  }, [lineLabelConfig]);

  useEffect(() => {
    if (!supportsQueue(lineLabelConfig.printMethod) && !!lineLabelConfig.queueName) {
      editProperty('queueName', undefined);
    }
  }, [lineLabelConfig]);

  const handleError = useCallback((e: any) => {
    console.error(e);
    showAlert({
      message: e.message,
      title: 'Received an Error from the Label Sheet Service',
      type: 'danger'
    });
  }, [showAlert]);

  const updateLineLabelConfig = (configId: string) => {
    setIsLoading(true);
    setIsEditing(false);
    patch<NewLineLabelConfig>(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/lineLabelConfiguration/${configId}`, lineLabelConfig)
      .then(() => {
        setIsLoading(false);
        refresh();
      }).catch(handleError)
      .finally(() => {
      setIsLoading(false);
    });
  };

  const saveNewLineLabelConfig = () => {
    post<NewLineLabelConfig>(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/lineLabelConfiguration`, lineLabelConfig)
      .then(() => {
        if (finishAdding) {
          finishAdding();
        }
        refresh();
      })
      .catch(handleError);
  };

  const deleteLineLabelConfig = (configId: string) => {
    setIsLoading(true);
    deleteResource(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/lineLabelConfiguration/${configId}`)
      .then(() => {
        refresh();
      })
      .catch(handleError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const printTestLabelSheet = (labelSheet: string) => {
    if (!lineLabelConfig.printerAddress || !lineLabelConfig.printMethod) {
      showAlert({
        message: <div>Please configure - print address, print method</div>,
        title: 'Missing configuration',
        type: 'danger'
      });
      setShowTestLabelSheets(false);
    } else {
      post<PrintRequest>(`${process.env.REACT_APP_CONDUIT_SERVICE_URL}/v1/tenant/${tenant}/files/print`, {
        printerAddress: lineLabelConfig.printerAddress,
        contentUrl: `${process.env.REACT_APP_ADMIN_UI_URL}/label-sheets/${labelSheet}`,
        queueName: lineLabelConfig.queueName,
        method: lineLabelConfig.printMethod
      }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from Conduit',
          type: 'danger'
        });
      }).finally(() => setShowTestLabelSheets(false));
    }
  };

  return<>
    {isLoading &&
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Spinner size="medium" />
      </div>}
    <Card header={
      <Row>
        <Col span={12}>
          {isEditing ? <TextField label="Name" value={lineLabelConfig.name}
                                  onChange={(e) => editProperty('name', e.target.value)}
            /> : lineLabelConfig.name}
        </Col>
        <Col span={12}>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button className={button} style={{marginLeft: '1rem'}}
                    onClick={() => setShowTestLabelSheets(true)}>
              <FontAwesomeIcon icon="print" /> Print Test Document
            </Button>
            {isEditing &&
              <>
                {
                  isAdding ?
                    <Button variant="primary"
                            className={buttonSuccess}
                            style={{marginLeft: '1rem'}}
                            onClick={() => saveNewLineLabelConfig()}>
                      <FontAwesomeIcon icon="save" /> Save New Config
                    </Button> :
                    <Button variant="primary"
                            style={{marginLeft: '1rem'}}
                            className={buttonSuccess}
                            onClick={() => updateLineLabelConfig(originalLineLabelConfig?.id ?? '')}>
                      <FontAwesomeIcon icon="save" /> Save
                    </Button>
                }
                <Button variant="primary"
                        style={{marginLeft: '1rem'}}
                        className={buttonWarning}
                        onClick={() => {
                          setIsEditing(false);
                          if (finishAdding) {
                            finishAdding();
                          }
                          setLineLabelConfig(getInitialConfig(originalLineLabelConfig));
                        }}>
                  <FontAwesomeIcon icon="times" /> Cancel
                </Button>
              </>
            }
            {!isEditing &&
              <>
                <Button className={button} variant="primary"
                        style={{marginLeft: '1rem'}}
                        onClick={() => setIsEditing(true)}>
                  <FontAwesomeIcon icon="edit" /> Edit Config
                </Button>
                <Button variant="primary"
                        style={{marginLeft: '1rem'}}
                        className={buttonDanger}
                        onClick={() => deleteLineLabelConfig(originalLineLabelConfig?.id ?? '')}>
                  <FontAwesomeIcon icon="trash" /> Delete Config
                </Button>
              </>
            }
          </div>
        </Col>
      </Row>
    }>
      <>
        <div>
          {/*Lines Selection*/}
          <Select
            isClearable
            isSearchable
            isDisabled={!isEditing}
            isMulti={true as any}
            onChange={(value) => editProperty('lineNbrs', (value as unknown as SelectOption[] | undefined)?.map(v => +v.value) ?? [])}
            label="Associated Lines"
            options={lineOptions}
            value={selectedLines as any}
          /><hr/>
        </div>
        <div>
          {/*Layout Selection*/}
          <Select
            isClearable
            isSearchable
            isDisabled={!isEditing}
            status={isEditing && !lineLabelConfig.layoutId ? 'error' : undefined}
            isMulti={false as any}
            onChange={
            (value) => editProperty('layoutId', value?.value ?? '')}
            label="Associated Layout"
            options={labelLayoutOptions}
            value={selectedLabelLayout}
          /><hr/>
        </div>
        <div>
          {/*Ready Statuses Selection*/}
          <Select
            isClearable
            isSearchable
            isDisabled={!isEditing}
            status={!_.isEqual(_.sortBy(lineLabelConfig.readyStatuses), _.sortBy(originalLineLabelConfig?.readyStatuses)) && lineLabelConfig.readyStatuses.length > 0 ? 'success' : undefined}
            isMulti={true as any}
            onChange={(value) => editProperty('readyStatuses', (value as unknown as SelectOption[] | undefined)?.map(v => v.value) ?? [])}
            label="Ready For Label Sheet Statuses"
            options={statusOptions}
            value={selectedReadyStatuses as any}
          />
          <hr/>
        </div>
        <div>
          {/*Additional Filters*/}
          <h6>Additional Filtering Properties</h6>
          <table className="table table-bordered table-hover">
            <thead>
            <tr>
              <th>Property Name</th>
              <th>Property Value</th>
              <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(lineLabelConfig.additionalFilters).map((property, i) => {
              const propertyOption = additionalFilterOptions.find(f => f.value === property);
              const filterType = additionalFilters.find(f => f.key === property)?.type;
              const value = lineLabelConfig.additionalFilters[property];
              return <tr key={i}>
                <td>
                  <Select
                    isSearchable
                    isDisabled={!isEditing}
                    onChange={(value) => {
                      editConfig(c => {
                        const newProperty = value?.value ?? '';
                        const defaultValue = additionalFilters.find(f => f.key === newProperty)?.defaultValue ?? '';
                        delete c.additionalFilters[property];
                        c.additionalFilters[newProperty] = defaultValue;
                      });
                    }}
                    isOptionDisabled={(option: any) => option.value !== property && Object.prototype.hasOwnProperty.call(lineLabelConfig.additionalFilters, option.value)}
                    options={additionalFilterOptions}
                    value={propertyOption}
                  />
                </td>
                <td>
                  {
                    filterType === 'boolean' && <Checkbox
                      checked={!!value}
                      label={<></>}
                      onChange={e => {
                        // needed to capture the closure
                        const newValue = e.target.checked;
                        editConfig(c => {
                          c.additionalFilters[property] = newValue;
                        });
                      }}
                    />
                  }
                  {
                    filterType === 'string' && <TextField
                      value={value?.toString()}
                      disabled={!isEditing}
                      onChange={e => {
                        editConfig(c => {
                          c.additionalFilters[property] = e.target.value;
                        });
                      }} />
                  }

                </td>
                <td><Button disabled={!isEditing} onClick={() => {
                  editConfig(c => {
                    delete c.additionalFilters[property];
                  });
                }} className={buttonDanger}><FontAwesomeIcon icon="trash" /></Button></td>
              </tr>;
            })}
            </tbody>
          </table>
          <Button className={button} blockLevel disabled={!isEditing || Object.prototype.hasOwnProperty.call(lineLabelConfig.additionalFilters, '')} onClick={() => {
            editConfig(c => {
              c.additionalFilters[''] = '';
            });
          }}><FontAwesomeIcon icon="plus" /> Add Property</Button>
          <hr/>
        </div>
        <div>
          {/*Seconds To Wait Prior To Printing Incomplete Sheet*/}
          <TextField label="Seconds to wait before printing incomplete sheet"
                     value={lineLabelConfig.incompleteSheetThresholdSeconds}
                     type="number"
                     disabled={!isEditing}
                     onChange={(e) => editProperty('incompleteSheetThresholdSeconds', e.target.value ? Number(e.target.value) : undefined as any)}/>
          <hr/>
        </div>
        <div>
          {/*Aggregation*/}
          <Select
            isClearable
            isSearchable
            isDisabled={!isEditing}
            status={!_.isEqual(_.sortBy(lineLabelConfig.aggregationTypes), _.sortBy(originalLineLabelConfig?.aggregationTypes)) ? 'success' : undefined}
            isMulti={true as any}
            onChange={(value) => editProperty('aggregationTypes', (value as unknown as SelectOption<AggregationType>[] | undefined)?.map(v => v.value) ?? [])}
            label="How should gangs be aggregated onto label sheets"
            options={aggregationOptions}
            value={selectedAggregationOptions as any}
          />
          <hr/>
        </div>
        <div>
          {/*Printer Address*/}
          <TextField label="Printer Address"
                     value={lineLabelConfig.printerAddress}
                     type="text"
                     disabled={!isEditing}
                     onChange={(e) => editProperty('printerAddress', e.target.value)}/>
        </div>
        <hr/>
        <div>
          {/*Print Method Selection*/}
          <Select
            isClearable
            isSearchable
            isDisabled={!isEditing}
            onChange={(value) => {
              if (value && value.value) {
                editProperty('printMethod', value.value as PrintMethod | undefined);
              }
            }}
            label="Print Method"
            options={printMethodOptions}
            value={selectedPrintMethod}
          />
        </div>
        <div>
          {/*QueueName*/}
          {supportsQueue(lineLabelConfig.printMethod) &&
            <>
              <hr/>
              <TextField label="Queue Name"
                         value={lineLabelConfig.queueName}
                         type="text"
                         disabled={!isEditing}
                         onChange={(e) => editProperty('queueName', e.target.value)}/>
            </>
          }

        </div>
      </>
    </Card>
    <br/>
    <Modal
      status="info"
      show={showTestLabelSheets}
      title="Select a test label sheet to print"
      closeButton={true}
      onRequestHide={() => setShowTestLabelSheets(false)}
      closeOnOutsideClick
    >
      <Row>
        {
          testLabelSheets.map((labelSheet) =>
            <Button className={button} key={labelSheet} variant="primary" blockLevel={true} onClick={() => printTestLabelSheet(labelSheet)}>
              {labelSheet}
            </Button>
          )
        }
      </Row>
    </Modal>
  </>;

}

export default LineLabelConfigCard;

