import React, { ReactElement, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card, Dropdown,
  FlexBox, Label, Modal,
  Robot,
  TextField,
  Toggle,
  Tooltip
} from '@cimpress/react-components';
import TenantSelector, {TenantDisplayMetadata} from './TenantSelector';
import {Pipeline, PipelineTag, StepDefinition} from '../types/pipeline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import { getContrast } from '../utils';
import {deleteResource, post} from '../clients/AuthClient';
import { ModalAlert } from '../types/modal-alert';
import JobContainerSimulator from './JobContainerSimulator';
import {PropertyBag} from '../types/property-info';
import PipelineHistory from './PipelineHistory';
import {getPipelineTemplates} from '../types/PipelineTemplates';
import {
  addPipelineDropdown,
  button,
  buttonDanger,
  buttonSuccess,
  buttonWarning
} from '../types/button-styles';

function PipelineList({
  tenantMetadata,
  setTenantMetadata,
  pipelines,
  pipelineTags,
  propertyBags,
  variablePropertyBags,
  stepDefinitions,
  fileTypes,
  startEditing,
  startAdding,
  duplicate,
  startReordering,
  setTenants,
  startManagingTags,
  setIsLoading,
  refreshPipelines,
  showAlert,
  openPipeline,
  showPropertyOverview
}: {
  tenantMetadata: TenantDisplayMetadata | null,
  setTenantMetadata: (tenant: TenantDisplayMetadata) => void,
  pipelines: Pipeline[],
  pipelineTags: PipelineTag[],
  propertyBags: PropertyBag[],
  variablePropertyBags: PropertyBag[],
  stepDefinitions: StepDefinition[],
  fileTypes: string[],
  startEditing: (id: string) => void,
  startAdding: (pipeline: Pipeline) => void,
  duplicate: (id: string) => void,
  startReordering: () => void,
  setTenants: (tenants: TenantDisplayMetadata[]) => void,
  startManagingTags: () => void,
  setIsLoading: (isLoading: boolean) => void,
  refreshPipelines: () => void,
  showAlert: (alert: ModalAlert) => void,
  openPipeline: (pipeline: Pipeline) => void,
  showPropertyOverview: () => void
}): ReactElement {

  const [filter, setFilter] = useState('');
  const [tagFilter, setTagFilter] = useState([] as string[]);
  const [pipelineToDelete, setPipelineToDelete] = useState(null as Pipeline | null);
  const [pipelineToggled, setPipelineToggled] = useState(null as Pipeline | null);
  const [pipelineToOpenHistory, setPipelineToOpenHistory] = useState(null as Pipeline | null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('filter')) {
      filterChanged(queryParams.get('filter') ?? '');
    }
  }, []);

  const filterChanged = (filter: string) => {
    const lowerFilter = filter.toLowerCase();
    setFilter(lowerFilter);
    const queryParams = new URLSearchParams(location.search);
    if (!lowerFilter) {
      queryParams.delete('filter');
    } else {
      queryParams.set('filter', lowerFilter);
    }
    history.push({
      search: queryParams.toString()
    });
  };

  const tagFilterChange = (tagId: string) => {
    const newTags = [...tagFilter];
    if (newTags.includes(tagId)) {
      _.remove(newTags, t => t === tagId);
    } else {
      newTags.push(tagId);
    }
    setTagFilter(newTags);
  };

  const getChipsForTags = (tags: PipelineTag[]): ReactElement => {
    return (<FlexBox className="button-group" flexWrap="wrap" justifyContent="initial">
      {
        tags.map(t => {
          return <Button size="sm"
            style={{
              backgroundColor: t.hexColor,
              color: getContrast(t.hexColor),
              borderRadius: '16px',
              border: '1px solid rgba(0, 0, 0, 0.25)',
              fontSize: '12px',
              fontWeight: 500
            }}
            onClick={() => tagFilterChange(t.id)}
            key={t.id}>
            {tagFilter.includes(t.id) && <span><FontAwesomeIcon icon="check" /> </span>}{t.name}
          </Button>;
        })
      }
    </FlexBox>
    );
  };

  const getChipsForTagIds = (tagIds: string[]): ReactElement => {
    return getChipsForTags(pipelineTags.filter(t => tagIds.includes(t.id)) ?? []);
  };

  const humanReadablePipelineDescription = (pipeline: Pipeline): ReactElement => {
    const preConditions = pipeline.preconditions;

    if (preConditions.length === 0) {
      return <Alert status='warning' dismissible={false}
                    message="This pipeline has no conditions. It will match all job containers that a previous pipeline did not match." />;
    }

    return <h6>This pipeline will run if {preConditions.filter(c => c.value).map((condition, i) => {
      const value = JSON.parse(condition.value ?? '');
      const possibleValues = Array.isArray(value) ? value : [value.toString()];
      const property = propertyBags.find(pb => pb.propertyBagName === condition.propertyBagName)
        ?.properties?.find(p => p.propertyName === condition.propertyName);
      const displayValues = possibleValues.map(possibleValue =>
        property?.displayValues?.find(dv => dv.value === possibleValue)?.display ?? possibleValue) as string[];
      return <React.Fragment key={i}><Label size="lg" status="primary"
                    text={`${condition.propertyName} is ${displayValues.join(' or ')}`} />{preConditions.length - 1 > i && <> and </>}</React.Fragment>;
    })}, further pipelines will not be considered for the job container.</h6>;
  };

  const togglePipeline = (): void => {
    if (pipelineToggled == null) {
      return;
    }
    setIsLoading(true);
    post<any>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata?.tenant}/pipelines/enable/${pipelineToggled.id}/${!pipelineToggled.enabled}`, null)
      .then(() => {
        return refreshPipelines();
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Pipelines Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const deletePipeline = (pipeline: Pipeline | null): void => {
    if (!pipeline) {
      return;
    }
    setIsLoading(true);
    deleteResource(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata?.tenant}/pipelines/${pipeline.id}`).then(() => {
      return refreshPipelines();
    })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an error from the Pipelines service',
          type: 'danger'
        });
      })
      .finally(() => {
        setIsLoading(false);
        setPipelineToDelete(null);
      });
  };

  return (
    <>
      <div className="container">
        <Card header={<FlexBox flexFlow="row" alignItems="center" justifyContent="initial">Pipeline Manager
          <FlexBox style={{flex: '1 1 auto'}} flexFlow="row" alignItems="center" justifyContent="flex-end">
            <Button className={button} onClick={showPropertyOverview}> <FontAwesomeIcon icon="list-alt" /> Property Usage</Button>
          </FlexBox>
        </FlexBox>}>
          <div className="row">
            <div className="col-md-12">
              <TenantSelector setTenantMetadata={setTenantMetadata} setTenants={setTenants} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField placeholder="Filter" onChange={c => filterChanged(c.target.value)}
                value={filter}
                helpText="Search for Pipeline"
              />
            </div>
          </div>
          {tenantMetadata?.tenant &&
            <div className="row">
              <div className="col-sm-8">
                {
                  getChipsForTags(pipelineTags)
                }
              </div>
              <div className="col-sm-4">
                <FlexBox justifyContent="flex-end">
                  <Button className={button} onClick={startManagingTags}> <FontAwesomeIcon icon="tags" /> Manage
                    Tags</Button>
                </FlexBox>
              </div>
            </div>
          }
        </Card>
        <br />
        {!tenantMetadata?.tenant ?
          <Card>
            <FlexBox justifyContent="center">
                <Robot status="warning" size="lg" />
            </FlexBox>
            <Alert
              status="info"
              message="Select a tenant to edit configuration"
              dismissible={false}
            />
          </Card> :
          <Card header={
            <FlexBox flexWrap="wrap">
              <FlexBox justifyContent="flex-start">
                <JobContainerSimulator tenantMetadata={tenantMetadata} showAlert={showAlert} startEditing={startEditing} pipelines={pipelines} propertyBags={propertyBags}/>
              </FlexBox>
              <FlexBox className="button-group"justifyContent="flex-end">
                <Dropdown className={addPipelineDropdown} title="Add Pipeline">
                  {getPipelineTemplates(tenantMetadata).map(template => {return {name: template.name, onClick: () => startAdding(template)};}).map((option, i) =>
                    <li key={i}>
                      <button onClick={() => {option.onClick();}}>
                        {option.name}
                      </button>
                    </li>)}
                </Dropdown>
                <Button className={button} onClick={startReordering}> <FontAwesomeIcon icon="sort" /> Reorder Pipelines
                </Button>
              </FlexBox>
            </FlexBox>
          }>
            {
              pipelines.filter(w => {
                const matchesFilter = !filter ? true : w.name.toLowerCase().includes(filter);
                const matchesTag = !tagFilter ? true : tagFilter.every(t => w.tags.includes(t));
                return matchesFilter && matchesTag;
              }).map(pipeline =>
                <React.Fragment key={pipeline.id}>
                  <Card header={
                    <FlexBox>
                      {pipeline.name}
                      <FlexBox className="edit-pipeline" justifyContent="flex-end">
                        <Tooltip contents="Enable/Disable Pipeline">
                          <Toggle
                            on={pipeline.enabled}
                            size="sm"
                            onClick={() => setPipelineToggled(pipeline)}
                          />
                        </Tooltip>
                        <Tooltip contents="Edit Pipeline">
                          <Button
                            variant="anchor"
                            size="lg"
                            icon={<FontAwesomeIcon icon="edit" />}
                            onClick={() => {
                              startEditing(pipeline.id);
                            }}
                          />
                        </Tooltip>
                        <Tooltip contents="Pipeline History">
                          <Button
                            variant="anchor"
                            size="lg"
                            icon={<FontAwesomeIcon icon="history" />}
                            onClick={() => {
                              setPipelineToOpenHistory(pipeline);
                            }}
                          />
                        </Tooltip>
                        <Tooltip contents="Duplicate Pipeline">
                          <Button
                            variant="anchor"
                            size="lg"
                            icon={<FontAwesomeIcon icon="copy" />}
                            onClick={() => {
                              duplicate(pipeline.id);
                            }}
                          />
                        </Tooltip>
                        <Tooltip contents="Delete Pipeline">
                          <Button
                            variant="anchor"
                            size="lg"
                            icon={<FontAwesomeIcon icon="trash" />}
                            onClick={() => setPipelineToDelete(pipeline)}
                          />
                        </Tooltip>
                      </FlexBox>
                    </FlexBox>
                  }>
                    {humanReadablePipelineDescription(pipeline)}
                    {getChipsForTagIds(pipeline.tags)}
                  </Card>
                  <br />
                </React.Fragment>
              )
            }
          </Card>
        }
      </div>
      {
        <Modal
          status={'danger'}
          show={!!pipelineToDelete}
          onRequestHide={() => setPipelineToDelete(null)}
          title={'Confirm delete'}
          closeButton={true}
          footer={(
            <Button className={buttonDanger} variant="primary" onClick={() => deletePipeline(pipelineToDelete)}>
              Delete
            </Button>
          )}
        >
          Confirm deletion of pipeline: <b>{pipelineToDelete?.name}</b>
        </Modal>
      }
      <Modal
        status="info"
        show={pipelineToggled != null}
        title="Enable/Disable Pipeline"
        footer={(
          <div className="button-group">
            <Button onClick={() => {
              setPipelineToggled(null);
            }} className={buttonWarning} variant="primary">
              Cancel
            </Button>
            <Button onClick={() => {
              togglePipeline();
              setPipelineToggled(null);
            }}
              className={buttonSuccess} variant="primary">
              Save
            </Button>
          </div>
        )}
      >
        Are you sure you would like to <b>{pipelineToggled?.enabled ? 'disable ' : 'enable '}</b>
        pipeline: <b>{pipelineToggled?.name}</b>?
      </Modal>
      <Modal
        status="info"
        show={!!pipelineToOpenHistory}
        onRequestHide={() => setPipelineToOpenHistory(null)}
        title="Pipeline History"
        closeButton={true}
        size={'lg'}
      >
        <PipelineHistory pipelineId={pipelineToOpenHistory?.id} tenant={tenantMetadata?.tenant} isActive={!!pipelineToOpenHistory} pipelineTags={pipelineTags} propertyBags={propertyBags} variablePropertyBags={variablePropertyBags} stepDefinitions={stepDefinitions} fileTypes={fileTypes} showAlert={showAlert} restorePipeline={(pipeline) => {
          openPipeline(pipeline);
        }}/>
      </Modal>
    </>);
}

export default PipelineList;
