import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { TenantHierarchy } from '../types/settings-types';
import { TreeSelect } from 'antd';
import { DataNode } from 'rc-tree/lib/interface';
import 'antd/dist/antd.min.css';
import { Copy, Tooltip } from '@cimpress/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function SettingsServiceTenantSelector({ tenantSelected, tenantHierarchy, prettyPrintTenant }: { tenantSelected: (tenant: string | undefined) => void,
  tenantHierarchy: TenantHierarchy[], prettyPrintTenant: (tenant: string) => string }): ReactElement {
  const [allTenants, setAllTenants] = useState([] as DataNode[]);
  const [selectedTenant, setSelectedTenant] = useState(undefined as string | undefined);
  const history = useHistory();
  const location = useLocation();

  function ConvertTenantHierarchyToTree(tenantHierarchy: TenantHierarchy): any {
    return {
      title: prettyPrintTenant(tenantHierarchy.tenant),
      value: tenantHierarchy.tenant,
      children: tenantHierarchy.children.map(t => ConvertTenantHierarchyToTree(t))
    };
  }

  useEffect(() => {
    setAllTenants(tenantHierarchy?.map(t => ConvertTenantHierarchyToTree(t)));
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('tenant')) {
      onChange(queryParams.get('tenant') ?? undefined);
    }
  }, [tenantHierarchy]);

  function onChange(value: string | undefined): void {
    setSelectedTenant(value);
    tenantSelected(value);
    const queryParams = new URLSearchParams(location.search);
    if (!value) {
      queryParams.delete('tenant');
    } else {
      queryParams.set('tenant', value);
    }
    history.push({
      search: queryParams.toString()
    });
  }

  return (
    <div className="row">
      <div className="col-md-10" >
      <TreeSelect
        treeData={allTenants}
        value={selectedTenant}
        size="large"
        placeholder='Select a Tenant'
        style={{ width: '100%' }}
        showSearch={true}
        treeDefaultExpandAll={true}
        onChange={onChange}
        allowClear={true}
      />
      </div>
      <div className="col-md-2">
        <Tooltip contents={selectedTenant ?? 'No tenant selected'}>
          <Copy variant="button" value={selectedTenant ?? ''}><FontAwesomeIcon icon="copy" /> Copy Tenant</Copy>
        </Tooltip>
      </div>
    </div>
  );
}

export default SettingsServiceTenantSelector;
