import {
  Button,
  Card,
  FlexBox, Select,
  TextField,
  Spinner,
} from '@cimpress/react-components';
import React, {ReactElement, useEffect, useState} from 'react';
import {
  postWithResponse
} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TenantSelector from './TenantSelector';
import {SelectOption} from '../types/select';
import {button} from '../types/button-styles';

function PrintJobStatusUpdater({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [tenant, setTenant] = useState('cimpress_viper_windsor_test');

  const [gangNumber, setGangNumber] = useState('');
  const [status, setStatus] = useState('');
  const [allStatuses, setAllStatuses] = useState([] as SelectOption[]);

  const ordersServiceUrls: Record<string, string> = {
    'cimpress_viper_windsor_test': 'https://orders.windsor.test.msw.cimpress.io',
    'cimpress_viper_venlo_test': 'https://orders.venlo.test.msw.cimpress.io',
    'cimpress_viper_bhiwandi_test': 'https://orders.bhiwandi.test.msw.cimpress.io',
    'cimpress_viper_deerpark_test': 'https://orders.deer-park.test.msw.cimpress.io',
    'cimpress_viper_windsor_dev': 'https://orders.windsor.dev.msw.cimpress.io',
    'cimpress_viper_venlo_dev': 'https://orders.venlo.dev.msw.cimpress.io',
    'cimpress_viper_bhiwandi_dev': 'https://orders.bhiwandi.dev.msw.cimpress.io',
    'cimpress_viper_deerpark_dev': 'https://orders.deer-park.dev.msw.cimpress.io'
  };

  useEffect(() => {
    getPossibleStatuses();
  }, [tenant]);

  const tenantSelectionChange = (tenant: string) => {
    setTenant(tenant);
  };

  const includeTenant = (tenant: string): boolean => {
    return tenant.includes('prod') ? false : !tenant.includes('reno');
  };

  const updateGangStatus = () => {
    if (tenant) {
      setIsLoading(true);
      const url = ordersServiceUrls[tenant];
      postWithResponse<any, any>(`${url}/orders-service`,
        {
          'query': 'mutation SetPrintJobStatus($printJobInput: PrintJobInput!) {printJob(request: $printJobInput){statusUpdated}}',
          'variables': {
            'printJobInput': {
              'newStatus': status,
              'gangNbr': gangNumber
            }
          },
          'operationName': 'SetPrintJobStatus'
        })
        .then(result => {
          console.log(result);
          if (result.data.printJob.statusUpdated){
            showAlert({
              message: <div>Successfully updated {gangNumber} to {status} in {tenant}.</div>,
              title: 'Response From Orders Service',
              type: 'info'
            });
          } else {
            showAlert({
              message: <div>Status for {gangNumber} not updated to {status} in {tenant}.</div>,
              title: 'Response From Orders Service',
              type: 'warning'
            });
          }
        }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: `Received an Error from the ${tenant} Orders Service`,
          type: 'danger'
        });
      }).finally(() => {
        setGangNumber('');
        setStatus('');
        setIsLoading(false);
      });
    }
  };

  const getPossibleStatuses = () => {
    if (tenant) {
      setIsLoading(true);
      const url = ordersServiceUrls[tenant];
      postWithResponse<any, string[]>(`${url}/orders-service`,
        {'query': 'query enumValuesOfMetaInformationTags { __type(name: "PrintJobStatus") { enumValues { name } } }'})
        .then(statuses => {
          const statusOptions = (statuses as any).data.__type.enumValues.map((val: { name: any; }) => {
            return {
              value: val.name,
              label: val.name
            };
          });
          setAllStatuses(statusOptions);
        }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: `Received an Error from the ${tenant} Orders Service`,
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      setAllStatuses([]);
    }
  };

  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox>
      }
      <Card header="Update Gang Status">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <TextField placeholder="Gang Number" onChange={c => setGangNumber(c.target.value)}
                         value={gangNumber}/>
            </div>
            <div className="col-md-4">
              <TenantSelector tenantSelected={tenantSelectionChange} includeTenant={includeTenant}/>
            </div>
            <div className="col-md-4">
              <Select
                isClearable
                isSearchable
                onChange={(value) => value? setStatus(value.label) : setStatus('')}
                label="Status"
                options={allStatuses}
                value={{label: status, value: status}}
              />
            </div>
          </div>
          <div className="row">
            <Button className={button} variant="primary" blockLevel={true} onClick={updateGangStatus}>
              <FontAwesomeIcon icon="sync" /> Update Status
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default PrintJobStatusUpdater;
