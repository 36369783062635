import {auth} from '../Auth';

function handleResponseWithJson(response: Response): Promise<any> {
  if (response.ok) {
    return response.json();
  } else {
    return response.text().then(e => {
      return Promise.reject(new Error(e));
    });
  }
}

function handleResponse(response: Response): Promise<void> {
  if (!response.ok) {
    return response.text().then(e => {
      return Promise.reject(new Error(e));
    });
  }
  return Promise.resolve();
}

export function get<T>(url: string): Promise<T> {
  return fetch(url, {
    headers: {
      'Authorization': `Bearer ${auth.getAccessToken()}`
    }
  }).then(handleResponseWithJson)
    .catch(err => {
      console.error(err);
      throw err;
    });
}

export function patch<T>(url: string, body: T): Promise<void> {
  return fetch(url, {
    headers: {
      'Authorization': `Bearer ${auth.getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(body)
  }).then(handleResponse).catch(err => {
    console.error(err);
    throw err;
  });
}

export function post<T>(url: string, body: T): Promise<any> {
  return fetch(url, {
    headers: {
      'Authorization': `Bearer ${auth.getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body)
  }).then(handleResponse).catch(err => {
    console.error(err);
    throw err;
  });
}

export function postWithResponse<T, U>(url: string, body: T): Promise<U> {
  return fetch(url, {
    headers: {
      'Authorization': `Bearer ${auth.getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(body)
  }).then(handleResponseWithJson)
    .catch(err => {
      console.error(err);
      throw err;
    });
}

export function put<T>(url: string, body: T): Promise<void> {
  return fetch(url, {
    headers: {
      'Authorization': `Bearer ${auth.getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify(body)
  }).then(handleResponse).catch(err => {
    console.error(err);
    throw err;
  });
}

export function deleteResource(url: string): Promise<void> {
  return fetch(url, {
    headers: {
      'Authorization': `Bearer ${auth.getAccessToken()}`
    },
    method: 'DELETE',
  }).then(handleResponse).catch(err => {
    console.error(err);
    throw err;
  });
}

export function deleteResourceWithBody<T>(url: string, body: T): Promise<any> {
  return fetch(url, {
    headers: {
      'Authorization': `Bearer ${auth.getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    method: 'DELETE',
    body: JSON.stringify(body)
  }).then(handleResponse).catch(err => {
    console.error(err);
    throw err;
  });
}

