import React, {ReactElement} from 'react';
import {Condition} from '../types/pipeline';
import {Card, RadioGroup, Radio} from '@cimpress/react-components';
import _ from 'lodash';
import FileNameCondition from './FileNameCondition';
import FileTypeCondition from './FileTypeCondition';
import PropertyCondition from './PropertyCondition';
import {PropertyBag} from '../types/property-info';

function ConditionSelector({condition, updateStepCondition, removeStepCondition, propertyBags, conditions, fileTypes, disabled}:
  {condition: Condition, updateStepCondition: (condition: Condition) => void,
    removeStepCondition: () => void, propertyBags: PropertyBag[], conditions: Condition[], fileTypes: string[], disabled: boolean}): ReactElement {

  const onConditionTypeChange = (e: any, value: any): void => {
    const newCondition = _.cloneDeep(condition);
    newCondition.conditionType = value;
    newCondition.propertyBagName = undefined;
    newCondition.propertyName = undefined;
    newCondition.value = undefined;
    newCondition.operatorType = undefined;
    updateStepCondition(newCondition);
  };

  return (
    <Card>
      <RadioGroup onChange={onConditionTypeChange} inline valueSelected={condition.conditionType} name="stepcondition">
        <Radio label="Property" value="Property" disabled={disabled}/>
        <Radio label="Filename" value="Filename" disabled={disabled}/>
        <Radio label="FileType" value="FileType" disabled={disabled}/>
      </RadioGroup>
      {condition.conditionType === 'Filename' &&
        <FileNameCondition condition={condition}
                           updateStepCondition={updateStepCondition}
                           removeSelf={removeStepCondition}
                           disabled={disabled}/>}
      {condition.conditionType === 'FileType' &&
      <FileTypeCondition condition={condition}
                         fileTypes={fileTypes}
                         updateStepCondition={updateStepCondition}
                         removeSelf={removeStepCondition}
                         disabled={disabled}/>}
      {condition.conditionType === 'Property' &&
        <PropertyCondition condition={condition}
                           propertyBags={propertyBags}
                           removeSelf={removeStepCondition}
                           updateCondition={updateStepCondition}
                           conditions={conditions}
                           disabled={disabled}/>}
    </Card>);
}

export default ConditionSelector;
