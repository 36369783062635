import React, { ReactElement, useEffect, useState } from 'react';
import { get } from '../clients/AuthClient';
import { ModalAlert } from '../types/modal-alert';
import {Accordion, FlexBox, Spinner} from '@cimpress/react-components';
import {AuditEvent, Equipment, LineWithName} from '../types/equipment';
import _ from 'lodash';

function EquipmentHistory({ equipment, lines, tenant, isActive, showAlert }: { equipment: Equipment, lines:LineWithName[], tenant?: string, isActive: boolean, showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [equipmentHistory, setEquipmentHistory] = useState([] as AuditEvent[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isActive) {
      setIsLoading(true);
      get<AuditEvent[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment/${equipment.equipmentId}/History`)
        .then(historyResponse => {
          setEquipmentHistory(historyResponse);
        }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Equipment Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [isActive]);

  const getLineName = (lineNbr: number) : string => {
    const line = lines.find(l => l.lineNbr === lineNbr);
    return line ? line.lineName : '';
  };

  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size='medium' />
      </FlexBox>
      }
      {equipmentHistory.map(eh => {
        return <Accordion key={`${eh.timestamp}${eh.action}` }
                          title={<><span>User: {eh.username}</span><br/><span>Timestamp: {new Date(eh.timestamp).toLocaleString()}</span><br/><span>Action: {eh.action} </span></>}>
          <div className='row'>
            <h4>
              {eh.equipment.equipmentNbr} - {eh.equipment.name} - {_.startCase(eh.equipment.decorationTechnology)}
            </h4>
          </div>
          <div className='col-md-8'>
            <h4>Properties</h4>
            {eh.equipment.properties.map(ep => {
              return <p key={ep.propertyId}>
                <strong>{ep.name}</strong> : {ep.value}
              </p>;
            })}
          </div>
          <div className='col-md-4'>
            <h4>Lines</h4>
            {eh.equipment.lines.map(el => {
              return <p key={el.lineNbr}>
                <strong>{getLineName(el.lineNbr)}</strong>
              </p>;
            })}
          </div>
        </Accordion>;
      })}
    </>
  );
}
export default EquipmentHistory;
