import React, {ReactElement, useEffect, useState} from 'react';
import {
  Column, ColumnInstance, FilterValue,
  useFilters, useGlobalFilter, usePagination,
  useTable
} from 'react-table';
import {Button, FlexBox, Pagination, Select, TextField} from '@cimpress/react-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


function DefaultColumnFilter<D extends Record<string, unknown>>({column}: {column: ColumnInstance<D>}) {

  return (
    <TextField
      value={column.filterValue || ''}
      onChange={e => {
        column.setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={'Search'}
    />
  );
}

export function SelectColumnFilter<D extends Record<string, unknown>>({column}: {column: ColumnInstance<D>}): ReactElement {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set<string>();
    column.preFilteredRows.forEach(row => {
      options.add(row.values[column.id]);
    });
    return Array.from(options.values()).map(option => {return {label: option, value: option};});
  }, [column.id, column.preFilteredRows]);

  return (
      <Select
        isClearable
        value={!column.filterValue ? undefined : {label: column.filterValue, value: column.filterValue}}
        onChange={e => {
          column.setFilter(e?.value ?? undefined);
        }}
        options={options}
      >
      </Select>
  );
}

function FilterableTable<D extends Record<string, unknown>>({columns, data, globalFilter}: {columns: Column<D>[], data: D[], globalFilter?: FilterValue}): ReactElement {

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      filter: 'exactText'
    }),
    []
  );

  const defaultPageSize = 15;

  const [paginate, setPaginate] = useState(true);

  useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [globalFilter]);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageCount,
    page,
    rows,
    gotoPage,
    setPageSize,
    setGlobalFilter
  } = useTable({columns, data, defaultColumn, initialState: {pageSize: paginate ? defaultPageSize : undefined}}, useFilters, useGlobalFilter, usePagination);

  useEffect(() => setPageSize(paginate ? defaultPageSize : rows.length), [rows, paginate]);

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead>
        {
          headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}  style={{textTransform: 'none'}}>
              {headerGroup.headers.map(column => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {<div>{column.canFilter && column.render('Filter')}</div>}
                </th>
              ))}
            </tr>
          ))
        }
        <tr>
        </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          page.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {
                  row.cells.map(cell => {
                    // eslint-disable-next-line react/jsx-key
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })
                }
              </tr>
            );
          })
        }
        </tbody>
      </table>
      <FlexBox className="button-group" alignItems="center" justifyContent="flex-end">
        {
          pageCount > 1 && paginate &&
          <>
            <div
            ><Pagination onPageChange={({ selected }: { selected: number }) => {
              gotoPage(selected);
            }
            } pageCount={pageCount} pageRangeDisplayed={2} marginPagesDisplayed={2} />
            </div>
            <div><Button onClick={() => setPaginate(false)}><FontAwesomeIcon icon="window-maximize"/> Disable Pagination</Button></div>
          </>
        }
        {
          !paginate && <Button onClick={() => setPaginate(true)}><FontAwesomeIcon icon="window-restore"/> Paginate</Button>
        }
      </FlexBox>
    </>
  );
}

export default FilterableTable;
