import React, {ReactElement, useEffect, useState} from 'react';
import TenantSelector, {TenantDisplayMetadata} from './TenantSelector';
import {Card, Copy, Tooltip, colors} from '@cimpress/react-components';
import {get} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import _ from 'lodash';
import moment from 'moment';

type HeartbeatResponse = Record<string, Record<string, LatestHeartbeat>>;

interface HostInfo {
  machineName: string;
  processName: string;
  processId: number;
  assembly: string;
  assemblyVersion: string;
  frameworkVersion: string;
  massTransitVersion: string;
  operatingSystemVersion: string;
}

interface LatestHeartbeat {
  hostInfo: HostInfo;
  sentTime: string;
  receivedTime: string;
  tenants: string[];
}

function ConduitMonitor({showAlert}: { showAlert: (alert: ModalAlert) => void }) : ReactElement {
  const [tenantFilter, setTenantFilter] = useState('');
  const [tenants, setTenants] = useState([] as TenantDisplayMetadata[]);
  const [heartbeat, setHeartbeat] = useState(null as null | HeartbeatResponse);


  useEffect(() => {
    refreshHeartbeats();
    const id = setInterval(refreshHeartbeats, 1000 * 5);
    return () => clearInterval(id);
  }, []);

  const refreshHeartbeats = () => {
    get<HeartbeatResponse>(`${process.env.REACT_APP_CONDUIT_SERVICE_URL}/v1/heartbeats`).then(response => {
      if (response) {
        setHeartbeat(response);
      }
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from Conduit',
        type: 'danger'
      });
    });
  };

  const updateTenants = (newTenants: TenantDisplayMetadata[]) => {
    const environmentOrder = ['prod', 'test', 'dev'];
    setTenants(_.sortBy(newTenants, t => environmentOrder.indexOf(t.environment), t => t.business_unit, t => t.plant_name));
  };

  return (
    <Card header="Conduit Monitor">
      <TenantSelector tenantSelected={setTenantFilter} setTenants={updateTenants} />
      <table className="table">
        <thead>
          <tr>
            <th>Tenant</th>
            <th>Machine</th>
            <th>Process</th>
            <th>Last Heartbeat (Sent)</th>
            <th>Last Heartbeat (Received)</th>
            <th>Latency (ms)</th>
          </tr>
        </thead>
        <tbody>
          {
            heartbeat && tenants.filter(t => tenantFilter ? t.tenant === tenantFilter : true).map(tenant => {
              if (!heartbeat || !heartbeat[tenant.tenant] || Object.keys(heartbeat[tenant.tenant]).length === 0) {
                return <tr key={tenant.tenant}>
                  <td style={{color: colors.danger.base}}>{tenant.prettyTenant}</td>
                  <td style={{color: colors.danger.base}}>None</td>
                  <td style={{color: colors.danger.base}}></td>
                  <td style={{color: colors.danger.base}}></td>
                  <td style={{color: colors.danger.base}}></td>
                  <td style={{color: colors.danger.base}}></td>
                </tr>;
              }
              const heartbeats = heartbeat[tenant.tenant];
              return <React.Fragment key={tenant.tenant}>
                {Object.keys(heartbeats).map(machineId => {
                  const machineHeartbeat = heartbeats[machineId];
                  const sent = moment(machineHeartbeat.sentTime);
                  const received = moment(machineHeartbeat.receivedTime);
                  const elapsed = received.diff(sent);
                  return <tr key={tenant.tenant + machineId}>
                    <td style={{color: colors.success.base}}>{tenant.prettyTenant}</td>
                    <td style={{color: colors.success.base}}>
                      <Tooltip contents={
                          <Copy variant="button" value={`application:"MIS/Viper/FileTransferService" AND MachineName:"${machineHeartbeat.hostInfo.machineName}"`}>NewRelic Logs</Copy>}>
                        {machineHeartbeat.hostInfo.machineName} - {machineHeartbeat.hostInfo.operatingSystemVersion}
                      </Tooltip>
                    </td>
                    <td style={{color: colors.success.base}}>{machineHeartbeat.hostInfo.processName}:{machineHeartbeat.hostInfo.processId} v{machineHeartbeat.hostInfo.assemblyVersion} .NET: {machineHeartbeat.hostInfo.frameworkVersion}</td>
                    <td style={{color: colors.success.base}}>{sent.format('HH:mm:ss:SSS')}</td>
                    <td style={{color: colors.success.base}}>{received.format('HH:mm:ss:SSS')}</td>
                    <td style={{color: colors.success.base}}>{elapsed}ms {elapsed > 30000 && <span style={{color: colors.warning.base}}>Latency is &gt;30s, verify clock is accurate on {machineHeartbeat.hostInfo.machineName}</span>}</td>
                  </tr>;
                })}
              </React.Fragment>;
            })
          }
        </tbody>
      </table>
    </Card>
  );
}

export default ConduitMonitor;
