import {ModalAlert} from '../types/modal-alert';
import React, {ReactElement, useState} from 'react';
import {LineWithName} from '../types/equipment';
import {Button} from '@cimpress/react-components';
import {LineLabelConfig} from '../types/line-label-config';
import _ from 'lodash';
import LineLabelConfigCard from './LineLabelConfigCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LabelSheetLayout} from '../types/label-sheet-layout';
import {button} from '../types/button-styles';

function LineLabelConfigSetup({tenant, filter, sortAscending, lines, labelSheetLayouts, lineLabelConfigs, refresh, showAlert}:
  { tenant: string, filter: string | undefined, sortAscending: boolean, lines: LineWithName[], labelSheetLayouts: LabelSheetLayout[], lineLabelConfigs: LineLabelConfig[],
    refresh: () => void, showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isAdding, setIsAdding] = useState<boolean>(false);

  return (<>
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <Button className={button} onClick={() => setIsAdding(true)}>
        <FontAwesomeIcon icon="plus" /> Add Line Label Config
      </Button>
    </div>
    <br/>
    {isAdding && <>
      <LineLabelConfigCard tenant={tenant}
                           isAdding={true}
                           refresh={refresh}
                           lines={lines}
                           labelSheetLayouts={labelSheetLayouts}
                           showAlert={showAlert}
                           finishAdding={() => setIsAdding(false)}
      />
      <br/>
    </>}
    {_.orderBy(lineLabelConfigs.filter(lineLabelConfig =>
      !filter || lineLabelConfig.name.toLowerCase().includes(filter.toLowerCase())), l => l.name, [sortAscending ? 'asc' : 'desc'])
      .map(lineLabelConfig => {
        return <React.Fragment key={lineLabelConfig.id}>
          <LineLabelConfigCard tenant={tenant}
                               lines={lines}
                               labelSheetLayouts={labelSheetLayouts}
                               originalLineLabelConfig={lineLabelConfig}
                               isAdding={false}
                               refresh={refresh}
                               showAlert={showAlert}
          />
          <br/>
        </React.Fragment>;
      })
    }
  </>);

}

export default LineLabelConfigSetup;
