import React, {ReactElement, useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  FlexBox,
  Label,
  Select,
  TextField,
  Spinner,
} from '@cimpress/react-components';
import Uploader from '@cimpress-technology/react-platform-uploader';
import {auth} from '../Auth';
import {UploadsResponse} from '../types/uploads-response';
import {InputFile, JobContainer, Pipeline, StepToTake} from '../types/pipeline';
import _ from 'lodash';
import {PropertyBag} from '../types/property-info';
import { v4 as uuid } from 'uuid';
import {TenantDisplayMetadata} from './TenantSelector';
import {get, post, postWithResponse} from '../clients/AuthClient';
import {SelectOption} from '../types/select';
import {ModalAlert} from '../types/modal-alert';
import {button, buttonDanger, buttonWarning} from '../types/button-styles';

function PipelineSimulator({ tenantMetadata, pipelines, propertyBags, jobContainerTypes, getStepToTakeComponent, showAlert }:
  { tenantMetadata?: TenantDisplayMetadata, pipelines: Pipeline[], propertyBags: PropertyBag[],
    jobContainerTypes: {label: string, value: string}[], getStepToTakeComponent:(step: StepToTake, index: number,
      EnqueueStepsToTake: (steps: StepToTake[]) => void) => ReactElement,
    showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [selectedPipeline, setSelectedPipeline] = useState(undefined as Pipeline | undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [entityIdentifierToSimulate, setEntityIdentifierToSimulate] = useState('');
  const [pipelineProperties, setPipelineProperties] = useState([] as string[]);
  const [jobContainer, setJobContainer] = useState(undefined as JobContainer | undefined);
  const [fileTypes, setFileTypes] = useState([] as SelectOption[]);
  const [newInputFileType, setNewInputFileType] = useState('');
  const [uploadNewInputFile, setUploadNewInputFile] = useState(false);
  const [jobContainerType, setJobContainerType] = useState('rip');
  const [stepsToTake, setStepsToTake] = useState([] as StepToTake[]);
  const [pipelineSubmitted, setPipelineSubmitted] = useState(false);

  useEffect(() => {
    if (!tenantMetadata) {
      return;
    }
    getFileTypes(tenantMetadata);
  },[tenantMetadata]);

  const getFileTypes = (tenant: TenantDisplayMetadata) => {
    return get<string[]>(`${process.env.REACT_APP_PRESSINTEGRATION_SERVICE_URL}/v1/Tenant/${tenant.tenant}/JobContainer/FileTypes`).then(fileTypes => {
      setFileTypes(fileTypes.map(filetype => ({'label': filetype, 'value': filetype} as SelectOption)));
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an error from Press Integration Service',
        type: 'danger'
      });
    });
  };

  const getAllPropertiesInPipeline = (pipeline?: Pipeline): void => {
    if (!pipeline) {
      return;
    }
    const propertiesInStepConditions = pipeline.steps.flatMap(step => step.conditions
      .filter(c => c.propertyBagName != null && c.propertyName != null)
      .map(c => c.propertyBagName + ':' + c.propertyName));
    const propertiesInStepParameters =  pipeline.steps.flatMap(step => Object.values(step.parameters)
      .filter(param => param.includes('$PIPELINE_VARIABLE:'))
      .map(param => param.split('$PIPELINE_VARIABLE:')[1]));
    const allProperties = [...propertiesInStepConditions, ...propertiesInStepParameters];
    generateJobContainer(allProperties);
    setPipelineProperties(_.uniq(allProperties));
    setEntityIdentifierToSimulate('');
    setUploadNewInputFile(false);
    setNewInputFileType('');
    setStepsToTake([]);
    setPipelineSubmitted(false);
  };

  const isBooleanPropertyType = (property: string): boolean => {
    const propertySplit = property.split(':');
    const type = propertyBags.find(p => p.propertyBagName === propertySplit[0])?.properties
      .find(p => p.propertyName === propertySplit[1])?.type;
    return type === 'boolean' || type === 'bool';
  };

  const isStringPropertyType = (property: string): boolean => {
    const propertySplit = property.split(':');
    return propertyBags.find(p => p.propertyBagName === propertySplit[0])?.properties
      .find(p => p.propertyName === propertySplit[1])?.type === 'string';
  };

  const getCurrentPropertyValue = (property: string): any => {
    const propertySplit = property.split(':');
    if (jobContainer?.propertyBags[propertySplit[0]][propertySplit[1]]) {
      const value = jobContainer.propertyBags[propertySplit[0]][propertySplit[1]];
      const displayValue = propertyBags.find(pb => pb.propertyBagName === propertySplit[0])?.properties
        .find(pb => pb.propertyName === propertySplit[1])?.displayValues?.find(dv => dv.value === value);
      return {
        label: displayValue?.display ?? value,
        value: value
      };
    }
    return undefined;
  };

  const getPossiblePropertyValues = (property: string): SelectOption[] => {
    const propertySplit = property.split(':');
    const properties = propertyBags.find(p => p.propertyBagName === propertySplit[0])?.properties;
    if (properties) {
      const propertyValues = properties.find(p => p.propertyName === propertySplit[1])?.displayValues;
      if (propertyValues) {
        return propertyValues.map(property => {
          return {
            label: property.display,
            value: property.value
          };
        });
      }
    }
    return [];
  };

  const generateJobContainer = (properties: string[]) => {
    let clonedJobContainer = _.cloneDeep(jobContainer);
    clonedJobContainer = {
      jobId: uuid(),
      inputFiles: [] as InputFile[],
      propertyBags: {} as Record<string, Record<string, string>>,
      entityReference: tenantMetadata?.tenant.replaceAll('_', '-') ?? ''
    } as JobContainer;
    properties.forEach(property => {
      const propertyBagName = property.split(':')[0];
      const propertyName = property.split(':')[1];
      if (!clonedJobContainer) {
        return;
      }
      if (!Object.keys(clonedJobContainer.propertyBags).includes(propertyBagName)) {
        clonedJobContainer.propertyBags[propertyBagName] = {};
      }
      if (isBooleanPropertyType(propertyBagName + ':' + propertyName)) {
        clonedJobContainer.propertyBags[propertyBagName][propertyName] = 'false';
      } else {
        clonedJobContainer.propertyBags[propertyBagName][propertyName] = '';
      }
    });
    setJobContainer(clonedJobContainer);
  };

  const generateJobContainerFromEntityReference = () => {
    setIsLoading(true);
    switch (tenantMetadata?.production_system_backend) {
      case 'viper':
        get<JobContainer>(`${process.env.REACT_APP_PRESSINTEGRATION_SERVICE_URL}/v1/Tenant/${tenantMetadata?.tenant}/JobContainer/${jobContainerType}/?gangNbr=${entityIdentifierToSimulate}`).then(j => {
          setJobContainer(j);
        }).catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an Error from the Press Integration Service',
            type: 'danger'
          });
        }).finally(() => setIsLoading(false));
        break;
      case 'paperless':
        get<any>(`${process.env.REACT_APP_PAPERLESS_RIP_ADAPTER_URL}/v1/${tenantMetadata?.tenant}/getJobContainer/${jobContainerType}?jobId=${entityIdentifierToSimulate}`).then(j => {
          setJobContainer(j);
        }).catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an Error from the Paperless Rip Adapter',
            type: 'danger'
          });
        }).finally(() => setIsLoading(false));
        break;
      default:
        showAlert({
          message: <div>This operation is not supported for tenant ${tenantMetadata?.tenant}</div>,
          title: 'Job Container simulation not supported',
          type: 'danger'
        });
        setIsLoading(false);
    }
  };

  const updateJobContainerProperties = (propertyBagName: string, propertyName: string, value: string | null) => {
    const propertyBags = _.cloneDeep(jobContainer?.propertyBags);
    if (!jobContainer || !propertyBags) {
      return;
    }
    propertyBags[propertyBagName ][propertyName] = value ?? '';
    setJobContainer({...jobContainer, propertyBags: propertyBags});
  };

  const replaceFile = (file: InputFile, response: UploadsResponse[]) => {
    if (!jobContainer) {
      return;
    }
    const uploadedFileName = response[0].name;
    const uploadedFileLocation = response[0].url;
    const inputFiles = _.cloneDeep(jobContainer.inputFiles);
    const fileToBeReplaced = inputFiles.find(f => f.type === file.type && f.name === file.name);
    if (!fileToBeReplaced) {
      return;
    }
    fileToBeReplaced.name = uploadedFileName;
    fileToBeReplaced.location = uploadedFileLocation;
    setJobContainer({ ...jobContainer, inputFiles: inputFiles });
  };

  const deleteFile = (file: InputFile) => {
    if (!jobContainer) {
      return;
    }
    const inputFiles = _.cloneDeep(jobContainer.inputFiles);
    const filesAfterDeletion = inputFiles.filter(f => f.type !== file.type || f.name !== file.name);
    setJobContainer({ ...jobContainer, inputFiles: filesAfterDeletion });
  };

  const addInputFile = (response: UploadsResponse[]) => {
    if (!jobContainer) {
      return;
    }
    const uploadedFileName = response[0].name;
    const uploadedFileLocation = response[0].url;
    const inputFile = {name: uploadedFileName, location: uploadedFileLocation, type: newInputFileType};
    const inputFiles: InputFile[] = [...jobContainer?.inputFiles ?? [], inputFile];
    setJobContainer({ ...jobContainer, inputFiles: inputFiles});
  };

  const deleteNewInputFile = () => {
    setUploadNewInputFile(false);
  };

  const getMatchingSteps = () => {
    postWithResponse<any, StepToTake[]>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata?.tenant}/pipelines/stepsToTake/${selectedPipeline?.id}`, jobContainer).then(steps => {
      setStepsToTake(steps);
    }).catch(() => {
      setStepsToTake([]);
    });
  };

  const getPipelineSubmittedAlert = () => {
    return pipelineSubmitted && <Alert status="success"
                                       message={<>Successfully submitted simulation. Track the status in&nbsp;
                                         <a href={`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/hangfire/tags/search/${jobContainer?.entityReference.toLowerCase()},simulation`}>
                                           Hangfire
                                         </a>.
                                       </>} onDismiss={() => setPipelineSubmitted(false)}/>;
  };

  const enqueueStepsToTake = (steps: StepToTake[]) => {
    setIsLoading(true);
    post<any>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata?.tenant}/steps/enqueue`, {
      stepsToTake: steps,
      callbackUrl: null,
      entityReference: jobContainer?.entityReference ?? '',
      tags: ['simulation']
    }).then(() => {
      setPipelineSubmitted(true);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Pipelines Service',
        type: 'danger'
      });
    }).finally(() => setIsLoading(false));
  };

  return <>
    <Select label="Select pipeline"
            options={pipelines.map(pipeline => { return {label: pipeline.name, value: pipeline.name}; })}
            value={{label: selectedPipeline?.name ?? '', value: selectedPipeline?.name ?? ''}}
            onChange={value => {
              if (value?.value === selectedPipeline?.name) {
                return;
              }
              const pipeline = pipelines.find(p => p.name === value?.value);
              setSelectedPipeline(pipeline);
              getAllPropertiesInPipeline(pipeline);
            }}/>
    {isLoading &&
    <FlexBox justifyContent="center">
      <Spinner size="medium" />
    </FlexBox>}
    <br/>
    <>
      {selectedPipeline && jobContainer && pipelineProperties
        .map((property, index) => {
          const propertyBagName = property.split(':')[0];
          const propertyName = property.split(':')[1];
          if (isBooleanPropertyType(property)) {
            return <Checkbox key={index}
              label={property}
              checked={jobContainer.propertyBags[propertyBagName][propertyName] === 'true'}
              onChange={(e) => { updateJobContainerProperties(propertyBagName, propertyName, e.target.checked.toString()); }}/>;
          } else if (isStringPropertyType(property)) {
            return <TextField key={index} label={property} value={jobContainer.propertyBags[propertyBagName][propertyName]}
                              onChange={e => { updateJobContainerProperties(propertyBagName, propertyName, e.target.value); }}/>;
          } else {
            return <Select key={index}
              isClearable
              label={propertyBagName + '.' + propertyName}
              value={getCurrentPropertyValue(property)}
              options={getPossiblePropertyValues(property)}
              onChange={(value: any) => {
                updateJobContainerProperties(propertyBagName, propertyName, value == null ? null : value.value);
              }}
            />;
          }
        })}
      {selectedPipeline && jobContainer && jobContainer?.inputFiles.map((inputFile, index) => {
        return <>
          <Card key={index}>
            <div><b>File name</b>: {inputFile.name}</div>
            <div><b>File type</b>: {inputFile.type}</div>
            <div><b>File location</b>:
              <Label style={{whiteSpace: 'normal', overflowWrap: 'anywhere'}}
                     text={<>{inputFile.location}</> as any} status="info" size="lg" />
            </div>
            <br/>
            <Uploader uploadTenant="press-integration"
                      accessToken={auth.getAccessToken()}
                      provideThumbnails={false}
                      onUploadFileStart={() => setIsLoading(true)}
                      onUploadFileSuccess={(response: UploadsResponse[]) => {
                        replaceFile(inputFile, response);
                        setIsLoading(false);
                      }}
                      onUploadFileError={(error: any) => {
                        console.log(error);
                        setIsLoading(false);
                      }}
                      deleteAfterDays={'1'}/>
            <br/>
            <Button size="sm" blockLevel className={buttonDanger}
                    onClick={() => deleteFile(inputFile)}>Delete</Button>
          </Card>
          <br/>
        </>;
      })}
      {(jobContainer && uploadNewInputFile) &&
      <Card>
        <div><b>File name</b>:</div>
        <Select options={fileTypes} onChange={e => {
          setNewInputFileType(e?.value ?? '');
        }}/>
        <div className={newInputFileType == '' ? 'grey-block' : undefined}>
          <div><b>File location</b>:</div>
          <br/>
          <Uploader uploadTenant="press-integration"
                    accessToken={auth.getAccessToken()}
                    provideThumbnails={false}
                    onUploadFileStart={() => setIsLoading(true)}
                    onUploadFileSuccess={(response: UploadsResponse[]) => {
                      addInputFile(response);
                      setIsLoading(false);
                      setUploadNewInputFile(false);
                    }}
                    onUploadFileError={(error: any) => {
                      console.log(error);
                      setIsLoading(false);
                    }}
                    deleteAfterDays={'1'}/>
        </div>
        <br/>
        <Button size="sm" blockLevel className={buttonWarning} onClick={() => deleteNewInputFile()}>Cancel</Button>
      </Card>}
      <br/>
      <Button size="sm" blockLevel variant="default" className={button} disabled={!selectedPipeline} onClick={() => setUploadNewInputFile(true)}>Add new file</Button>
      <br/>
      <Card className={!selectedPipeline ? 'grey-block' : undefined}>
        <TextField label="Enter entity reference" value={entityIdentifierToSimulate}
                   onChange={e => setEntityIdentifierToSimulate(e.target.value.trim())}/>
        <Select
          onChange={(value) => value ? setJobContainerType(value.value) : setJobContainerType('')}
          label="Job Container Type"
          options={jobContainerTypes}
          value={{label: _.startCase(jobContainerType), value: jobContainerType}}
        />
        <Button size="sm" blockLevel variant="default" className={button} disabled={!selectedPipeline || !entityIdentifierToSimulate} onClick={() => generateJobContainerFromEntityReference()}>Populate job container</Button>
      </Card>
      <br/>
      {<Button size="sm" variant="default" className={button} disabled={!selectedPipeline} blockLevel onClick={() => getMatchingSteps()}>Simulate!</Button>}
      <br/>
      {
        selectedPipeline && (jobContainer && stepsToTake.length > 0 ? stepsToTake.map((stepToTake, i) => {
          return (<>{getStepToTakeComponent(stepToTake, i, enqueueStepsToTake)}<br/></>);
        }) : <Alert status='warning' dismissible={false}
                    message="No steps to be taken to be displayed. Update pipeline files, properties and click on Simulate button." />)
      }
      <br/>
      {getPipelineSubmittedAlert()}
      <br/>
      <Button className={button} variant="primary" blockLevel disabled={stepsToTake.length == 0} onClick={() => enqueueStepsToTake(stepsToTake)}>
        Run All Steps
      </Button>
    </>
  </>;
}

export default PipelineSimulator;
