import React, {ReactElement, useEffect, useState} from 'react';
import {
  DecorationTechnology,
  Equipment,
  Line,
  LineWithName,
  Property,
  PropertyType
} from '../types/equipment';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Copy,
  FlexBox,
  Modal,
  Select,
  Spinner,
  TextField, Tooltip
} from '@cimpress/react-components';
import * as _ from 'lodash';
import {SelectOption} from '../types/select';
import {deleteResourceWithBody, patch, post, postWithResponse} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EquipmentHistory from './EquipmentHistory';
import { v4 as uuid } from 'uuid';
import {
  button,
  buttonDanger,
  buttonSuccess,
  buttonWarning
} from '../types/button-styles';

function EquipmentCard({
  equipment,
  tenant,
  lines,
  decorationTechnologies,
  showAlert,
  refreshAll,
  finishedAdding,
  defaultNewDecorationTechnology
}: { equipment?: Equipment, tenant: string, lines: LineWithName[],
  decorationTechnologies: DecorationTechnology[],
  showAlert: (alert: ModalAlert) => void,
  refreshAll: () => Promise<void>,
  finishedAdding?: () => void,
  defaultNewDecorationTechnology?: string}): ReactElement {
  const [newSelectedLines, setNewSelectedLines] = useState([] as Line[]);
  const [newProperties, setNewProperties] = useState([] as Property[]);
  const [newEquipmentName, setNewEquipmentName] = useState('');
  const [changeReason, setChangeReason] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newDecorationTechnology, setNewDecorationTechnology] = useState('');
  const [selectedDecorationTechnology, setSelectedDecorationTechnology] = useState(null as SelectOption | undefined | null);

  useEffect(() => {
    if (isCreating() && defaultNewDecorationTechnology) {
      setNewDecorationTechnology(defaultNewDecorationTechnology);
      setSelectedDecorationTechnology({
        label: _.startCase(defaultNewDecorationTechnology),
        value: defaultNewDecorationTechnology
      });
      const properties = getRequiredProperties(defaultNewDecorationTechnology)
        .concat(getOptionalProperties(defaultNewDecorationTechnology)).map(p => {
          return {
            value: '',
            name: p.name,
            propertyId: uuid(),
            type: p.type
          } as Property;
        });
      setNewProperties(_.sortBy(properties, p => p.name));
    }
  }, []);

  useEffect(() => {
    initializeStateVariables();
  }, [equipment, decorationTechnologies]);

  useEffect(() => {
    if (isCreating() || (newDecorationTechnology && (equipment?.decorationTechnology !== newDecorationTechnology))) {
      const properties = getRequiredProperties(newDecorationTechnology)
        .concat(getOptionalProperties(newDecorationTechnology)).map(p => {
        return {
          value: '',
          name: p.name,
          propertyId: uuid(),
          type: p.type
        } as Property;
      });
      setNewProperties(_.sortBy(properties, p => p.name));
    }
  }, [newDecorationTechnology, equipment]);

  const isCreating = (): boolean => {
    return !equipment;
  };

  const initializeStateVariables = () => {
    if (equipment)
    {
      sanitizeUserInput(equipment.name, _.sortBy(_.cloneDeep(equipment.properties), key => key.name));
      setNewSelectedLines(_.cloneDeep(equipment.lines));
      setNewDecorationTechnology(equipment.decorationTechnology);
      setSelectedDecorationTechnology({
        label: _.startCase(equipment.decorationTechnology),
        value: equipment.decorationTechnology
      });
    } else {
      setIsEditing(true);
    }
  };

  const performSave = (equipmentId: string): Promise<any> => {
    return Promise.all([...saveEquipmentNameChanges(), ...saveEquipmentLineChanges(equipmentId), ...saveEquipmentPropertyChanges(equipmentId)]).then(() => {
      setIsEditing(false);
      setIsSaving(false);
      setChangeReason('');
      return refreshAll();
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Equipment Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
      if (isCreating() && finishedAdding) {
        finishedAdding();
      }
    });
  };

  const saveEquipmentChanges = () => {
    setIsLoading(true);
    if (!equipment)
    {
      postWithResponse<any, string>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment`, {
        changeReason: changeReason,
        name: newEquipmentName,
        decorationTechnology: newDecorationTechnology
      }).then(performSave).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Equipment Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      performSave(equipment.equipmentId);
    }
  };

  const saveEquipmentNameChanges = (): Promise<any>[] => {
    if (equipment && (equipment.name !== newEquipmentName || equipment.decorationTechnology != newDecorationTechnology)) {
      setIsLoading(true);
      return [patch(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment/${equipment.equipmentId}`,
        {changeReason: changeReason, name: newEquipmentName, decorationTechnology: newDecorationTechnology})];
    } else {
      return [];
    }
  };

  const saveEquipmentLineChanges = (equipmentId: string): Promise<void>[] => {
    // need it figure out if we added or deleted (2 diff service calls)
    // lodash difference function
    const addedLinePromises = getAddedLines().map((line) => {
      return post(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment/${equipmentId}/LineAssociations`,
        {changeReason: changeReason, lineNbr: line.lineNbr});
    });
    const deletedLinePromises = getDeletedLines().map((line) => {
      return deleteResourceWithBody(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment/${equipmentId}/LineAssociations/${line.lineNbr}`,
        {changeReason: changeReason});
    });
    return [...addedLinePromises, ...deletedLinePromises];
  };

  const saveEquipmentPropertyChanges = (equipmentId: string): Promise<void>[] => {
    // add a property service call
    // update a property service call
    const addedPropertyPromises = getAddedProperties().map((property) => {
      return post(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment/${equipmentId}/Properties`,
        {
          changeReason: changeReason,
          name: property.name,
          type: property.type,
          value: property.value
        });
    });
    const updatedPropertyPromises = getModifiedProperties().map((property) => {
      return patch(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment/${equipmentId}/Properties/${property.propertyId}`,
        {
          changeReason: changeReason,
          name: property.name,
          type: property.type,
          value: property.value
        });
    });
    return [...addedPropertyPromises, ...updatedPropertyPromises];
  };

  const setProperty = (propertyName: string, value: string | undefined) => {
    setNewProperties(newProperties.map(property => {
      if (property.name === propertyName) {
        property.value = value ?? '';
      }
      return property;
    }));
  };

  const isChanged = (propertyName: string) => {
    const newProperty = newProperties.find(p => p.name === propertyName);
    const oldProperty = equipment?.properties.find(p => p.name === propertyName);
    return newProperty?.value !== oldProperty?.value;
  };

  const equipmentChangesExist = (): boolean => {
    return !newEquipmentName || equipment?.name !== newEquipmentName || equipment?.decorationTechnology !== newDecorationTechnology ||
      getDeletedLines().length > 0 || getAddedLines().length > 0 || getAddedProperties().length > 0 ||
      getDeletedProperties().length > 0 || getModifiedProperties().length > 0;
  };

  const getDeletedLines = (): Line[] => {
    return equipment ? equipment.lines.filter(l => !newSelectedLines.map(x => x.lineNbr).includes(l.lineNbr)): [];
  };

  const getAddedLines = (): Line[] => {
    return equipment ? newSelectedLines.filter(l => !equipment.lines.map(x => x.lineNbr).includes(l.lineNbr)) : newSelectedLines;
  };

  const getAddedProperties = (): Property[] => {
    return equipment ? newProperties.filter(p => !equipment.properties.map(x => x.name).includes(p.name)): newProperties.filter(p => p.value);
  };

  const getDeletedProperties = (): Property[] => {
    return equipment ? equipment.properties.filter(p => !newProperties.map(x => x.name).includes(p.name)): [];
  };

  const getModifiedProperties = (): Property[] => {
    return equipment ? newProperties.filter(p => {
      const originalProperty = equipment.properties.find(x => x.name === p.name);
      return !!originalProperty && originalProperty.value !== p.value;
    }): [];
  };

  const getPropertyOptions = (propertyName: string): string[] => {
    const dt = decorationTechnologies.find(d => d.name === newDecorationTechnology);
    const property = dt?.optionalProperties.find(p => p.name === propertyName) || dt?.requiredProperties.find(p => p.name === propertyName);
    return property?.possibleValues ?? [];
  };

  const equipmentLineSelectionChanged = (selection: SelectOption[]) => {
    if (selection) {
      setNewSelectedLines(
        selection.map(line => {
          return {lineNbr: +line.value} as Line;
        }));
    } else {
      setNewSelectedLines([]);
    }
  };

  const getRequiredProperties = (equipmentDecorationTechnology: string): PropertyType[] => {
    const decorationTechnology = decorationTechnologies.find(d => d.name.toLowerCase() === equipmentDecorationTechnology.toLowerCase());
    return decorationTechnology ? decorationTechnology.requiredProperties : [];
  };

  const getOptionalProperties = (equipmentDecorationTechnology: string): PropertyType[] => {
    const decorationTechnology = decorationTechnologies.find(d => d.name.toLowerCase() === equipmentDecorationTechnology.toLowerCase());
    return decorationTechnology ? decorationTechnology.optionalProperties : [];
  };

  const isRequiredProperty = (propertyName: string): boolean => {
    return getRequiredProperties(newDecorationTechnology).some(p => p.name === propertyName);
  };

  const areRequiredPropertiesSet = (): boolean => {
    const requiredProperties = getRequiredProperties(newDecorationTechnology);
    return !!newDecorationTechnology && !newProperties.some(p => requiredProperties.some(rp => p.name === rp.name) && !p.value && p.type !== 'bool');
  };

  const isRequiredPropertyAndIsNotSet = (propertyName: string, propertyValue: string): boolean => {
    return isRequiredProperty(propertyName) && !propertyValue;
  };

  const deleteEquipment = () => {
    if (equipment) {
      setIsLoading(true);
      deleteResourceWithBody(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v2/Tenant/${tenant}/Equipment/${equipment.equipmentId}`, {
        changeReason
      }).then(() => {
        return refreshAll();
      }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Equipment Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const sanitizeUserInput = (equipmentName: string, properties: Property[]) => {
    setNewEquipmentName(equipmentName.trim());
    setNewProperties(properties.map(property => {
      return { ...property, value: property.value?.trim() ?? property.value };
    }));
  };

  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox>
      }
      <Card header={
        <div className="row">
          <div className="col-sm-6">
            {!isEditing && equipment &&
            <Tooltip contents={<>
              <div>
                <h6>Equipment Number: {equipment.equipmentNbr} <Copy variant="button"
                  value={equipment.equipmentNbr.toString()}><FontAwesomeIcon icon="copy" /> Copy</Copy></h6>
                <h6>Equipment ID: {equipment.equipmentId} <Copy variant="button" value={equipment.equipmentId}>
                  <FontAwesomeIcon icon="copy" /> Copy</Copy></h6>
              </div>
            </>} tooltipInnerStyle={{maxWidth: '50vw'}}>
              <h4>{newEquipmentName} <FontAwesomeIcon icon="info-circle"/></h4>
              {_.startCase(equipment.decorationTechnology)}
            </Tooltip>}
            {isEditing &&
              <TextField
                name="simple"
                status={!newEquipmentName ? 'error' : newEquipmentName !== equipment?.name ? 'success' : undefined}
                value={newEquipmentName}
                onChange={e => setNewEquipmentName(e.target.value)}
              />
            }
            {/* we only allow changing the decoration technology if you're creating a new equipment or if it is currently generic */}
            {(isCreating() || (isEditing && equipment?.decorationTechnology === 'generic')) &&
              <Select
                label="Select Decoration Technology"
                status={!newDecorationTechnology ? 'error' : newDecorationTechnology !== equipment?.decorationTechnology ? 'success' : undefined}
                value={selectedDecorationTechnology}
                options={decorationTechnologies.map(dt => {
                  return {
                    label: _.startCase(dt.name),
                    value: dt.name
                  };
                })}
                onChange={(value) => {
                    setSelectedDecorationTechnology(value);
                    setNewDecorationTechnology(value?.value ?? '');
                }}
              />
            }
          </div>
          <div className="col-sm-6">
            <FlexBox className="button-group" justifyContent="flex-end">
              {!isEditing &&
              <>
                <Button className={button} variant="primary" size="sm" onClick={() => setIsEditing(true)}>
                  <FontAwesomeIcon icon="edit" /> Edit Equipment
                </Button>
                <Button className={buttonDanger} size="sm" onClick={() => setIsDeleting(true)}>
                  <FontAwesomeIcon icon="trash" /> Delete Equipment
                  <Modal
                    status="info"
                    show={isDeleting}
                    title="Delete Equipment"
                    footer={(
                      <div className="button-group">
                        <Button onClick={() => {
                          setIsDeleting(false);
                          setChangeReason('');
                        }} className={buttonWarning}>
                          Cancel
                        </Button>
                        <Button onClick={() => deleteEquipment()} disabled={!changeReason}
                                className={buttonDanger}>
                          Delete
                        </Button>
                      </div>
                    )}
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <TextField onChange={e => setChangeReason(e.target.value)} value={changeReason}
                                   helpText="Change Reason"/>
                      </div>
                    </div>
                    {isLoading &&
                    <FlexBox justifyContent="center">
                      <Spinner size="medium"/>
                    </FlexBox>
                    }
                  </Modal>
                </Button>
              </>
              }
              {isEditing &&
              <>
                <div>
                <Button variant="primary" size="sm"
                        className={buttonSuccess}
                        disabled={!newEquipmentName || !equipmentChangesExist() || !areRequiredPropertiesSet()}
                        onClick={() => {
                          sanitizeUserInput(newEquipmentName, newProperties);
                          setIsSaving(true);
                        }}>
                  <FontAwesomeIcon icon="save" /> {isCreating() && 'Save New Equipment' || 'Save Changes'}
                </Button>
                </div>
                <Button variant="primary" size="sm"
                        className={buttonWarning}
                        onClick={() => {
                          if (isCreating() && finishedAdding) {
                            finishedAdding();
                          } else {
                            setIsEditing(false);
                            initializeStateVariables();
                          }
                        }}>
                  <FontAwesomeIcon icon="times"/> {isCreating() && 'Discard New Equipment' || 'Cancel Changes' }
                </Button>
              </>
              }
              {!isCreating() &&
                <Button className={button} variant="primary" size="sm" disabled={!equipment}
                        onClick={() => setOpenHistory(true)}>
                  <FontAwesomeIcon icon="history"/> Equipment History
                </Button>
              }
            </FlexBox>
          </div>
        </div>}>

        {<Select
          isClearable
          isSearchable
          isDisabled={!isEditing}
          status={!_.isEqual(_.sortBy(newSelectedLines), _.sortBy(equipment?.lines ?? [])) ? 'success' : undefined}
          isMulti={true as any}
          onChange={(value) => equipmentLineSelectionChanged(value as any)}
          label="Associated lines"
          options={lines.map(l => {
            return {
              label: l.lineName,
              value: l.lineNbr.toString()
            };
          })}
          value={newSelectedLines.map(l => lines.find(li => li.lineNbr === l.lineNbr) || {
            lineNbr: l.lineNbr,
            lineName: 'Deleted Line'
          }).map(l => {
            return {
              label: l.lineName,
              value: l.lineNbr
            };
          }) as any}
        />}
        {
          <div>
            <table className="table table-bordered table-hover">
              <thead>
              <tr>
                <th className="w-25">Property Name</th>
                <th className="w-75">Value</th>
              </tr>
              </thead>
              {isEditing &&
              <tbody>
              {newProperties.map(property =>
                <tr key={property.propertyId}>
                  <td>
                    {property.name}
                  </td>
                  {property.type === 'string' &&
                  <td>
                    {getPropertyOptions(property.name).length > 0 ?
                      <Select
                        isClearable
                        isSearchable
                        status={isRequiredPropertyAndIsNotSet(property.name, property.value) ? 'error' : isChanged(property.name) ? 'success' : undefined}
                        onChange={(value) => setProperty(property.name, value?.value)}
                        options={getPropertyOptions(property.name).map(option => {
                          return {
                            label: option,
                            value: option
                          };
                        })}
                        value={{
                          label: property.value,
                          value: property.value
                        }}
                      />
                      : <TextField
                        status={isRequiredPropertyAndIsNotSet(property.name, property.value) ? 'error' : isChanged(property.name) ? 'success' : undefined}
                        value={property.value}
                        onChange={e => setProperty(property.name, e.target.value)}
                      />
                    }
                  </td>
                  }
                  {property.type === 'bool' &&
                  <td>
                    <Checkbox
                      checked={property.value === 'true'}
                      label={<></>}
                      onChange={e => setProperty(property.name, e.target.checked.toString())}
                    />
                  </td>
                  }
                  {property.type === 'long' || property.type === 'int' &&
                  <td>
                    <TextField
                      type="number"
                      status={isRequiredPropertyAndIsNotSet(property.name, property.value) ? 'error' : isChanged(property.name) ? 'success' : undefined}
                      value={property.value}
                      onChange={e => {
                        if (Number.isInteger(+e.target.value)) {
                          setProperty(property.name, e.target.value);
                        }
                      }}
                    />
                  </td>
                  }
                  {property.type === 'double' || property.type === 'float' &&
                  <td>
                    <TextField
                      type="number"
                      status={isRequiredPropertyAndIsNotSet(property.name, property.value) ? 'error' : isChanged(property.name) ? 'success' : undefined}
                      value={property.value}
                      onChange={e => setProperty(property.name, e.target.value)}
                    />
                  </td>
                  }
                </tr>
              )}
              </tbody>
              }
              {!isEditing &&
              <tbody>
              {newProperties.map(property =>
                <tr key={property.propertyId}>
                  <td>
                    {property.name}
                  </td>
                  <td>
                    {property.value}
                  </td>
                </tr>
              )}
              </tbody>
              }
            </table>
          </div>
        }
      </Card>
      <br/>
      <Modal
        status="info"
        show={isSaving}
        title="Save Equipment Changes"
        footer={(
          <div className="button-group">
            <Button onClick={() => {
              setIsSaving(false);
              setChangeReason('');
            }} className={buttonWarning}>
              Cancel
            </Button>
            <Button onClick={() => saveEquipmentChanges()} disabled={!changeReason}
                    className={buttonSuccess}>
              Save
            </Button>
          </div>
        )}
      >
        <div className="row">
          <div className="col-sm-12">
            <TextField onChange={e => setChangeReason(e.target.value)} value={changeReason}
                       helpText="Change Reason"/>
          </div>
        </div>
        {!equipment &&
        <Alert message={`Name set to ${newEquipmentName}`} status="success"
               dismissible={false}/>}
        {equipment && newEquipmentName !== equipment.name &&
        <Alert message={`Name changed from ${equipment.name} to ${newEquipmentName}`} status="info"
               dismissible={false}/>}
        {getAddedLines().map(line =>
          <Alert key={line.lineNbr} message={`Line ${line.lineNbr} - ${lines.find(l => l.lineNbr === line.lineNbr)?.lineName} added`} status="success"
               dismissible={false}/>)}
        {getDeletedLines().map(line =>
          <Alert key={line.lineNbr} message={`Line ${line.lineNbr} - ${lines.find(l => l.lineNbr === line.lineNbr)?.lineName} removed`} status="danger"
                 dismissible={false}/>)}
        {getAddedProperties().map(prop =>
          <Alert key={prop.name} message={`Property ${prop.name} added with value "${prop.value}"`} status="success"
                 dismissible={false}/>)}
        {getDeletedProperties().map(prop =>
          <Alert key={prop.name} message={`Property ${prop.name} deleted with old value "${prop.value}"`} status="danger"
                 dismissible={false}/>)}
        {getModifiedProperties().map(prop =>
          <Alert key={prop.name} message={`Property ${prop.name} changed to value "${prop.value}"`} status="warning"
                 dismissible={false}/>)}
        {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size="medium"/>
        </FlexBox>
        }
      </Modal>
      {equipment && <Modal
        status="info"
        show={openHistory}
        onRequestHide={() => setOpenHistory(false)}
        title="Equipment History"
        closeButton={true}
        size={'lg'}
      >
        <EquipmentHistory equipment={equipment} lines={lines} tenant={tenant} isActive={openHistory} showAlert={showAlert}/>
      </Modal>}
    </>
  );
}

export default EquipmentCard;
