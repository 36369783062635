import React, {ReactElement, useState} from 'react';
import {
  Button,
  Select,
} from '@cimpress/react-components';
import {ModalAlert} from '../types/modal-alert';
import {DisplayUnit, LabelSheetLayout} from '../types/label-sheet-layout';
import LabelSheetLayoutCard from './LabelSheetLayoutCard';
import _ from 'lodash';
import {SelectOption} from '../types/select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LineLabelConfig} from '../types/line-label-config';
import {button} from '../types/button-styles';

function LabelSheetLayoutSetup({tenant, filter, sortAscending, headerLocations, labelSheetLayouts, lineLabelConfigs, refresh, showAlert}:
  { tenant: string, filter: string | undefined, sortAscending: boolean, headerLocations: string[],
    labelSheetLayouts: LabelSheetLayout[], lineLabelConfigs: LineLabelConfig[], refresh: () => void, showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [selectedUnits, setSelectedUnits] = useState(DisplayUnit[DisplayUnit.inch]);

  const getLineLabelConfigNamesForLayout = (labelSheetLayout: LabelSheetLayout): string[] => {
    return lineLabelConfigs.filter(l => l.layoutId === labelSheetLayout.id).map(l => l.name);
  };

  return (
    <>
      <Select isClearable={false} label="Select Units"
              value={{label: _.startCase(selectedUnits), value: selectedUnits} as SelectOption}
              options={Object.keys(DisplayUnit).filter(u => isNaN(Number(u))).map(u => {return {label: _.startCase(u), value: u};})}
              onChange={(e: any) => setSelectedUnits(e.value)} />
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button className={button} onClick={() => setIsAdding(true)}>
          <FontAwesomeIcon icon="plus" /> Add Label Sheet Layout
        </Button>
      </div>

      <br/>

      {isAdding &&
        <>
          <LabelSheetLayoutCard tenant={tenant}
                                headerLocations={headerLocations}
                                isAdding={true}
                                refresh={refresh}
                                selectedUnits={selectedUnits}
                                showAlert={showAlert}
                                finishAdding={() => setIsAdding(false)} />
          <br/>
        </>}

      {_.orderBy(labelSheetLayouts.filter(labelSheetLayout =>
        !filter || labelSheetLayout.name.toLowerCase().includes(filter.toLowerCase())), l => l.name, [sortAscending ? 'asc' : 'desc'])
        .map((labelSheetLayout: LabelSheetLayout) => {
          return <React.Fragment key={labelSheetLayout.name}>
            <LabelSheetLayoutCard tenant={tenant}
                                  originalLabelSheetLayout={labelSheetLayout}
                                  lineLabelConfigNamesForLayout={getLineLabelConfigNamesForLayout(labelSheetLayout)}
                                  headerLocations={headerLocations}
                                  isAdding={false}
                                  refresh={refresh}
                                  selectedUnits={selectedUnits}
                                  showAlert={showAlert} />
            <br/>
          </React.Fragment>;
        })
      }
    </>
  );
}

export default LabelSheetLayoutSetup;
