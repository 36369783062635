import {Pipeline} from './pipeline';
import {TenantMetadata} from './tenant';

const viperPipelineTemplates: Pipeline[] = [
    {
      id: '',
      name: '(Blank)',
      preconditions: [],
      steps: [],
      enabled: false,
      tags: []
    },
    {
      id: '',
      name: 'Kornit',
      preconditions: [
        {
          conditionType: 'Property',
          propertyBagName: 'equipmentData',
          propertyName: 'garment.Exists',
          operatorType: 'isEqual',
          value: 'true'
        }
      ],
      steps: [
        {
          id: 'CopyFile',
          conditions: [
            {
              conditionType: 'FileType',
              operatorType: 'oneOf',
              value: JSON.stringify(['FrontImage', 'BackImage'])
            },
            {
              conditionType: 'Property',
              propertyBagName: 'gangProductionData',
              propertyName: 'IsLightTextile',
              operatorType: 'isEqual',
              value: 'true'
            }
          ],
          parameters: {
            'DestinationFolder': ''
          }
        },
        {
          id: 'CopyFile',
          conditions: [
            {
              conditionType: 'FileType',
              operatorType: 'oneOf',
              value: JSON.stringify(['FrontImage', 'BackImage'])
            },
            {
              conditionType: 'Property',
              propertyBagName: 'gangProductionData',
              propertyName: 'IsLightTextile',
              operatorType: 'isEqual',
              value: 'false'
            }
          ],
          parameters: {
            'DestinationFolder': ''
          }
        }
      ],
      enabled: false,
      tags: []
    },
    {
      id: '',
      name: 'Digital JMF',
      preconditions: [
        {
          conditionType: 'Property',
          propertyBagName: 'equipmentData',
          propertyName: 'digital.Exists',
          operatorType: 'isEqual',
          value: 'true'
        }
      ],
      steps: [
        {
          id: 'SubmitJmf',
          conditions: [
            {
              conditionType: 'FileType',
              operatorType: 'oneOf',
              value: JSON.stringify(['Basic', 'Jdf'])
            }
          ],
          parameters: {
            'JmfUrl': '$PIPELINE_VARIABLE:equipmentData:digital.JMFPortalUrl'
          }
        }
      ],
      enabled: false,
      tags: []
    },
    {
      id: '',
      name: 'Embroidery',
      preconditions: [
        {
          conditionType: 'Property',
          propertyBagName: 'equipmentData',
          propertyName: 'embroidery.Exists',
          operatorType: 'isEqual',
          value: 'true'
        }
      ],
      steps: [],
      enabled: false,
      tags: []
    }
];

const paperlessPipelineTemplates: Pipeline[] = [
  {
    id: '',
    name: '(Blank)',
    preconditions: [],
    steps: [],
    enabled: false,
    tags: []
  }
];

export const getPipelineTemplates = (tenantMetaData: TenantMetadata): Pipeline[] => {
  if (!tenantMetaData) {
    return [];
  }
  if (tenantMetaData.production_system_backend === 'viper') {
    return viperPipelineTemplates;
  } else if (tenantMetaData.production_system_backend === 'paperless') {
    return paperlessPipelineTemplates;
  } else {
    return [];
  }
};
