import React, {ReactElement} from 'react';
import * as _ from 'lodash';
import {Button, Select} from '@cimpress/react-components';
import {SelectOption} from '../types/select';
import {Condition} from '../types/pipeline';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {buttonDanger} from '../types/button-styles';

function FileTypeCondition({fileTypes, condition, updateStepCondition, removeSelf, disabled}:
  {
    fileTypes: string[],
    condition: Condition,
    updateStepCondition: (condition: Condition) => void,
    removeSelf: () => void,
    disabled: boolean
  }): ReactElement {
  const getFileTypes = (): any => {
    if (condition.value) {
      const values = JSON.parse(condition.value) as string[];
      return values.map(value => {
        return {
          label: value,
          value: value
        };
      });
    }
    return [];
  };

  const onFileTypeChange = (value: any): void => {
    const newCondition = _.cloneDeep(condition);
    newCondition.operatorType = 'oneOf';
    newCondition.value = JSON.stringify(value?.map((s: SelectOption) => s.value) ?? []);
    updateStepCondition(newCondition);
  };

  return (
    <div className="row">
      {!disabled && <div className="col-md-1">
        <Button className={buttonDanger} onClick={removeSelf}><FontAwesomeIcon icon="trash" /></Button>
      </div>}
      <div className={disabled ? 'col-md-12' : 'col-md-11'}>
        <Select
          isClearable
          isMulti={true as any}
          label="Select File Types"
          value={getFileTypes()}
          isDisabled={disabled}
          options={fileTypes.map(ft => {
            return {
              label: _.startCase(ft),
              value: ft
            };
          })}
          onChange={(value: any) => {
            onFileTypeChange(value);
          }}
        />
      </div>
    </div>
  );
}

export default FileTypeCondition;
