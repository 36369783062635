import React, {ReactElement, useEffect, useState} from 'react';
import {Button, Checkbox, Select, TextField} from '@cimpress/react-components';
import * as _ from 'lodash';
import {PropertyBag} from '../types/property-info';
import {Condition} from '../types/pipeline';
import {DataNode} from 'rc-tree/lib/interface';
import {TreeSelect} from 'antd';
import {SelectOption} from '../types/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {buttonDanger} from '../types/button-styles';

function PropertyCondition({
  condition,
  updateCondition,
  removeSelf,
  propertyBags,
  conditions,
  disabled
}: { condition: Condition, updateCondition: (condition: Condition) => void,
  removeSelf: () => void, propertyBags: PropertyBag[], conditions: Condition[], disabled: boolean }): ReactElement {
  const [treeData, setTreeData] = useState([] as DataNode[]);

  useEffect(() => {
    setTreeData(propertyBags.map(pb => {
      return {
        title: pb.propertyBagName,
        value: pb.propertyBagName,
        selectable: false,
        children: pb.properties.map(p => {
          return {
            title: p.propertyName,
            value: `${pb.propertyBagName}|${p.propertyName}`,
            disabled: !!conditions.find(c => c.propertyBagName === pb.propertyBagName && c.propertyName == p.propertyName)
          };
        })
      } as any;
    }));
  }, [propertyBags, conditions]);

  const updatePropertyName = (value: string | undefined): void => {
    const newName = getPropertyNameFromTreeData(value);
    const newBag = getPropertyBagNameFromTreeData(value);
    const newCondition = _.cloneDeep(condition);
    newCondition.propertyName = newName;
    newCondition.propertyBagName = newBag;
    newCondition.value = undefined;
    updateCondition(newCondition);
  };

  const getPropertyBagNameFromTreeData = (value: string | undefined): string | undefined => {
    return value ? value.split('|')[0] : undefined;
  };

  const getPropertyNameFromTreeData = (value: string | undefined): string | undefined => {
    return value ? value.split('|')[1] : undefined;
  };

  const getPossiblePropertyValues = (): SelectOption[] => {
    const properties = propertyBags.find(p => p.propertyBagName === condition.propertyBagName)?.properties;
    if (properties) {
      const propertyValues = properties.find(p => p.propertyName === condition.propertyName)?.displayValues;
      if (propertyValues) {
        return propertyValues.map(property => {
          return {
            label: property.display,
            value: property.value
          };
        });
      }
    }
    return [];
  };

  const isBooleanPropertyType = (): boolean => {
    const type = propertyBags.find(p => p.propertyBagName === condition.propertyBagName)?.properties
      .find(p => p.propertyName === condition.propertyName)?.type;
    return type === 'boolean' || type === 'bool';
  };

  const isStringPropertyType = (): boolean => {
    return propertyBags.find(p => p.propertyBagName === condition.propertyBagName)?.properties
      .find(p => p.propertyName === condition.propertyName)?.type === 'string';
  };

  const getCurrentPropertyValue = (): any => {
    if (condition.value) {
      const values = JSON.parse(condition.value) as string[];
      return values.map(value => {
        const displayValue = propertyBags.find(pb => pb.propertyBagName === condition.propertyBagName)?.properties
          .find(pb => pb.propertyName === condition.propertyName)?.displayValues?.find(dv => dv.value === value);
        return {
          label: displayValue?.display ?? value,
          value: value
        };
      });
    }
    return [];
  };

  return (
    <>
      <div className="row">
      {!disabled && <div className="col-md-1">
          <Button className={buttonDanger} onClick={removeSelf} disabled={disabled}><FontAwesomeIcon icon="trash" /></Button>
        </div>}
        <div className={disabled ? 'col-md-4' : 'col-md-3'}>
          <TreeSelect
            treeData={treeData}
            size="large"
            value={condition.propertyBagName && condition.propertyName ? `${condition.propertyBagName}|${condition.propertyName}` : undefined}
            placeholder="Select a Property"
            style={{width: '100%'}}
            showSearch={true}
            treeDefaultExpandAll={!!condition.propertyName}
            allowClear={true}
            onChange={updatePropertyName}
            disabled={disabled}
          />
        </div>
        <div className="col-md-8">
          {isBooleanPropertyType() ?
            <Checkbox
              label={condition.value ? condition.value : 'click to set to true or false'}
              checked={condition.value === 'true'}
              disabled={disabled}
              onChange={(e) => {
                const newCondition = _.cloneDeep(condition);
                newCondition.operatorType = 'isEqual';
                newCondition.value = e.target.checked.toString();
                updateCondition(newCondition);
              }}
              indeterminate={condition.value === undefined}/> :
            isStringPropertyType() ?
              <TextField placeholder="Value"
                         disabled={disabled}
                         onChange={e => {
                const newCondition = _.cloneDeep(condition);
                newCondition.operatorType = 'isEqual';
                newCondition.value = e.target.value;
                updateCondition(newCondition);
              }} value={condition.value}
              /> :
            <Select
              isClearable
              label="Select Value"
              value={getCurrentPropertyValue()}
              options={getPossiblePropertyValues()}
              isDisabled={disabled}
              onChange={(value) => {
                const newCondition = _.cloneDeep(condition);
                newCondition.operatorType = 'oneOf';
                if (value === null) {
                  newCondition.value = undefined;
                } else {
                  newCondition.value = JSON.stringify((value as any).map((v: any) => v.value));
                }
                updateCondition(newCondition);
              }}
              isMulti={true as any}
            />}
        </div>
      </div>
    </>
  );
}

export default PropertyCondition;
