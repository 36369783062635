import React, { ReactElement, useEffect, useState } from 'react';
import { SettingHistoryEntry } from '../types/settings-types';
import { get } from '../clients/AuthClient';
import { ModalAlert } from '../types/modal-alert';
import { FlexBox, Table, Spinner } from '@cimpress/react-components';

function SettingHistory({ setting, tenant, isActive, showAlert }: { setting?: string, tenant?: string, isActive: boolean, showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [settingHistory, setSettingHistory] = useState([] as SettingHistoryEntry[]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isActive) {
      setIsLoading(true);
      get<SettingHistoryEntry[]>(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/settings/${tenant}/${setting}/history`)
        .then(historyResponse => {
          setSettingHistory(historyResponse);
        }).catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an Error from the Settings Service',
            type: 'danger'
          });
        }).finally(() => {
          setIsLoading(false);
        });
      //do some stuff
    }
  }, [isActive]);

  const historyColumns = [
    { Header: 'Date', accessor: 'date', sortable: true },
    { Header: 'Action Taken', accessor: 'actionTaken' },
    { Header: 'Changed By', accessor: 'changedBy' },
    { Header: 'Old Value', accessor: 'oldValue' },
    { Header: 'New Value', accessor: 'newValue' },
    { Header: 'Tags', accessor: 'tags' },
    { Header: 'Change Reason', accessor: 'changeReason' },
  ];

  return (
    <>
      {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size='medium' />
        </FlexBox>
      }
      <h3>
        {setting}
      </h3>
      <div className="row">
        <div className="col-12">
          <Table
            columns={historyColumns}
            data={settingHistory.map(h => ({
              date: h.timestamp,
              actionTaken: h.action.replace('Setting', ''),
              changedBy: h.changedBy.replace('adfs|', ''),
              oldValue: h.oldValue,
              newValue: h.newValue,
              tags: h.tags?.join(', ') ?? '',
              changeReason: h.changeReason
            }))}
            noDataText={'No history data for this Setting'}
          />
        </div>
      </div>
    </>
  );
}

export default SettingHistory;
