import {
  Button,
  Card,
  FlexBox,
  TextField,
  Spinner,
} from '@cimpress/react-components';
import React, {ReactElement, useState} from 'react';
import {
  postWithResponse
} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {button} from '../types/button-styles';

function VLRAFilenameTester({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [vlraFilename, setVlraFilename] = useState('');

  const testVLRAFilename = () => {
    setIsLoading(true);
    postWithResponse<any, string>(`${process.env.REACT_APP_VLRA_SERVICE_URL}/v1/testFileName`, {
      filename: vlraFilename
    })
      .then(response => {
        showAlert({
          message: <div>{response}</div>,
          title: 'Response From VLRA',
          type: 'info'
        });
      }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the VLRA',
        type: 'danger'
      });
    }).finally(() => {
      setVlraFilename('');
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox>
      }
      <Card header="Test Viper Log Reader Adapter Filename">
        <div className="col-md-12">
          <div>
            <TextField placeholder="Filename" onChange={c => setVlraFilename(c.target.value)}
                       value={vlraFilename}
                       rightAddon={<Button className={button} onClick={() => testVLRAFilename()}> <FontAwesomeIcon icon="file" /> Test Filename</Button>}
            />
          </div>
        </div>
      </Card>
    </>
  );
}

export default VLRAFilenameTester;
