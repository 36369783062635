import {NewPipelineTag, PipelineTag} from '../types/pipeline';
import React, {ReactElement, useEffect, useState} from 'react';
import * as _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, FlexBox, InlineEdit, Modal, TextField} from '@cimpress/react-components';
import {CompactPicker} from 'react-color';
import {deleteResource, patch, post} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import {button, buttonDanger, buttonSuccess, buttonWarning} from '../types/button-styles';

function ManagePipelineTags({
  tags,
  prettyTenant,
  refreshPipelines,
  stopManagingTags,
  setIsLoading,
  tenant,
  showAlert
}:
  {
    tags: PipelineTag[], prettyTenant: string | undefined, refreshPipelines: () => void, stopManagingTags: () => void,
    setIsLoading: (isLoading: boolean) => void, tenant: string | undefined, showAlert: (alert: ModalAlert) => void
  }): ReactElement {

  const [pipelineTags, setPipelineTags] = useState([] as PipelineTag[]);
  const [isAddingNewTag, setIsAddingNewTag] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [newTagColor, setNewTagColor] = useState('');
  const [tagToDelete, setNewTagToDelete] = useState(null as PipelineTag | null);

  useEffect(() => {
    setPipelineTags(_.cloneDeep(tags ?? []));
  }, [tags]);

  const addTag = (newTag: NewPipelineTag) => {
    setIsLoading(true);
    post<NewPipelineTag>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines/tags`, newTag)
      .then(() => {
        setNewTagName('');
        setNewTagColor('');
        setIsAddingNewTag(false);
        return refreshPipelines();
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Pipelines Service',
          type: 'danger'
        });
      }).finally(() => {
      setIsLoading(false);
    });
  };

  const editTag = (id: string, editedTag: NewPipelineTag) => {
    setIsLoading(true);
    patch<NewPipelineTag>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines/tags/${id}`, editedTag)
      .then(() => {
        return refreshPipelines();
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Pipelines Service',
          type: 'danger'
        });
      }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteTag = (tag: PipelineTag | null) => {
    if(!tag) {
      return;
    }
    setIsLoading(true);
    deleteResource(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines/tags/${tag.id}`)
      .then(() => {
        return refreshPipelines();
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Pipelines Service',
          type: 'danger'
        });
      }).finally(() => {
      setIsLoading(false);
      setNewTagToDelete(null);
    });
  };

  const closeTagsView = (): void => {
    stopManagingTags();
    refreshPipelines();
  };

  const handleChangeComplete = (tagId: string, color: any) => {
    const newPipelineTags = [...pipelineTags];
    const changedPipeline = newPipelineTags.find(wt => wt.id == tagId);
    if (changedPipeline) {
      changedPipeline.hexColor = color.hex;
      editTag(tagId, {name: changedPipeline.name, hexColor: changedPipeline.hexColor});
    }
    setPipelineTags(newPipelineTags);
  };

  const saveTagName = ({value, name}: { value?: string, name?: string }) => {
    const changedPipeline = pipelineTags.find(wt => wt.id == name);
    if (!changedPipeline || !value || !name) {
      return;
    }
    editTag(name, {name: value, hexColor: changedPipeline.hexColor});
  };

  return (
    <div className="container">
      <Card header={
        <FlexBox>
          <FlexBox className="button-group">
            <Button variant="secondary" className={button} size="sm"
                    onClick={closeTagsView}>
              <FontAwesomeIcon icon="backward"/> Back
            </Button>
            <h6 style={{marginTop: '0.5rem'}}>Manage Tags for {prettyTenant}</h6>
          </FlexBox>
          <FlexBox justifyContent="flex-end">
            <Button variant="primary" className={button} size="sm"
                    onClick={() => setIsAddingNewTag(true)}>
              <FontAwesomeIcon icon="plus"/> Add Tag
            </Button>
          </FlexBox>
        </FlexBox>
      }>
        {pipelineTags.map(t => {
          return <React.Fragment key={t.id}><Card>
            <div className="row">
              <div className="col-md-10">
                <InlineEdit
                  name={t.id}
                  value={t.name}
                  size="h4"
                  onSave={saveTagName}
                />
              </div>
              <div className="col-md-2">
                <FlexBox justifyContent="flex-end">
                  <Button variant="primary" className={buttonDanger} size="sm"
                          onClick={() => setNewTagToDelete(t)}>
                    <FontAwesomeIcon icon="trash"/> Delete Tag
                  </Button>
                </FlexBox>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 color-picker">
                <CompactPicker color={t.hexColor}
                               onChangeComplete={(color: any) => handleChangeComplete(t.id, color)}/>
              </div>
            </div>
          </Card><br/></React.Fragment>;
        })}
      </Card>
      <Modal
        status={'danger'}
        show={!!tagToDelete}
        onRequestHide={() => setNewTagToDelete(null)}
        title={'Confirm delete'}
        closeButton={true}
        footer={(
          <Button className={buttonDanger} onClick={() => deleteTag(tagToDelete)}>
            Delete
          </Button>
        )}
      >
        Confirm deletion of tag: <b>{tagToDelete?.name}</b>
      </Modal>
      <Modal
        status="info"
        show={isAddingNewTag}
        title="Create New Tag"
        footer={(
          <>
            <Button onClick={() => setIsAddingNewTag(false)} className={buttonWarning}>
              Cancel
            </Button>
            <Button onClick={() => addTag({name: newTagName, hexColor: newTagColor})}
                    className={buttonSuccess}>
              Save
            </Button>
          </>
        )}
      >
        <>
          <TextField
            name="simple"
            label="New Tag Name"
            value={newTagName}
            onChange={e => setNewTagName(e.target.value)}
            autoFocus={true}
          />
          <CompactPicker color={newTagColor}
                         onChangeComplete={(color: any) => setNewTagColor(color.hex)}/>
        </>
      </Modal>
    </div>
  );
}

export default ManagePipelineTags;
