export interface PipelineTag {
  id: string,
  name: string,
  tenant: string,
  hexColor: string
}

export interface NewPipelineTag {
  name: string,
  hexColor: string
}

export interface PipelineStep {
  id: 'CopyFile' | 'MoveFile' | 'SubmitJmf' | null,
  conditions: Condition[],
  parameters: Record<string, string>
}

export interface Condition {
  conditionType: 'Property' | 'Filename' | 'FileType',
  operatorType?: string,
  value?: string,
  propertyBagName?: string,
  propertyName?: string
}

export interface Pipeline {
  id: string,
  name: string,
  enabled: boolean,
  preconditions: Condition[],
  steps: PipelineStep[],
  tags: string[]
}

export interface NewPipeline {
  name: string,
  enabled: boolean,
  preconditions: Condition[],
  steps: PipelineStep[],
  tags: string[]
}

export interface StepDefinition {
  id: string;
  parameters: StepParameter[];
}

export interface StepParameter {
  name: string;
  supportsLoadBalancing: boolean;
}

export interface InputFile {
  name: string;
  location: string;
  type: string;
}

export interface StepToTake {
  id: string;
  parameters: Record<string, string>;
  inputFiles: InputFile[];
}

export interface MatchingPipeline {
  id: string,
  name: string,
  stepsToTake: StepToTake[]
}

export interface JobContainer {
  jobId: string;
  entityReference: string;
  inputFiles: InputFile[];
  propertyBags: Record<string, Record<string, string>>;
  callbackUrl?: string;
}

export enum PipelineEditMode {
  readOnly,
  edit,
  add
}
