import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {Pipeline} from '../types/pipeline';
import {PropertyBag} from '../types/property-info';
import {TenantPropertyBags} from '../routes/PipelineManager';
import _ from 'lodash';
import FilterableTable, {SelectColumnFilter} from './FilterableTable';
import {Column, FilterValue, Row} from 'react-table';
import {Button, Card, FlexBox, TextField, Spinner} from '@cimpress/react-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TenantDisplayMetadata} from './TenantSelector';

type PropertyUsage = {
  propertyBagName: string;
  propertyName: string;
  value: string;
  pipeline: PipelineWithTenant;
}

type FileNameUsage = {
  operator: string;
  value: string;
  pipeline: PipelineWithTenant;
};

type FileTypeUsage = {
  value: string;
  pipeline: PipelineWithTenant;
}

type PipelineWithTenant = { tenant: string, prettyTenant: string } & Pipeline;

function PipelineCell({row}: { row: Row<{ pipeline: PipelineWithTenant }> }) {
  return <a
    href={`/pipelineManager?tenant=${row.original.pipeline.tenant}&pipelineId=${row.original.pipeline.id}`}
    target="_blank" rel="noopener noreferrer">{row.original.pipeline.name}</a>;
}

function PipelinePropertyOverview({getPipelines, getPropertyBags, tenants, exitPropertyOverview}:
  {
    getPipelines: (tenant: string) => Promise<Pipeline[]>,
    getPropertyBags: (tenants: TenantDisplayMetadata[]) => Promise<TenantPropertyBags[]>,
    tenants: TenantDisplayMetadata[],
    exitPropertyOverview: () => void
  }): ReactElement {

  const [isLoading, setIsLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null as FilterValue);
  const [propertyUsage, setPropertyUsage] = useState([] as PropertyUsage[]);
  const [fileNameUsage, setFileNameUsage] = useState([] as FileNameUsage[]);
  const [fileTypeUsage, setFileTypeUsage] = useState([] as FileTypeUsage[]);

  const propertyColumns: Column<PropertyUsage>[] = useMemo(() => {
    const cols: Column<PropertyUsage>[] = [];
    cols.push({
      Header: 'Property Bag',
      accessor: p => p.propertyBagName,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Property',
      accessor: p => p.propertyName,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Value',
      accessor: p => p.value,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Tenant',
      accessor: p => p.pipeline.prettyTenant,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Pipeline',
      accessor: p => p.pipeline.name,
      Filter: SelectColumnFilter,
      Cell: PipelineCell
    });
    return cols;
  }, []);

  const fileNameColumns: Column<FileNameUsage>[] = useMemo(() => {
    const cols: Column<FileNameUsage>[] = [];
    cols.push({
      Header: 'Operator',
      accessor: f => f.operator,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Value',
      accessor: f => f.value,
      filter: 'text'
    });
    cols.push({
      Header: 'Tenant',
      accessor: p => p.pipeline.prettyTenant,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Pipeline',
      accessor: p => p.pipeline.name,
      Filter: SelectColumnFilter,
      Cell: PipelineCell
    });
    return cols;
  }, []);

  const fileTypeColumns: Column<FileTypeUsage>[] = useMemo(() => {
    const cols: Column<FileTypeUsage>[] = [];
    cols.push({
      Header: 'Type',
      accessor: f => f.value,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Tenant',
      accessor: p => p.pipeline.prettyTenant,
      Filter: SelectColumnFilter
    });
    cols.push({
      Header: 'Pipeline',
      accessor: p => p.pipeline.name,
      Filter: SelectColumnFilter,
      Cell: PipelineCell
    });
    return cols;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const propertyBagsPromise = getPropertyBags(tenants);
    const pipelinesPromise = Promise.all(tenants.map(t => getPipelines(t.tenant)
      .then(pipelines => pipelines.map(pipeline => {
        return {...pipeline, ...t};
      }))));
    Promise.all([propertyBagsPromise, pipelinesPromise]).then(result => {
      const propertyBags = result[0];
      const pipelines = result[1];
      const allPipelines = pipelines.flat();

      const newPropertyUsage: PropertyUsage[] = [];
      const newFileNameUsage: FileNameUsage[] = [];
      const newFileTypeUsage: FileTypeUsage[] = [];

      for (const pipeline of allPipelines) {
        const tenantPropertyBags = propertyBags.find(p => p.tenant === pipeline.tenant);
        for (const precondition of pipeline.preconditions) {
          newPropertyUsage.push(...convertConditionToPropertyUsage(tenantPropertyBags, pipeline, precondition.propertyBagName, precondition.propertyName, precondition.value));
        }
        for (const step of pipeline.steps) {
          for (const paramName of Object.keys(step.parameters)) {
            const matches = Array.from(step.parameters[paramName].matchAll(/\${?PIPELINE_VARIABLE:([\w\-.]+):([\w\-.]+)}?/g));
            // we support multiple matches within a step parameter...
            for (const match of matches) {
              const propertyBagName = match.length === 3 ? match[1] : undefined;
              const propertyName = match.length === 3 ? match[2] : undefined;
              if (!propertyBagName || !propertyName) {
                continue;
              }
              // variable substitution will never have a display value so skipping...
              newPropertyUsage.push({
                propertyBagName,
                propertyName,
                pipeline,
                value: 'any - Variable Substitution'
              });
            }
          }

          for (const condition of step.conditions) {
            if (condition.conditionType === 'Filename') {
              if (!condition.operatorType || !condition.value) {
                continue;
              }
              newFileNameUsage.push({
                operator: condition.operatorType,
                value: condition.value,
                pipeline
              });
            } else if (condition.conditionType === 'FileType') {
              if (!condition.operatorType || !condition.value) {
                continue;
              }
              const value = JSON.parse(condition.value ?? '');
              const possibleValues = Array.isArray(value) ? value : [value.toString()];
              for (const possibleValue of possibleValues) {
                newFileTypeUsage.push({value: possibleValue, pipeline});
              }
            } else if (condition.conditionType === 'Property') {
              newPropertyUsage.push(...convertConditionToPropertyUsage(tenantPropertyBags, pipeline, condition.propertyBagName, condition.propertyName, condition.value));
            }
          }
        }
      }

      setPropertyUsage(_.orderBy(_.uniqWith(newPropertyUsage, (a, b) =>
          a.propertyBagName === b.propertyBagName && a.propertyName === b.propertyName
          && a.value === b.value && a.pipeline.id === b.pipeline.id),
        [p => p.propertyBagName, p => p.propertyName, p => p.value]));
      setFileNameUsage(_.orderBy(_.uniqWith(newFileNameUsage, (a, b) => a.operator === b.operator
        && a.value === b.value && a.pipeline.id === b.pipeline.id), [f => f.operator, f => f.value]));
      setFileTypeUsage(_.orderBy(_.uniqWith(newFileTypeUsage,
        (a, b) => a.value === b.value && a.pipeline.id === b.pipeline.id), [f => f.value]));
    }).finally(() => setIsLoading(false));
  }, [tenants]);

  const convertConditionToPropertyUsage = (propertyBags: TenantPropertyBags | undefined, pipeline: PipelineWithTenant, propertyBagName?: string, propertyName?: string, value?: string): PropertyUsage[] => {
    const result: PropertyUsage[] = [];

    if (!propertyBagName || !propertyName || !value) {
      return result;
    }
    const valueJson = JSON.parse(value ?? '');
    const possibleValues = Array.isArray(valueJson) ? valueJson : [valueJson.toString()];


    for (const possibleValue of possibleValues) {
      const value = getDisplayValue(propertyBags?.propertyBags, propertyBagName, propertyName, possibleValue)
        ?? possibleValue;
      result.push({value, pipeline, propertyBagName: propertyBagName, propertyName: propertyName});
    }
    return result;
  };

  const getDisplayValue = (propertyBags: PropertyBag[] | undefined, propertyBagName: string, propertyName: string, value: string): string | undefined => {
    return propertyBags?.find(pb => pb.propertyBagName === propertyBagName)?.properties.find(p => p.propertyName === propertyName)?.displayValues?.find(dv => dv.value === value)?.display;
  };

  return (
    <div className="container-fluid">
      <Card header={<>
        <Button onClick={() => exitPropertyOverview()} variant="secondary"> <FontAwesomeIcon
          icon="backward"/>
        </Button>
      </>}>
        {isLoading ?
          <FlexBox justifyContent="center">
            <Spinner size="medium"/>
          </FlexBox>
          : <>
            <TextField value={globalFilter} onChange={e => setGlobalFilter(e.target.value)} placeholder='Search' />
            <Card header="Property Usage">
              <FilterableTable columns={propertyColumns} data={propertyUsage} globalFilter={globalFilter} />
            </Card>
            <br/>
            <Card header="File Name Usage">
              <FilterableTable columns={fileNameColumns} data={fileNameUsage} globalFilter={globalFilter} />
            </Card>
            <br/>
            <Card header="File Type Usage">
              <FilterableTable columns={fileTypeColumns} data={fileTypeUsage} globalFilter={globalFilter} />
            </Card>
          </>
        }
      </Card>
    </div>
  );
}

export default PipelinePropertyOverview;
