import { Alert, Button, Card, Spinner } from '@cimpress/react-components';
import React, { ReactElement, useEffect, useState } from 'react';
import { get } from '../clients/AuthClient';
import { ModalAlert } from '../types/modal-alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export class AutoUpdateVersion {
  url: string | undefined;
  version: string | undefined;
}

function BarudanTrayApp({showAlert} : {showAlert: (alert: ModalAlert) => void}): ReactElement {
  const [loading, setLoading] = useState(true);
  const [downloadLink, setDownloadLink] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    document.title = 'Barudan Tray';
    get<AutoUpdateVersion>('https://updates.pressint.cimpress.io/updates?programName=MIPI.BarudanTray.exe')
      .then(data => {
        if (data.url) {
          setDownloadLink(data.url);
        }
        if (data.version) {
          setVersion(data.version);
        }
        setLoading(false);
      }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Unable to fetch latest version of tray app',
          type: 'danger'
        });
      });
  }, []); // useEffect with [] as dependencies is the equivalent of componentDidMount for function components

  return (
    <div className="container">
      <Card header="Download Link">
        {version && <div className="card-text">
          <div>
            <b>Latest Version:</b> {version}
          </div>
        </div>}
        <div className="col-md-12" style={{ margin: '5px' }}>
          {loading ? <Spinner size="medium" />
            : <Button variant="primary" blockLevel={true} href={downloadLink}>
              <FontAwesomeIcon icon="download" /> Download MIPI.BarudanTray.exe
            </Button>}
        </div>
      </Card>
      <br />
      <Card header="Barudan Tray Application">
        <h2>
          Installation Procedure
        </h2>
        <p>
          The Barudan Tray app was designed as a drop-in replacement for the existing Barudan communication service.
          It bypasses the netscalers for the embroidery presses and allows the presses to connect directly to the
          UIs that run them. This document describes how to deploy the Barudan Tray app in the embroidery cell.
        </p>
        <Alert
          status="info"
          message="As of version 1.1.0, the app will auto-update in the background."
          dismissible={false}
        />
        <h3>1. Download and Install the Tray app</h3>
        <p>
          The first step is to download the Barudan Tray app onto the PC that will be used to run the embroidery UI
          from
          the download link at the top of this page. This page hosts the Windows version of the executable. Other
          versions
          of the app are available for deployment (Linux, macOS). Please contact us if you would like to deploy to one
          of these other platforms and we can help you out.
        </p>
        <Alert
          status="danger"
          message="This app cannot be deployed to a network share. It contains auto-updating code that will not work on
              network drives. Please install this locally on the machine you wish to run it on."
          dismissible={false}
        />
        <p>
          You should end up with a desktop shortcut that looks like this:
        </p>
        <div className="text-center">
          <img src="./barudan-tray/app-icon.png" className="img-thumbnail" />
        </div>
        <p>
          Launching the .exe will present a window that looks like this:
        </p>
        <div className="text-center">
          <img src="./barudan-tray/disconnected.png" className="img-thumbnail"
            style={{ maxWidth: '75%' }} />
        </div>
        <h3>2. Configure the WebShell Station to Use the Tray App</h3>
        <p>
          The second step is to configure WebShell to use the tray app. To do this, navigate to the station level
              WebShell configuration for the station you’re configuring. Set the <code>useBarudanTray</code> setting to
              true.
              Re-load the embroidery screen.
        </p>
        <div className="text-center">
          <img src="./barudan-tray/webshell-admin.png"
            className="img-thumbnail" style={{ maxWidth: '100%' }} />
        </div>
        <p>
          Once the embroidery station is re-loaded, the tray app should look like this:
        </p>
        <div className="text-center">
          <img src="./barudan-tray/pc-connected.png" className="img-thumbnail"
            style={{ maxWidth: '75%' }} />
        </div>
        <h3>3. Configure the Embroidery Press to Point to Press PC</h3>
        <p>
          The third step is to configure the PC and the embroidery press to talk to each other. On the embroidery
          press,
          navigate to the network setting screen, and set the IP address and port to the IP and port shown at the
              bottom of the tray app display (<code>10.227.56.52</code> port <code>8001</code> in this example). Once
              that’s done, the tray app will report the press IP address and Automat ID.
        </p>
        <div className="text-center">
          <img src="./barudan-tray/connected.png" className="img-thumbnail"
            style={{ maxWidth: '75%' }} />
        </div>
        <Alert status="warning"
               message="You will want to make sure that your press PC has a DHCP reservation. If the PC IP changes, the press
          configuration will also need to change. To avoid this make sure the press PC is given a static
          IP through its DHCP lease."
               dismissible={false}/>
        <h2>Notes</h2>
        <p>
          The tray app automatically logs to Sumologic under the category
          <code className="dont-break-out">_sourceCategory=mis/pressintegration/prd/barudantray</code>. The hostname of the press PC is
              reported along with each log so that we can identify where problems might be happening.
        </p>
        <Alert
          status="info"
          message={<SlackLink />}
          dismissible={false}
        />
      </Card>
    </div>
  );
}

function SlackLink() {
  return (
    <>
      If you encounter issues during deployment, please escalate them to us in the <a href="https://cimpress.slack.com/app_redirect?channel=help-pressintegration">#help-pressintegration</a> Slack channel.
    </>
  );
}

export default BarudanTrayApp;
