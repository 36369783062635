import React, { ReactElement, useEffect, useState } from 'react';
import {
  Condition,
  NewPipeline,
  Pipeline,
  PipelineEditMode,
  PipelineStep,
  PipelineTag,
  StepDefinition,
  StepParameter
} from '../types/pipeline';
import { Alert, Button, Card, FlexBox, InlineEdit, Modal, Select } from '@cimpress/react-components';
import { patch, post } from '../clients/AuthClient';
import { PropertyBag } from '../types/property-info';
import { ModalAlert } from '../types/modal-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { SelectOption } from '../types/select';
import PropertyCondition from './PropertyCondition';
import EditablePipelineStep from './EditablePipelineStep';
import {button, buttonDanger, buttonSuccess, buttonWarning} from '../types/button-styles';

function EditPipeline({
  pipeline,
  pipelineTags,
  propertyBags,
  variablePropertyBags,
  stepDefinitions,
  fileTypes,
  tenant,
  showAlert,
  setIsLoading,
  stopEditing,
  refreshPipelines,
  editingMode
}: {
  pipeline: Pipeline,
  pipelineTags: PipelineTag[],
  propertyBags: PropertyBag[],
  variablePropertyBags: PropertyBag[],
  stepDefinitions: StepDefinition[],
  fileTypes: string[],
  tenant: string | undefined,
  showAlert: (alert: ModalAlert) => void, setIsLoading: (isLoading: boolean) => void,
  stopEditing: () => void, refreshPipelines: () => Promise<void>, editingMode: PipelineEditMode
}): ReactElement {
  const [editingPipeline, setEditingPipeline] = useState(_.cloneDeep(pipeline));
  const [discardingChangesModal, setDiscardingChangesModal] = useState(false);
  const [savingChangesModal, setSavingChangesModal] = useState(false);

  useEffect(() => {
    setEditingPipeline(_.cloneDeep(pipeline));
  }, [pipeline]);

  const addPipeline = () => {
    if (!editingPipeline) {
      return;
    }
    setIsLoading(true);
    post<NewPipeline>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines`, {
      name: editingPipeline.name,
      preconditions: editingPipeline.preconditions,
      steps: editingPipeline.steps,
      enabled: editingPipeline.enabled,
      tags: editingPipeline.tags
    }).then(() => {
      return refreshPipelines().then(() => stopEditing());
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Pipelines Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const savePipeline = () => {
    if (!editingPipeline) {
      return;
    }
    setIsLoading(true);
    patch<NewPipeline>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines/${editingPipeline.id}`, {
      name: editingPipeline.name,
      preconditions: editingPipeline.preconditions,
      steps: editingPipeline.steps,
      enabled: editingPipeline.enabled,
      tags: editingPipeline.tags
    })
      .then(() => {
        return refreshPipelines().then(() => stopEditing());
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Pipelines Service',
          type: 'danger'
        });
      }).finally(() => {
        setIsLoading(false);
      });

  };

  const updatePrecondition = (condition: Condition, index: number): void => {
    const newPipeline = _.cloneDeep(editingPipeline);
    if (newPipeline) {
      newPipeline.preconditions[index] = condition;
      setEditingPipeline(newPipeline);
    }
  };

  const removePrecondition = (index: number): void => {
    const newPipeline = _.cloneDeep(editingPipeline);
    if (newPipeline) {
      newPipeline.preconditions.splice(index, 1);
      setEditingPipeline(newPipeline);
    }
  };

  const addBlankPrecondition = (): void => {
    const newPipeline = _.cloneDeep(editingPipeline);
    if (newPipeline) {
      newPipeline.preconditions.push({
        conditionType: 'Property',
        operatorType: 'oneOf'
      });
      setEditingPipeline(newPipeline);
    }
  };

  const updateStepCondition = (stepIndex: number, conditionIndex: number, condition: Condition): void => {
    const newPipeline = _.cloneDeep(editingPipeline);
    if (newPipeline) {
      newPipeline.steps[stepIndex].conditions[conditionIndex] = condition;
      setEditingPipeline(newPipeline);
    }
  };

  const removeStepCondition = (stepIndex: number, conditionIndex: number): void => {
    const newPipeline = _.cloneDeep(editingPipeline);
    if (newPipeline) {
      newPipeline.steps[stepIndex].conditions.splice(conditionIndex, 1);
      setEditingPipeline(newPipeline);
    }
  };

  const getParameters = (step: PipelineStep): StepParameter[] => {
    const existingParameters = Array.from(Object.keys(step.parameters));
    const definedParameters = stepDefinitions.find(s => s.id == step.id)?.parameters ?? [];
    const undefinedParameters = existingParameters.filter(ep => !definedParameters.find(p => ep === p.name));
    return definedParameters.concat(undefinedParameters.map(p => ({
      name: p,
      supportsLoadBalancing: false,
    })));
  };

  const reorderStep = (stepIndex: number, directionMoved: number): void => {
    if (editingPipeline) {
      const newPipeline = _.cloneDeep(editingPipeline);
      const steps = newPipeline.steps;
      const currentStep = steps[stepIndex];
      steps[stepIndex] = steps[stepIndex + directionMoved];
      steps[stepIndex + directionMoved] = currentStep;
      setEditingPipeline(newPipeline);
    }
  };

  const removeSelf = (stepIndex: number): void => {
    if (editingPipeline) {
      const newPipeline = _.cloneDeep(editingPipeline);
      newPipeline.steps.splice(stepIndex, 1);
      setEditingPipeline(newPipeline);
    }
  };

  const addStepCondition = (stepIndex: number): void => {
    if (editingPipeline) {
      const newPipeline = _.cloneDeep(editingPipeline);
      newPipeline.steps[stepIndex].conditions.push({} as Condition);
      setEditingPipeline(newPipeline);
    }
  };

  const updateStepActionType = (stepIndex: number, newStepType: any): void => {
    if (editingPipeline) {
      const newPipeline = _.cloneDeep(editingPipeline);
      newPipeline.steps[stepIndex].id = newStepType;
      newPipeline.steps[stepIndex].parameters = {};
      setEditingPipeline(newPipeline);
    }
  };

  const updateStepActionValue = (stepIndex: number, paramName: string, newValue: string): void => {
    if (editingPipeline) {
      const newPipeline = _.cloneDeep(editingPipeline);
      newPipeline.steps[stepIndex].parameters[paramName] = newValue;
      setEditingPipeline(newPipeline);
    }
  };

  return (
    <>
      {editingPipeline &&
        <Card
          header={
            <>
              <FlexBox justifyContent="space-between">
                <FlexBox className="button-group" justifyContent="flex-start">
                  <Button onClick={() => stopEditing()} variant="secondary"> <FontAwesomeIcon
                    icon="backward" /></Button>
                  <InlineEdit onChange={(e: any) => {
                    const pipelineCopy = _.cloneDeep(editingPipeline);
                    pipelineCopy.name = e.target.value;
                    setEditingPipeline(pipelineCopy);
                  }} value={editingPipeline.name}
                    label="Pipeline Name"
                    size="h1"
                    placeholder="Enter a pipeline name"
                    disabled={editingMode == PipelineEditMode.readOnly} />
                </FlexBox>
                {editingMode != PipelineEditMode.readOnly && <FlexBox className="button-group" justifyContent="flex-end">
                  <Button onClick={() => setSavingChangesModal(true)}
                    disabled={!editingPipeline.name || (editingMode == PipelineEditMode.edit && _.isEqual(pipeline, editingPipeline))}
                    className={buttonSuccess} variant="primary">
                    {editingMode == PipelineEditMode.edit ? 'Save Changes' : 'Add Pipeline'}
                  </Button>
                  <Button onClick={() => setDiscardingChangesModal(true)}
                    disabled={editingMode == PipelineEditMode.edit && _.isEqual(pipeline, editingPipeline)}
                    className={buttonWarning} variant="primary">
                    {editingMode == PipelineEditMode.edit ? 'Discard Changes' : 'Discard Pipeline'}
                  </Button>
                </FlexBox>}
              </FlexBox>
              <br />
              <Select
                isClearable
                isMulti={true as any}
                label="Pipeline Tags"
                isDisabled={editingMode == PipelineEditMode.readOnly}
                value={editingPipeline.tags.map(t => {
                  const pipelineTag = pipelineTags.find(pt => pt.id === t);
                  return {
                    label: pipelineTag?.name ?? 'Unknown Tag',
                    value: t
                  };
                }) as any}
                options={pipelineTags.map(t => {
                  return {
                    label: t.name,
                    value: t.id
                  };
                })}
                onChange={(value: any) => {
                  const newPipeline = _.cloneDeep(editingPipeline);
                  newPipeline.tags = value?.map((s: SelectOption) => s.value) ?? [];
                  setEditingPipeline(newPipeline);
                }}
              />
            </>
          }>
          <Card header="If all of these conditions are met:">
            {editingPipeline.preconditions.map((c, i) => {
              return <>
                <PropertyCondition key={i}
                  condition={c}
                  updateCondition={(cond) => updatePrecondition(cond, i)}
                  removeSelf={() => removePrecondition(i)}
                  propertyBags={propertyBags}
                  conditions={editingPipeline.preconditions}
                  disabled={editingMode == PipelineEditMode.readOnly} />
                <hr />
              </>;
            })}
            {editingPipeline.preconditions.length === 0 &&
              <Alert
                message="This pipeline has no preconditions and will match all jobs. This is not a recommended configuration."
                status="danger" dismissible={false} />}
            {editingMode !== PipelineEditMode.readOnly && <React.Fragment><br /><Button onClick={addBlankPrecondition} variant="default" className={button}>
              <FontAwesomeIcon icon="plus" /> Add Precondition
            </Button>
            </React.Fragment>}
          </Card>
          <br />
          <Card header="Steps">
            {editingPipeline.steps.map((s, stepIndex) => {
              return <EditablePipelineStep key={stepIndex}
                s={s}
                propertyBags={propertyBags}
                variablePropertyBags={variablePropertyBags}
                stepDefinitions={stepDefinitions}
                fileTypes={fileTypes}
                hideReorderUp={stepIndex === 0}
                hideReorderDown={stepIndex === editingPipeline.steps.length - 1}
                headerName={`Step ${stepIndex + 1}`}
                reorderStep={(directionMoved: number) => reorderStep(stepIndex, directionMoved)}
                updateStepCondition={(conditionIndex: number, condition: Condition) => updateStepCondition(stepIndex, conditionIndex, condition)}
                removeStepCondition={(conditionIndex: number) => removeStepCondition(stepIndex, conditionIndex)}
                getParameters={(step: PipelineStep) => getParameters(step)}
                removeSelf={() => removeSelf(stepIndex)}
                addStepCondition={() => addStepCondition(stepIndex)}
                updateStepActionType={(newStepType: any) => updateStepActionType(stepIndex, newStepType)}
                updateStepActionValue={(paramName: string, newValue: string) => updateStepActionValue(stepIndex, paramName, newValue)}
                copySelf={() => {
                  const c = _.cloneDeep(s);
                  const newPipeline = _.cloneDeep(editingPipeline);
                  newPipeline.steps.splice(stepIndex + 1, 0, c);
                  setEditingPipeline(newPipeline);
                }}
                disabled={editingMode == PipelineEditMode.readOnly} />;
            })}
            {editingPipeline.steps.length === 0 &&
              <Alert
                message="This pipeline has no steps and will only mark the job container as completed."
                dismissible={false}
                status="warning" />}
            {editingMode !== PipelineEditMode.readOnly && <Button variant="primary" className={`col-md-12 ${button}`} onClick={() => {
              const newPipeline = _.cloneDeep(editingPipeline);
              newPipeline.steps.push({
                id: null,
                parameters: {},
                conditions: []
              } as PipelineStep);
              setEditingPipeline(newPipeline);
            }}><FontAwesomeIcon icon="plus" /> Add a Step</Button>}
          </Card>
          <br />
        </Card>
      }
      <Modal
        status="info"
        show={discardingChangesModal}
        title="Discard Pipeline Changes"
        footer={(
          <div className="button-group">
            <Button onClick={() => {
              setDiscardingChangesModal(false);
            }} className={buttonWarning}>
              Cancel
            </Button>
            <Button onClick={() => {
              setEditingPipeline(pipeline);
              setDiscardingChangesModal(false);
              if (editingMode !== PipelineEditMode.edit) {
                stopEditing();
              }
            }}
              className={buttonDanger}>
              Discard
            </Button>
          </div>
        )}
      >
        Are you sure you would like to discard all changes made to your pipeline?
      </Modal>
      <Modal
        status="info"
        show={savingChangesModal}
        title={editingMode === PipelineEditMode.edit ? 'Save Pipeline Changes' : 'Add Pipeline'}
        footer={(
          <div className="button-group">
            <Button onClick={() => {
              setSavingChangesModal(false);
            }} variant="default" className={buttonWarning}>
              Cancel
            </Button>
            <Button onClick={() => {
              editingMode === PipelineEditMode.edit ? savePipeline() : addPipeline();
              setSavingChangesModal(false);
            }}
              className={buttonSuccess}>
              Save
            </Button>
          </div>
        )}
      >
        Are you sure you would like to save all changes made to your pipeline?
      </Modal>
    </>
  );
}

export default EditPipeline;
