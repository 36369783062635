import { FlexBox, Card, Alert, Button, TextField, Select, Tooltip, Robot } from '@cimpress/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { ReactElement, useState } from 'react';
import { useEffect } from 'react';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { auth } from '../Auth';
import { deleteResource, get, patch, post } from '../clients/AuthClient';
import { DecorationTechnology, PropertyType } from '../types/equipment';
import { ModalAlert } from '../types/modal-alert';
import {button, buttonDanger, buttonSuccess, buttonWarning} from '../types/button-styles';

function PropertyEditor({ property, changeSelf, deleteSelf }:
  { property: PropertyType, changeSelf: (newProp: PropertyType) => void, deleteSelf: () => void }): ReactElement {
  const [prop, setProp] = useState(undefined as PropertyType | undefined);

  useEffect(() => {
    setProp(property);
  }, [property]);

  const getTags = (): Tag[] => {
    if (prop && prop.possibleValues) {
      return prop.possibleValues?.map(p => {
        return { id: p, name: p };
      });
    }
    return [];
  };

  return (<tr>
    <td><TextField label="Default Value" value={prop?.name} onChange={e => {
      if (prop) {
        const newProp = _.cloneDeep(prop);
        newProp.name = e.target.value;
        changeSelf(newProp);
      }
    }} type="text" /></td>
    <td><Select value={prop ? {
      label: prop?.type,
      value: prop?.type
    } : undefined} label="Type" className="min-w-10"
      options={[
        { label: 'float', value: 'float' },
        { label: 'double', value: 'double' },
        { label: 'int', value: 'int' },
        { label: 'long', value: 'long' },
        { label: 'bool', value: 'bool' },
        { label: 'string', value: 'string' },
      ]} onChange={value => {
        if (prop && value) {
          const newProp = _.cloneDeep(prop);
          newProp.type = value?.value;
          changeSelf(newProp);
        }
      }} /></td>
    <td><TextField label="Default" value={prop?.defaultValue} onChange={e => {
      if (prop) {
        const newProp = _.cloneDeep(prop);
        newProp.defaultValue = e.target.value;
        changeSelf(newProp);
      }
    }} type="text" /></td>
    <td><ReactTags autoresize={false} tags={getTags()} allowNew onAddition={tag => {
      if (prop) {
        const newProp = _.cloneDeep(prop);
        if (!newProp.possibleValues) {
          newProp.possibleValues = [];
        }
        newProp.possibleValues?.push(tag.name);
        changeSelf(newProp);
      }
    }} onDelete={tagIndex => {
      if (prop) {
        const newProp = _.cloneDeep(prop);
        newProp.possibleValues?.splice(tagIndex, 1);
        if (newProp.possibleValues?.length === 0) {
          newProp.possibleValues = null as any;
        }
        changeSelf(newProp);
      }
    }} placeholderText="Add new value" minQueryLength={1} /></td>
    <td><Button onClick={deleteSelf} className={buttonDanger}><FontAwesomeIcon icon="trash" /></Button></td>
  </tr>);
}

function PropertyTable({ properties, updateProperties }: {
  properties: PropertyType[],
  updateProperties: (properties: PropertyType[]) => void
}): ReactElement {
  const [props, setProperties] = useState([] as PropertyType[]);

  useEffect(() => {
    setProperties(properties);
  }, [properties]);

  return (<div className="table-responsive">
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          <th>Property Name</th>
          <th>Property Type</th>
          <th>Default Value</th>
          <th>Possible Values</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {props.map((prop, idx) => <>
          <PropertyEditor key={idx} property={prop} changeSelf={prop => {
            const p = _.cloneDeep(props);
            p[idx] = prop;
            updateProperties(p);
          }} deleteSelf={() => {
            const p = _.cloneDeep(props);
            p.splice(idx, 1);
            updateProperties(p);
          }} />
        </>)}
      </tbody>
    </table>
    <Button blockLevel onClick={() => {
      const p = _.cloneDeep(props);
      p.push({} as any);
      updateProperties(p);
    }}><FontAwesomeIcon icon="plus" /> Add Property</Button>
  </div>);
}

function DecorationTechnologyEditor({ decorationTechnology, updateSelf, deleteSelf }: { decorationTechnology: DecorationTechnology, updateSelf: (updatedValue: DecorationTechnology) => void, deleteSelf: () => void }): ReactElement {
  const [originalDt, setOriginalDt] = useState(undefined as DecorationTechnology | undefined);
  const [editedDt, setEditedDt] = useState(undefined as DecorationTechnology | undefined);

  useEffect(() => {
    setOriginalDt(decorationTechnology);
    setEditedDt(decorationTechnology);
  }, [decorationTechnology]);

  const discardChanges = (): void => {
    if (originalDt && originalDt._isNew) {
      deleteSelf();
    }
    setEditedDt(originalDt);
  };

  return (<Card header={<FlexBox>
    <FlexBox justifyContent="flex-start">
      <Tooltip contents={
        <Button className={buttonDanger} blockLevel onClick={deleteSelf}> <FontAwesomeIcon icon="radiation-alt" /> Delete <FontAwesomeIcon icon="radiation-alt" /></Button>}
      >
        {decorationTechnology.name}
      </Tooltip>
    </FlexBox>
    <FlexBox className="button-group" justifyContent="flex-end">
      <Button size="sm" disabled={!originalDt?._isNew && JSON.stringify(editedDt) === JSON.stringify(originalDt)} className={buttonWarning} onClick={discardChanges}>Discard Changes</Button>
      <Button size="sm" disabled={!originalDt?._isNew && JSON.stringify(editedDt) === JSON.stringify(originalDt)} className={buttonSuccess} onClick={() => {
        if (editedDt) updateSelf(editedDt);
      }}>Save Changes</Button>
    </FlexBox>
  </FlexBox>}>
    <h6>Decoration Technology-Level Properties</h6>
    <table className="table table-bordered table-hover">
      <thead>
        <tr>
          <th>Property Name</th>
          <th>Property Value</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {editedDt && Object.keys(editedDt?.decorationTechnologyProperties).map((property, i) => {
          const value = editedDt.decorationTechnologyProperties[property];
          return (<tr key={i}>
            <td>{property}</td>
            <td><TextField value={value} onChange={e => {
              const dt = _.cloneDeep(editedDt);
              dt.decorationTechnologyProperties[property] = e.target.value;
              setEditedDt(dt);
            }} /></td>
            <td><Button onClick={() => {
              const dt = _.cloneDeep(editedDt);
              delete dt.decorationTechnologyProperties[property];
              setEditedDt(dt);
            }} className={buttonDanger}><FontAwesomeIcon icon="trash" /></Button></td>
          </tr>);
        })}
      </tbody>
    </table>
    <Button blockLevel onClick={() => {
      const dt = _.cloneDeep(editedDt);
      const newName = prompt('Enter a new property name');
      if (dt && newName) {
        dt.decorationTechnologyProperties[newName] = '';
        setEditedDt(dt);
      }
    }}><FontAwesomeIcon icon="plus" /> Add Property</Button>
    <hr />
    <h5>Required Properties</h5>
    <PropertyTable properties={editedDt?.requiredProperties ?? []} updateProperties={newProps => {
      const dt = _.cloneDeep(editedDt);
      if (dt) {
        dt.requiredProperties = newProps;
        setEditedDt(dt);
      }
    }} />
    <hr />
    <h5>Optional Properties</h5>
    <PropertyTable properties={editedDt?.optionalProperties ?? []} updateProperties={newProps => {
      const dt = _.cloneDeep(editedDt);
      if (dt) {
        dt.optionalProperties = newProps;
        setEditedDt(dt);
      }
    }} />
  </Card>);
}

function DecorationTechnologyAdmin({ showAlert }: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isMipiUser, setIsMipiUser] = useState(false);
  const [decorationTechnologies, setDecorationTechnologies] = useState([] as DecorationTechnology[]);

  useEffect(() => {
    if (auth.getAccessToken()) {
      get<any>('https://api.cimpress.io/auth/access-management/v1/principals/self/groups').then(response => {
        if (response.groups.some((g: any) => g.id.toString() === '3268')) {
          setIsMipiUser(true);
          loadDecorationTechnologies();
        }
      }).catch(() => setIsMipiUser(false));
    }
  }, [auth.getAccessToken()]);

  const loadDecorationTechnologies = () => {
    get<DecorationTechnology[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/DecorationTechnologies`).then(setDecorationTechnologies).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Equipment Service',
        type: 'danger'
      });
    });
  };

  const addNewDecorationTechnology = () => {
    const newName = prompt('What should the new technology be called?');
    if (newName) {
      const dts = _.cloneDeep(decorationTechnologies);
      dts.push({
        name: newName,
        requiredProperties: [],
        optionalProperties: [],
        decorationTechnologyProperties: {},
        _isNew: true
      } as any);
      setDecorationTechnologies(dts);
    }
  };

  const updateOrCreateDecorationTechnology = (updatedDt: DecorationTechnology | undefined): void => {
    if (updatedDt) {
      let p: Promise<void>;
      if (updatedDt?._isNew) {
        p = post<DecorationTechnology>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/DecorationTechnologies`, updatedDt);
      } else {
        p = patch<DecorationTechnology>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/DecorationTechnologies?name=${updatedDt?.name}`, updatedDt);
      }
      p.catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Equipment Service',
          type: 'danger'
        });
      }).finally(() => loadDecorationTechnologies());
    }
  };

  const deleteDecorationTechnology = (name: string): void => {
    const confirm = prompt(`Enter the name of this decoration technology (${name}) to delete it.`);
    if (confirm === name) {
      deleteResource(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/DecorationTechnologies?name=${name}`).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Equipment Service',
          type: 'danger'
        });
      }).finally(() => loadDecorationTechnologies());
    }
  };

  return (<>
    {isMipiUser ? <div className="container">
      <Card header={<FlexBox>
        <FlexBox justifyContent="flex-start">
          <h5>Decoration Technology Admin</h5>
        </FlexBox>
        <FlexBox justifyContent="flex-end">
          <Button className={button} size="sm" variant="secondary" onClick={loadDecorationTechnologies}><FontAwesomeIcon icon="sync" /> Reload</Button>
        </FlexBox>
      </FlexBox>}>
        {decorationTechnologies.map((dt, i) => <>
          <DecorationTechnologyEditor decorationTechnology={dt} key={dt.name} deleteSelf={() => {
            if (dt._isNew) {
              const dts = _.cloneDeep(decorationTechnologies);
              dts.splice(i, 1);
              setDecorationTechnologies(dts);
            } else {
              deleteDecorationTechnology(dt.name);
            }
          }} updateSelf={updateOrCreateDecorationTechnology} />
          <br />
        </>
        )}
        <Button blockLevel className={buttonSuccess} variant="primary" onClick={addNewDecorationTechnology}><FontAwesomeIcon icon="plus" /> Add New</Button>
      </Card>
      <br />
    </div> :
      <Card>
        <FlexBox justifyContent="center">
          <Robot status="warning" size="lg" />
        </FlexBox>
        <Alert
          status="danger"
          message="You must be a member of the Press Integration squad to use this page."
          dismissible={false}
        />
      </Card>}
  </>);
}

export default DecorationTechnologyAdmin;
