import React, {ReactElement} from 'react';
import {ReactSVG} from 'react-svg';

function CardHeader({ svgName }: { svgName: string }): ReactElement {
  return (
    <div style={{padding: '8.5px 1px', maxWidth: '220px', margin: '0px auto', textAlign: 'center', position: 'relative'}}>
      <ReactSVG src="https://static.ux.cimpress.io/assets/icons/card_icon_bg.svg" />
      <ReactSVG
        src={`https://static.ux.cimpress.io/assets/icons/${svgName}.svg`}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '24px',
          height: '24px',
        }}
      />
    </div>
  );
}

export default CardHeader;
