import {ModalAlert} from '../types/modal-alert';
import React, {ReactElement, useState} from 'react';
import TenantSelector from '../components/TenantSelector';
import {
  Alert,
  Button,
  Card,
  FlexBox,
  Modal, Select,
  Slider,
  Robot,
  Spinner,
} from '@cimpress/react-components';
import {deleteResource, get, post} from '../clients/AuthClient';
import {AvailableStatuses, RipLimitConfig} from '../types/rip-limit-config';
import {LineWithName} from '../types/equipment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SelectOption} from '../types/select';
import {buttonDanger, buttonSuccess, buttonWarning} from '../types/button-styles';

function RipLimitSetup({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [tenant, setTenant] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ripLimitConfig, setRipLimitConfig] = useState([] as RipLimitConfig[]);
  const [lines, setLines] = useState([] as LineWithName[]);
  const [isAddingLimit, setIsAddingLimit] = useState(false);
  const [newRipLimitLine, setNewRipLimitLine] = useState(undefined as any as number | undefined);
  const [newRipLimitLimit, setNewRipLimitLimit] = useState(1);

  const tenantSelectionChange = (tenant: string) => {
    setTenant(tenant);
    if (!tenant) {
      setRipLimitConfig([]);
      setLines([]);
      return;
    }
    loadRipLimitConfig(tenant);
    get<LineWithName[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/Lines?tenant=${tenant}`).then(l => {
      setLines(l);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an error from the equipment service',
        type: 'danger'
      });
    });
  };

  const loadRipLimitConfig = (tenant: string) => {
    setIsLoading(true);
    get<RipLimitConfig[]>(`${process.env.REACT_APP_VIPER_RIP_ADAPTER_URL}/v1/${tenant}/ripLimits`)
      .then(ripLimits => {
        setRipLimitConfig(ripLimits);
      }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Viper Rip Adapter',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteRipLimitConfig = (tenant: string, lineNbr: number) => {
    setIsLoading(true);
    deleteResource(`${process.env.REACT_APP_VIPER_RIP_ADAPTER_URL}/v1/${tenant}/ripLimits/line/${lineNbr}`)
      .then(() => {
        loadRipLimitConfig(tenant);
      }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Viper Rip Adapter',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const updateRipLimitConfig = (tenant: string, lineNbr: number, limit: number, inProgressStatuses: number[] | string[] | undefined) => {
    setIsLoading(true);
    post(`${process.env.REACT_APP_VIPER_RIP_ADAPTER_URL}/v1/${tenant}/ripLimits/line/${lineNbr}`, {
      limit: limit,
      inProgressStatuses: inProgressStatuses?.map((s: any) => parseInt(s.toString(), 10))
    })
      .then(() => {
        loadRipLimitConfig(tenant);
        setIsAddingLimit(false);
      }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Viper Rip Adapter',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getSelectedLine = (): SelectOption | undefined => {
    if (!newRipLimitLine) {
      return undefined;
    }
    return {
      label: lines.find(l => l.lineNbr === newRipLimitLine)?.lineName ?? `Line number ${newRipLimitLine}`,
      value: newRipLimitLine.toString() ?? ''
    };
  };

  return (
    <div className="container">
      <Card header="RIP Limit Configuration">
        {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size="large"/>
        </FlexBox>
        }
        <div className="col-md-12">
          <TenantSelector tenantSelected={tenantSelectionChange}/>
        </div>
      </Card>
      <br/>
      {!tenant ?
        <Card>
          <FlexBox justifyContent="center">
            <Robot status="warning" size="lg"/>
          </FlexBox>
          <Alert
            status="info"
            message="Select a tenant to edit configuration"
            dismissible={false}
          />
        </Card> :
        <Card header="Configured RIP Limits">
          {ripLimitConfig.map(rl =>
            <>
              <Card key={rl.lineNbr}
                    header={`Line ${lines.find(l => l.lineNbr === rl.lineNbr)?.lineName ?? rl.lineNbr}`}>
                <div className="row">
                  <div className="col-md-4">
                    <Slider value={rl.limit} increment={1} label="How many in-progress jobs?"
                            min={1} max={250}
                            onSelect={value => updateRipLimitConfig(tenant, rl.lineNbr, value, rl.inProgressStatuses)}/>
                  </div>
                  <div className="col-md-4">
                    <Select label="Extra In-Progress Statuses" isMulti={true as any}
                            value={rl.inProgressStatuses ? rl.inProgressStatuses.map(s => {
                              return {
                                label: AvailableStatuses.find(as => as.value === s.toString())?.label ?? 'Unknown status',
                                value: s.toString()
                              };
                            }) as any : []}
                            options={AvailableStatuses}
                            onChange={value => updateRipLimitConfig(tenant, rl.lineNbr, rl.limit, value ? (value as any).map((v: any) => v.value) : [])}/>
                  </div>
                  <div className="col-md-3">
                    In-progress statuses will always include &quot;RIP&quot; and &quot;Transfer to
                    RIP&quot;
                  </div>
                  <div className="col-md-1">
                    <FlexBox justifyContent="flex-end">
                      <Button className={buttonDanger}
                              onClick={() => deleteRipLimitConfig(tenant, rl.lineNbr)}><FontAwesomeIcon
                        icon="trash"/></Button>
                    </FlexBox>
                  </div>
                </div>
              </Card>
              <br/>
            </>)}
          <Button variant="primary" blockLevel onClick={() => {
            setIsAddingLimit(true);
            setNewRipLimitLimit(1);
            setNewRipLimitLine(undefined);
          }}><FontAwesomeIcon icon="plus"/> Add
            RIP Limit</Button>
          <Modal show={isAddingLimit} title="Add RIP Limit" className="modal-tall" footer={
            <div className="row">
              <div className="col-md-6">
                <Button blockLevel className={buttonWarning}
                        onClick={() => setIsAddingLimit(false)}>Cancel</Button>
              </div>
              <div className="col-md-6">
                <Button blockLevel className={buttonSuccess}
                        disabled={!newRipLimitLimit || !newRipLimitLine}
                        onClick={() => updateRipLimitConfig(tenant, newRipLimitLine ?? 0, newRipLimitLimit, [])}>Save
                  New Limit</Button>
              </div>
            </div>
          }>
            <Card>
              <div className="row">
                <div className="col-md-12">
                  <Select label="Select a line" value={getSelectedLine()} options={lines.map(l => {
                    return {
                      label: l.lineName,
                      value: l.lineNbr.toString()
                    };
                  })} onChange={value => {
                    setNewRipLimitLine(parseInt(value?.value ?? '0', 10));
                  }}/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <Slider value={newRipLimitLimit} increment={1} label="How many in-progress jobs?"
                          min={1} max={250}
                          onSelect={value => {
                            setNewRipLimitLimit(value);
                          }}/>
                </div>
                <div className="col-md-4">
                  In-progress statuses will always include &quot;RIP&quot; and &quot;Transfer to
                  RIP&quot;
                </div>
              </div>
            </Card>
          </Modal>
        </Card>
      }
    </div>
  );
}

export default RipLimitSetup;
