import {
  Alert,
  Button,
  Card,
  Checkbox,
  FlexBox, Modal, Select,
  TextField,
  Robot,
  Spinner,
} from '@cimpress/react-components';
import _ from 'lodash';
import React, {ReactElement, useEffect, useState} from 'react';
import {
  deleteResource,
  get,
  postWithResponse,
} from '../clients/AuthClient';
import TenantSelector from '../components/TenantSelector';
import {ModalAlert} from '../types/modal-alert';
import {
  CreateTestJobRequest, CreateTestJobResponse,
  TestJob,
  TestJobDecorationTechnology, TestJobFile
} from '../types/test-jobs';
import {SelectOption} from '../types/select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory, useLocation} from 'react-router-dom';
import TestJobFileUploader from '../components/TestJobFileUploader';
import { saveAs } from 'file-saver';
import {button, buttonDanger, buttonInfo} from '../types/button-styles';

/* eslint-disable @typescript-eslint/no-var-requires */
const JSZip = require('jszip');

function TestJobSetup({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [tenant, setTenant] = useState('');
  const [decorationTechnologies, setDecorationTechnologies] = useState([] as TestJobDecorationTechnology[]);
  const [testJobs, setTestJobs] = useState([] as TestJob[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingTestJob, setIsCreatingTestJob] = useState(false);
  const [isReplacingTestJobFile, setIsReplacingTestJobFile] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [testJobToDownload, setTestJobToDownload] = useState({} as TestJob);
  const [testJobToReplace, setTestJobToReplace] = useState({} as TestJob);
  const [newTestJobName, setNewTestJobName] = useState('');
  const [newTestJobDecorationTechnology, setNewTestJobDecorationTechnology] = useState(null as SelectOption | null);
  const [newTestJobProperties, setNewTestJobProperties] = useState({} as Record<string, any>);
  const [newTestJobFiles, setNewTestJobFiles] = useState([] as TestJobFile[]);
  const [testJobFilter, setTestJobFilter] = useState(undefined as string | undefined);
  const [tagFilter, setTagFilter] = useState('');
  const [groupTags, setGroupTags] = useState([] as string[]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = 'Test Job Setup';
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('testJobFilter')) {
      testJobFilterChanged(queryParams.get('testJobFilter') ?? '');
    }
    if (tenant) {
      get<TestJobDecorationTechnology[]>(`${process.env.REACT_APP_TEST_JOB_SERVICE_URL}/decorationTechnologies?tenant=${tenant}`).then(setDecorationTechnologies).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an error from the test job service',
          type: 'danger'
        });
      });
    }
  }, [tenant]);

  useEffect(() => {
    if (tenant) {
      loadTestJobs(tenant);
    } else {
      setTestJobs([]);
    }
  }, [tenant]);


  useEffect(() => {
    if (testJobs.length > 0) {
      updateGroupTags();
    } else {
      setGroupTags([]);
    }
  }, [testJobs]);

  function loadTestJobs(tenant: string): Promise<any> {
    setIsLoading(true);
    return get<TestJob[]>(`${process.env.REACT_APP_TEST_JOB_SERVICE_URL}/listJobs?tenant=${tenant}`).then(tj => {
      setTestJobs(_.sortBy(tj, [o => o.decorationTechnology, o => o.name]));
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an error from the test job service',
        type: 'danger'
      });
    }).finally(() => {
        setTagFilter('');
        setIsLoading(false);
    });
  }

  function updateGroupTags() {
    const uniqueGroups = _.uniq(testJobs.filter(tj => tj.properties['group']).map(tj => tj.properties['group'])).sort() as string[];
    setGroupTags(uniqueGroups);
  }

  function createNewTestJob() {
    setIsLoading(true);
    const testJobProperties = { ...newTestJobProperties };
    if (newTestJobDecorationTechnology) {
      // add missing bool properties and set to false
      const boolProperties = decorationTechnologies.find(d => d.name === newTestJobDecorationTechnology.value)?.properties.filter(p => p.type === 'bool') ?? [];
      const missingBoolProperties = _.difference(boolProperties.map(p => p.name), Object.keys(newTestJobProperties));
      if (missingBoolProperties.length > 0) {
        missingBoolProperties.forEach(property => {
          testJobProperties[property] = false;
        });
      }
      postWithResponse<CreateTestJobRequest, CreateTestJobResponse>(`${process.env.REACT_APP_TEST_JOB_SERVICE_URL}/createJob`, {
        tenant: tenant,
        decorationTechnology: newTestJobDecorationTechnology.value,
        name: newTestJobName,
        properties: testJobProperties,
        files: newTestJobFiles
      } as CreateTestJobRequest)
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the Test Job Service',
          type: 'danger'
        });
      })
      .finally(() => {
        setIsLoading(false);
        setIsCreatingTestJob(false);
        setNewTestJobDecorationTechnology(null);
        setNewTestJobName('');
        setNewTestJobProperties({});
        setNewTestJobFiles([]);
        setTagFilter('');
        loadTestJobs(tenant);
      });
    }
  }

  function deleteTestJob(testJob: TestJob): Promise<any> {
    setIsLoading(true);
    return deleteResource(`${process.env.REACT_APP_TEST_JOB_SERVICE_URL}/deleteJob?jobId=${testJob.jobId}`)
      .then(() => {
        return loadTestJobs(tenant);
      })
      .catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an error from the test job service',
          type: 'danger'
        });
      })
      .finally(() => {
        setTagFilter('');
        setIsLoading(false);
      });
  }

  function replaceTestJobFile() {
    deleteTestJob(testJobToReplace).then(() => {
      createNewTestJob();
    });
    setIsReplacingTestJobFile(false);
  }

  function tagFilterChange(tagName: string) {
    const newTagFilter = tagFilter === tagName ? '' : tagName;
    setTagFilter(newTagFilter);
  }

  function getChipsForTags(): ReactElement {
    return (<FlexBox className="button-group" flexWrap="wrap" justifyContent="initial">
        {
          groupTags.map(gt => {
            return <Button size="sm"
                           style={{
                             color: 'green',
                             borderRadius: '16px',
                             border: '1px solid rgba(0, 0, 0, 0.25)'
                           }}
                           onClick={() => tagFilterChange(gt)}
                           key={gt}>
              {tagFilter === gt && <span><FontAwesomeIcon icon="check" /> </span>}{gt}
            </Button>;
          })
        }
      </FlexBox>
    );
  }

  function testJobFilterChanged(filter: string) {
    const lowerFilter = filter.toLowerCase();
    setTestJobFilter(lowerFilter);
    const queryParams = new URLSearchParams(location.search);
    if (!lowerFilter) {
      queryParams.delete('testJobFilter');
    } else {
      queryParams.set('testJobFilter', lowerFilter);
    }
    history.push({
      search: queryParams.toString()
    });
  }

  async function onDownload(testJob: TestJob) {
    setTestJobToDownload(testJob);
    setIsDownloading(true);
    const zip = new JSZip();
    const zipFilesPromises = Promise.all(testJob.files.map(async file => {
      const response = await fetch(file.fileUrl);
      zip.file(file.fileName, await response.blob());
    }));
    await zipFilesPromises;
    zip.generateAsync({type: 'blob'}).then((content: Blob) => {
      saveAs(content, `${testJob.jobId}.zip`);
      setIsDownloading(false);
    });
  }

  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox>
      }
      <div className="container">
        <Card header="Test Job Setup">
          <div className="row">
            <div className="col-md-10">
              <TenantSelector tenantSelected={setTenant} />
            </div>
            <div className="col-md-2">
              <Button className={button} variant="primary" onClick={() => loadTestJobs(tenant)}>
                <FontAwesomeIcon icon="sync" /> Refresh
              </Button>
            </div>
          </div>
          <div>
            <TextField placeholder="Filter" onChange={c => testJobFilterChanged(c.target.value)}
                       value={testJobFilter}
                       disabled={!tenant}
                       rightAddon={<Button className={button} onClick={() => setIsCreatingTestJob(true)} disabled={!tenant}> <FontAwesomeIcon icon="plus" /> Add Test Job</Button>}
                       helpText="Search for Test Jobs"
            />
          </div>
          <div>
            {getChipsForTags()}
          </div>
        </Card>
        <br/>
        {!tenant ?
          <Card>
            <FlexBox justifyContent="center">
              <Robot status="warning" size="lg"/>
            </FlexBox>
            <Alert
              status="info"
              message="Select a tenant to configure test jobs"
              dismissible={false}
            />
          </Card> : <Card header="Test Jobs">
            {testJobs.filter(tj => {
              const filter = testJobFilter?.toLowerCase() ?? '';
              const textFilter = tj.name.toLowerCase().includes(filter) || tj.decorationTechnology.toLowerCase().includes(filter) || (tj.properties['group'] && tj.properties['group'].toString().toLowerCase().includes(filter));
              const matchesGroupTagFilter = !tagFilter || (tj.properties['group'] && tj.properties['group'].toString().toLowerCase() === tagFilter.toLowerCase());
              return textFilter && matchesGroupTagFilter;
            })
              .map(testJob => {
              return (<span style={{border: '1px solid #c4cdd6', padding: '10px 15px'}} className="list-group-item" key={testJob.jobId}>
                <h4>{testJob.name}</h4>
                <p>Decoration Technology: {testJob.decorationTechnology}</p>
                {Object.keys(testJob.properties).length > 0 && <div>Properties:
                  <ul>
                    {Object.keys(testJob.properties).map(key => <li key={key}>
                      <b>{_.startCase(key)}:</b> {testJob.properties[key].toString()}
                    </li>)}
                  </ul>
                </div>}
                <FlexBox className="button-group" justifyContent="flex-end">
                  <Button variant="primary" className={buttonDanger} size="sm"
                          onClick={() => {
                            deleteTestJob(testJob);
                          }}>
                    <FontAwesomeIcon icon="trash" /> Delete Test Job
                  </Button>
                  <Button style={{width:'136px'}} variant="primary" className={button} size="sm"
                          onClick={() => onDownload(testJob)}>
                    {isDownloading && testJobToDownload == testJob ?
                      <FontAwesomeIcon icon="spinner" className="fa-spin"/> :
                      <span><FontAwesomeIcon icon="download" /> Download</span>
                    }
                  </Button>
                  <Button variant="primary" className={buttonInfo} size="sm"
                          onClick={() => {
                            setTestJobToReplace(testJob);
                            setNewTestJobDecorationTechnology({value: testJob.decorationTechnology, label:  testJob.decorationTechnology});
                            setNewTestJobName(testJob.name);
                            setNewTestJobProperties(testJob.properties);
                            setIsReplacingTestJobFile(true);
                          }}>
                    <FontAwesomeIcon icon="user-edit" /> Replace Test Files
                  </Button>
                </FlexBox>
              </span>);
            })
            }
          </Card>
        }
        <Modal
          size="lg"
          status="info"
          className="modal-tall"
          show={isCreatingTestJob}
          onRequestHide={() => setIsCreatingTestJob(false)}
          title="Add a new Test Job"
          closeButton={true}
          footer={(
            <div className="button-group">
              <Button disabled={newTestJobFiles.length == 0 || !newTestJobName || !newTestJobDecorationTechnology || newTestJobFiles.find(testJob => testJob.fileType == null) != undefined} onClick={() => createNewTestJob()}>
                Save
              </Button>
              <Button onClick={() => setIsCreatingTestJob(false)}>
                Close
              </Button>
            </div>
          )}
        >
          <TextField
            name="simple"
            label="New Test Job Name"
            value={newTestJobName}
            onChange={e => setNewTestJobName(e.target.value)}
            autoFocus={true}
          />
          <br/>
          <Select
            isClearable
            label="Select a Decoration Technology"
            value={newTestJobDecorationTechnology}
            options={decorationTechnologies.map(dt => {return {
              label: dt.name,
              value: dt.name
            };
            })}
            onChange={(value) => setNewTestJobDecorationTechnology(!value ? null : {
              value: value.value,
              label: value.label
            })}
          />
          {newTestJobDecorationTechnology && decorationTechnologies.find(d => d.name === newTestJobDecorationTechnology.value)?.properties.map(property => {
            return <React.Fragment key={property.name}>
              {property.possibleValues &&
              <Select
                label={_.startCase(property.name)}
                helpText={property.description}
                value={newTestJobProperties[property.name] ?
                  {label: property.possibleValues.find(p => p.value === newTestJobProperties[property.name])?.name ?? 'Deleted Property',
                    value: newTestJobProperties[property.name].toString()} : undefined}
                options={property.possibleValues.map(p => {
                  return {
                    label: p.name,
                    value: p.value.toString()
                  };
                })}
                onChange={(value: any) => {
                  const currentProperties = { ...newTestJobProperties };
                  currentProperties[property.name] = property.type === 'number' ? +value?.value : value?.value;
                  setNewTestJobProperties(currentProperties);
                }}
              />}
              {property.type === 'bool' ?
                <React.Fragment>
                  <Checkbox
                    label={_.startCase(property.name)}
                    checked={newTestJobProperties[property.name]}
                    onChange={e => {
                      const currentProperties = { ...newTestJobProperties };
                      currentProperties[property.name] = e.target.checked;
                      setNewTestJobProperties(currentProperties);
                    }}
                  />
                  <span>{property.description}</span>
                </React.Fragment> :
                <TextField
                  type={property.type === 'number' ? 'number' : undefined}
                  key={property.name}
                  value={newTestJobProperties[property.name]?.toString()}
                  label={_.startCase(property.name)}
                  helpText={property.description}
                  onChange={e => {
                    const currentProperties = { ...newTestJobProperties };
                    if (property.type === 'number') {
                      const numberValue = Number(e.target.value);
                      currentProperties[property.name] = Number.isNaN(numberValue) ? undefined : numberValue;
                    } else {
                      currentProperties[property.name] = e.target.value;
                    }
                    setNewTestJobProperties(currentProperties);
                  }}
                />
              }
            </React.Fragment>;
          })}
          <TestJobFileUploader tenant={tenant}
                               isLoading={isLoading}
                               setIsLoading={setIsLoading}
                               newTestJobFiles={newTestJobFiles}
                               setNewTestJobFiles={setNewTestJobFiles}
                               showAlert={showAlert}
          />
        </Modal>
        <Modal
          size="lg"
          status="info"
          className="modal-tall"
          show={isReplacingTestJobFile}
          onRequestHide={() => {
            setNewTestJobDecorationTechnology(null);
            setNewTestJobName('');
            setNewTestJobProperties({});
            setNewTestJobFiles([]);
            setIsReplacingTestJobFile(false);
          }}
          title="Replace Test Files for this Job"
          closeButton={true}
          footer={(
            <div className="button-group">
              <Button onClick={() => replaceTestJobFile()}>
                Save
              </Button>
              <Button onClick={() => {
                setNewTestJobDecorationTechnology(null);
                setNewTestJobName('');
                setNewTestJobProperties({});
                setNewTestJobFiles([]);
                setIsReplacingTestJobFile(false);
              }}>
                Close
              </Button>
            </div>
          )}
        >
          <TestJobFileUploader tenant={tenant}
                               isLoading={isLoading}
                               setIsLoading={setIsLoading}
                               newTestJobFiles={newTestJobFiles}
                               setNewTestJobFiles={setNewTestJobFiles}
                               showAlert={showAlert}
          />
        </Modal>
      </div>
    </>
  );
}

export default TestJobSetup;

