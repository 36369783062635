import React, { ReactElement, useEffect, useState } from 'react';
import { PipelineHistoryEvent } from '../types/PipelineHistoryEvent';
import { get } from '../clients/AuthClient';
import { ModalAlert } from '../types/modal-alert';
import { Accordion, Alert, Button, FlexBox, Spinner } from '@cimpress/react-components';
import EditPipeline from './EditPipeline';
import {Pipeline, PipelineEditMode, PipelineTag, StepDefinition} from '../types/pipeline';
import { PropertyBag } from '../types/property-info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PipelineHistory({
  pipelineId,
  tenant,
  isActive,
  pipelineTags,
  propertyBags,
  variablePropertyBags,
  stepDefinitions,
  fileTypes,
  showAlert,
  restorePipeline
}: {
  pipelineId?: string,
  tenant: string | undefined,
  isActive: boolean,
  pipelineTags: PipelineTag[],
  propertyBags: PropertyBag[],
  variablePropertyBags: PropertyBag[],
  stepDefinitions: StepDefinition[],
  fileTypes: string[],
  showAlert: (alert: ModalAlert) => void,
  restorePipeline: (pipeline: Pipeline) => void
}): ReactElement {

  const [pipelineHistory, setPipelineHistory] = useState([] as PipelineHistoryEvent[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isActive) {
      setIsLoading(true);
      get<PipelineHistoryEvent[]>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines/history/${pipelineId}`)
        .then(historyResponse => {
          setPipelineHistory(historyResponse);
        }).catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an Error from the Pipeline Service',
            type: 'danger'
          });
        }).finally(() => {
          setIsLoading(false);
        });
    }
  }, [isActive]);

  return (
    <>
      {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size="medium" />
        </FlexBox>
      }
      {pipelineId && <div>
        {pipelineHistory.map(historyEvent => {
          return <Accordion title={`Changed by ${historyEvent.user} on ${new Date(historyEvent.timestamp).toLocaleString()}`}
            key={historyEvent.timestamp}>
            <Button blockLevel onClick={() => {
              const p = historyEvent.pipeline;
              p.name = `${p.name} - Restored from ${new Date(historyEvent.timestamp).toLocaleString()}`;
              p.enabled = false;
              p.id = '';
              restorePipeline(p);
            }}><FontAwesomeIcon icon="copy" /> Restore Pipeline from This Date</Button>
            <br />
            {historyEvent.pipeline.enabled ?
              <Alert status="success" message="Pipeline is enabled." dismissible={false} /> :
              <Alert status="warning" message="Pipeline is disabled." dismissible={false} />
            }
            <EditPipeline tenant={tenant} pipeline={historyEvent.pipeline} editingMode={PipelineEditMode.readOnly} propertyBags={propertyBags} variablePropertyBags={variablePropertyBags} stepDefinitions={stepDefinitions} fileTypes={fileTypes} pipelineTags={pipelineTags} showAlert={showAlert} setIsLoading={setIsLoading} stopEditing={() => { return; }} refreshPipelines={Promise.resolve} />
          </Accordion>;
        })}
      </div>}
    </>
  );
}

export default PipelineHistory;
