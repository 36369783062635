import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {
  DisplayUnit,
  LabelSheetLayout,
  NewLabelSheetLayout
} from '../types/label-sheet-layout';
import {
  Button,
  Card,
  Modal,
  Select,
  TextField,
  Spinner,
} from '@cimpress/react-components';
import {Col, Row} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {deleteResource, patch, post} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import {SelectOption} from '../types/select';
import {convertUnitsDimensions, convertUnitsSize} from '../utils';
import Qty from 'js-quantities';
import {button, buttonDanger, buttonSuccess, buttonWarning} from '../types/button-styles';

const convertUnits = <T extends NewLabelSheetLayout>(labelSheetLayout: T, selectedUnits: string, toUnit: string): T => {
  if (selectedUnits === toUnit) {
    return labelSheetLayout;
  }
  return ({
    ...labelSheetLayout,
    page: labelSheetLayout.page && convertUnitsSize(labelSheetLayout.page, selectedUnits, toUnit),
    label: labelSheetLayout.label && convertUnitsSize(labelSheetLayout.label, selectedUnits, toUnit),
    margin: labelSheetLayout.margin && convertUnitsDimensions(labelSheetLayout.margin, selectedUnits, toUnit),
    rowSpacingInch: labelSheetLayout.rowSpacingInch && Qty(labelSheetLayout.rowSpacingInch, selectedUnits).to(toUnit).toPrec(0.01).scalar,
    columnSpacingInch: labelSheetLayout.columnSpacingInch && Qty(labelSheetLayout.columnSpacingInch, selectedUnits).to(toUnit).toPrec(0.01).scalar,
    headerHorizontalOffsetInch: labelSheetLayout.headerHorizontalOffsetInch && Qty(labelSheetLayout.headerHorizontalOffsetInch, selectedUnits).to(toUnit).toPrec(0.01).scalar,
    headerVerticalOffsetInch: labelSheetLayout.headerVerticalOffsetInch && Qty(labelSheetLayout.headerVerticalOffsetInch, selectedUnits).to(toUnit).toPrec(0.01).scalar,
    header: labelSheetLayout.header && convertUnitsSize(labelSheetLayout.header, selectedUnits, toUnit)
  });
};

// If a top_left header is selected, we should expand the header width to be the page width
// If a middle_right header is selected, we should expand the header height to be the page height
const expandHeader = <T extends NewLabelSheetLayout>(labelSheetLayout: T, headerLocation: string): T => {
  const newLayout = {
    ... labelSheetLayout
  };
  if (headerLocation === 'top_left' && !!newLayout.header) {
    newLayout.header.widthInch = newLayout.page.widthInch;
  }
  if (headerLocation === 'middle_right' && !!newLayout.header) {
    newLayout.header.heightInch = newLayout.page.heightInch;
  }
  return newLayout;
};

const getInitialConfig = (c: LabelSheetLayout | undefined, toUnit: string): NewLabelSheetLayout => {
  return convertUnits(c ?? {
    rowCount: 2,
    columnCount: 2,
    name: '',
    page: {
      heightInch: 11,
      widthInch: 8.5
    },
    rowSpacingInch: 0,
    columnSpacingInch: 0,
    label: {
      heightInch: 2,
      widthInch: 3
    },
    margin: {
      bottomInch: 0,
      topInch: 0,
      leftInch: 0,
      rightInch: 0
    }
  }, DisplayUnit[DisplayUnit.inch], toUnit);
};

const getVerticalLine = () => {
  return (
    <Row className="border-top-bottom">
      <div className="line-vertical"/>
    </Row>
  );
};

const getHorizontalLine = () => {
  return (
    <Row className="border-left-right">
      <div className="line"/>
    </Row>
  );
};

const toNumberOrUndefined = (value: string): number | undefined => value ? Number(value) : undefined;

function LabelSheetLayoutCard({tenant, originalLabelSheetLayout, lineLabelConfigNamesForLayout,
  headerLocations, isAdding, refresh, selectedUnits, showAlert, finishAdding}:
  {
    tenant: string,
    originalLabelSheetLayout?: LabelSheetLayout,
    lineLabelConfigNamesForLayout?: string[],
    headerLocations: string[],
    isAdding: boolean,
    refresh: () => void,
    selectedUnits: string,
    showAlert: (alert: ModalAlert) => void,
    finishAdding?: () => void,
  }): ReactElement {

  const [isEditing, setIsEditing] = useState(isAdding);
  const [isLoading, setIsLoading] = useState(false);
  const [labelSheetLayout, setLabelSheetLayout] = useState<NewLabelSheetLayout>(() => getInitialConfig(originalLabelSheetLayout, selectedUnits));
  const [currentUnits, setCurrentUnits] = useState(selectedUnits);
  const [isDeleting, setIsDeleting] = useState(false);

  const headerLocation = useMemo(() => headerLocations[labelSheetLayout.headerLocation ?? -1],
    [headerLocations, labelSheetLayout]);
  const showTopHeader = useMemo(() => headerLocation === 'top_left', [headerLocation]);
  const showRightHeader = useMemo(() => headerLocation === 'middle_right', [headerLocation]);

  const headerSelectionOptions: SelectOption[] = useMemo(() => headerLocations.map(u => ({
      value: u,
      label: _.startCase(u)
    })),
    [headerLocations]);
  const selectedHeaderLocation: SelectOption | null = useMemo(() => headerSelectionOptions.find(o => o.value === headerLocation) ?? null,
    [headerLocation, headerSelectionOptions]);

  useEffect(() => {
    if (currentUnits === selectedUnits) {
      return;
    }
    setLabelSheetLayout(convertUnits(labelSheetLayout, currentUnits, selectedUnits));
    setCurrentUnits(selectedUnits);
  }, [selectedUnits, currentUnits]);

  function editConfig(edit: (c: NewLabelSheetLayout) => void): void {
    setLabelSheetLayout(oldConfig => {
      const newConfig = _.cloneDeep(oldConfig);
      edit(newConfig);
      return newConfig;
    });
  }

  const handleError = useCallback((e: any) => {
    console.error(e);
    showAlert({
      message: e.message,
      title: 'Received an Error from the Label Sheet Service',
      type: 'danger'
    });
  }, [showAlert]);

  const updateLabelSheetLayout = (layoutId: string) => {
    let layout = convertUnits(labelSheetLayout, selectedUnits, DisplayUnit[DisplayUnit.inch]);
    layout = expandHeader(layout, headerLocation);
    if (!validateLayout(layout)) {
      return;
    }
    setIsLoading(true);
    patch<NewLabelSheetLayout>(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/labelSheetLayouts/${layoutId}`, layout)
      .then(() => {
        setIsEditing(false);
        refresh();
    }).catch(handleError)
      .finally(() => {
      setIsLoading(false);
    });
  };

  const saveNewLabelSheetLayout = () => {
    let layout = convertUnits(labelSheetLayout, selectedUnits, DisplayUnit[DisplayUnit.inch]);
    layout = expandHeader(layout, headerLocation);
    if (!validateLayout(layout)) {
      return;
    }
    setIsLoading(true);
    post<NewLabelSheetLayout>(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/labelSheetLayouts`, layout)
      .then(() => {
        if (finishAdding) {
          finishAdding();
        }
        refresh();
      }).catch(handleError)
      .finally(() => {
        setIsLoading(false);
    });
  };

  const deleteLabelSheetLayout = (layoutId: string) => {
    setIsLoading(true);
    deleteResource(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/labelSheetLayouts/${layoutId}`)
      .then(() => {
        refresh();
      }).catch(handleError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getRowHeight = () => {
    const headerRowCount = Number(showTopHeader);
    return 100 / (labelSheetLayout.rowCount + (labelSheetLayout.rowCount - 1) + headerRowCount);
  };

  const getColumnWidth = () => {
    return 100 / (labelSheetLayout.columnCount + (labelSheetLayout.columnCount - 1));
  };

  const validateLayout = (layout: NewLabelSheetLayout) => {
    if (layout.margin.leftInch + layout.margin.rightInch +
      (layout.label.widthInch * layout.columnCount) +
      (layout.columnSpacingInch * (layout.columnCount - 1)) > layout.page.widthInch) {
      showAlert({
        message: <>Provided dimensions exceed page width</>,
        title: 'Invalid layout',
        type: 'danger'
      });
      return false;
    } else if (layout.margin.topInch + layout.margin.bottomInch +
      (layout.label.heightInch * layout.rowCount) +
      (layout.rowSpacingInch * (layout.rowCount - 1)) > layout.page.heightInch) {
      showAlert({
        message: <>Provided dimensions exceed page height</>,
        title: 'Invalid layout',
        type: 'danger'
      });
      return false;
    }
    return true;
  };

  return (
    <>
      {isLoading &&
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Spinner size="medium" />
      </div>}
      <Card header={
        <Row>
          <Col span={12}>
            {!isEditing && labelSheetLayout.name}
            {isEditing &&
            <TextField label="Label Sheet Layout Name" value={labelSheetLayout.name}
                       onChange={(e) => editConfig(c => c.name = e.target.value)}
            />}
          </Col>
          <Col span={12}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              {isEditing &&
                <>
                {
                  isAdding ?
                    <Button variant="primary"
                            style={{marginLeft: '1rem'}}
                            className={buttonSuccess}
                            onClick={() => saveNewLabelSheetLayout()}>
                      <FontAwesomeIcon icon="save" /> Save New Layout
                    </Button> :
                    <Button variant="primary"
                            style={{marginLeft: '1rem'}}
                            className={buttonSuccess}
                            onClick={() => updateLabelSheetLayout(originalLabelSheetLayout?.id ?? '')}>
                      <FontAwesomeIcon icon="save" /> Save Layout
                    </Button>

                }
                  <Button variant="primary"
                          style={{marginLeft: '1rem'}}
                          className={buttonWarning}
                          onClick={() => {
                            setIsEditing(false);
                            if (finishAdding) {
                              finishAdding();
                            }
                            setLabelSheetLayout(getInitialConfig(originalLabelSheetLayout, selectedUnits));
                          }}>
                    <FontAwesomeIcon icon="times" /> Cancel
                  </Button>
                </>
              }
              {!isEditing &&
              <>
                <Button className={button} variant="primary"
                        style={{marginLeft: '1rem'}}
                        onClick={() => setIsEditing(true)}>
                  <FontAwesomeIcon icon="edit" /> Edit Layout
                </Button>
                <Button variant="primary"
                        style={{marginLeft: '1rem'}}
                        className={buttonDanger}
                        onClick={() => {
                          if (lineLabelConfigNamesForLayout && lineLabelConfigNamesForLayout.length > 0) {
                            setIsDeleting(true);
                          } else {
                            deleteLabelSheetLayout(originalLabelSheetLayout?.id ?? '');
                          }
                        }}>
                  <FontAwesomeIcon icon="trash" /> Delete Layout
                </Button>
              </>
              }
            </div>
          </Col>
        </Row>}>
        <>
          <Row justify="center" style={{alignItems: 'stretch', height: '70rem'}}>
            <Col span="16" style={{border: 'solid black 0.1rem'}}>
              {/*Margin Top*/}
              <Row justify="center" style={{height: '14%'}}>
                <Col span="3">
                  <TextField label="Margin Top" value={labelSheetLayout.margin.topInch}
                             disabled={!isEditing}
                             type="number"
                             className="margin-top-bottom"
                             onChange={(e) => editConfig(c => c.margin.topInch = Number(e.target.value))}/>
                </Col>
                <Col span="1" style={{display: 'flex'}}>
                  {getVerticalLine()}
                </Col>
                <Col span="5" offset={1} style={{marginTop: '1rem'}}>
                  <Select isClearable={true} label="Header Location"
                          isDisabled={!isEditing}
                          value={selectedHeaderLocation}
                          options={headerSelectionOptions}
                          onChange={(e: any) => editConfig(c => {
                            if (e == null) {
                              c.header = undefined;
                              c.headerLocation = undefined;
                              c.headerHorizontalOffsetInch = undefined;
                              c.headerVerticalOffsetInch = undefined;
                              c.headerTextFontSize = undefined;
                            } else {
                              const newHeaderLocation = headerLocations.indexOf(e.value);
                              if (c.headerLocation != newHeaderLocation) {
                                c.headerVerticalOffsetInch = undefined;
                                c.headerHorizontalOffsetInch = undefined;
                                c.header = undefined;
                              }
                              c.headerLocation = newHeaderLocation;
                            }
                          })} />
                </Col>
                <Col span="5" offset={1} style={{marginTop: '1rem'}}>
                  {(showTopHeader || showRightHeader) &&
                  <>
                    <TextField label="Header Font Size (pt)" value={labelSheetLayout.headerTextFontSize}
                               disabled={!isEditing}
                               type="number"
                               onChange={(e) => editConfig(c => c.headerTextFontSize = toNumberOrUndefined(e.target.value))}/>
                  </>
                  }
                </Col>
              </Row>
              <Row align="middle" style={{height: '72%'}}>
                {/*Margin Left*/}
                <Col style={{alignItems: 'center', width: '16%'}}>
                  {getHorizontalLine()}
                  <Row>
                    <TextField label="Margin Left" value={labelSheetLayout.margin.leftInch}
                               disabled={!isEditing}
                               type="number"
                               className="margin-left-right"
                               onChange={(e) => editConfig(c => c.margin.leftInch = Number(e.target.value))}/>
                  </Row>
                  <Row justify="center">
                    <Button size="sm" className={`${buttonSuccess} add-button`}
                            variant="primary"
                            disabled={!isEditing}
                            onClick={() => editConfig(c => c.rowCount++)}>Add Row</Button>
                  </Row>
                  <Row justify="center">
                    <Button size="sm" className={`${buttonDanger} delete-button`}
                            variant="primary"
                            disabled={!isEditing || labelSheetLayout.rowCount <= 1}
                            onClick={() => editConfig(c => c.rowCount--)}>Delete Row</Button>
                  </Row>
                </Col>
                {/*Rows and columns*/}
                <Col style={{display: 'flex', border: 'solid gray 0.1rem', height: '100%', width: '68%'}}>
                  <Col style={{height: '100%', width: showRightHeader ? '70%' : '100%'}}>
                    {/*Header*/}
                    {showTopHeader && <Row style={{height: getRowHeight() + '%'}}>
                      <Col span="8">
                        <Row style={{height: '50%'}}>
                          <div style={{display: 'flex', alignItems: 'end'}}><b>Header Text</b></div>
                        </Row>
                        <Row className="header-vertical-offset" style={{height: '50%'}}>
                          <Col span="1" style={{display: 'flex'}}>
                            {getVerticalLine()}
                          </Col>
                        </Row>
                      </Col>
                      <Col span="1"  offset={2} className="header-height" style={{display: 'flex'}}>
                        {getVerticalLine()}
                      </Col>
                    </Row>}
                    {/*First Row*/}
                    <Row style={{height: getRowHeight() + '%'}}>
                      <Col className="label-height" style={{display: 'flex', height: '100%', width: '2%'}}>
                        {getVerticalLine()}
                      </Col>
                      <Col className="label-width" style={{
                        backgroundColor: 'lightgray',
                        width: (getColumnWidth()-2) + '%',
                      }}>
                        <Row style={{height: '40%'}} justify="center">
                          <Col span={24}>
                            {getHorizontalLine()}
                          </Col>
                        </Row>
                        <Row style={{height: '60%'}} justify="center">
                          <FontAwesomeIcon icon="image"/>
                        </Row>
                      </Col>
                      {(!isEditing || labelSheetLayout.columnCount > 1) &&
                        <Col style={{
                          alignItems: 'center',
                          width: getColumnWidth() + '%',
                        }}>
                          <Col className="column-spacing">
                            {getHorizontalLine()}
                          </Col>
                        </Col>
                      }
                      {_.range(1, labelSheetLayout.columnCount).map((count) =>
                        <React.Fragment key={count}>
                          <Col key={count}
                               style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center',
                                 backgroundColor: 'lightgray',
                                 width: getColumnWidth() + '%',
                               }}>
                            <FontAwesomeIcon icon="image"/>
                          </Col>
                          {count !== labelSheetLayout.columnCount - 1 &&
                          <Col
                            style={{width: getColumnWidth() + '%'}}/>}
                        </React.Fragment>
                      )}
                    </Row>
                    {/*Second Row*/}
                    {(!isEditing || labelSheetLayout.rowCount > 1) && <Row style={{height: getRowHeight() + '%'}}>
                      <Col className="row-spacing" style={{
                        width: getColumnWidth() + '%',
                        display: 'flex', height: '100%', justifyContent: 'center'
                      }}>
                        {getVerticalLine()}
                      </Col>
                    </Row>}
                    {/*Dynamically generate rows and columns*/}
                    {_.range(1, labelSheetLayout.rowCount).map((count) =>
                      <React.Fragment key={count}>
                        <Row key={count} style={{height: getRowHeight() + '%'}}>
                          {_.range(0, labelSheetLayout.columnCount).map((colCount) =>
                            <React.Fragment key={colCount}>
                              <Col key={colCount}
                                   style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     justifyContent: 'center',
                                     backgroundColor: 'lightgray',
                                     width: getColumnWidth() + '%',
                                   }}>
                                <FontAwesomeIcon icon="image"/>
                              </Col>
                              {colCount !== labelSheetLayout.columnCount - 1 &&
                              <Col
                                style={{width: getColumnWidth() + '%'}}/>}
                            </React.Fragment>
                          )}
                        </Row>
                        {count !== labelSheetLayout.rowCount - 1 &&
                        <Row style={{height: getRowHeight() + '%'}}/>}
                      </React.Fragment>
                    )}
                  </Col>
                  {/*Header*/}
                  {showRightHeader &&
                    <Col style={{height: '100%', width: '30%'}}>
                      <Row style={{height: '30%', width: '100%'}}>
                        <Col className="header-width" style={{width: '100%'}}>
                          {getHorizontalLine()}
                        </Col>
                      </Row>
                      <Row style={{height: '70%', width: '100%'}}>
                        <Col span={6} className="header-horizontal-offset">
                          {getHorizontalLine()}
                        </Col>
                        <Col span={18}>
                          <div style={{transform: 'rotate(90deg)'}}>
                            <b>Header Text</b>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  }
                </Col>
                {/*Margin Right*/}
                <Col style={{alignItems: 'center', width: '16%'}}>
                  {getHorizontalLine()}
                  <Row>
                    <TextField label="Margin Right" value={labelSheetLayout.margin.rightInch}
                               disabled={!isEditing}
                               type="number"
                               className="margin-left-right"
                               onChange={(e) => editConfig(c => c.margin.rightInch = Number(e.target.value))}
                    />
                  </Row>
                  <Row justify="center">
                    <Button size="sm" className={`${buttonSuccess} add-button`}
                            variant="primary"
                            disabled={!isEditing}
                            onClick={() => editConfig(c => c.columnCount++)}>Add Column</Button>
                  </Row>
                  <Row justify="center">
                    <Button size="sm" className={`${buttonDanger} delete-button`}
                            variant="primary"
                            disabled={!isEditing || labelSheetLayout.columnCount <= 1}
                            onClick={() => editConfig(c => c.columnCount--)}>Delete Column</Button>
                  </Row>
                </Col>
              </Row>
              {/*Margin Bottom*/}
              <Row justify="center" style={{height: '14%'}}>
                <Col span="1" style={{display: 'flex'}}>
                  {getVerticalLine()}
                </Col>
                <Col span="4">
                  <TextField label="Margin Bottom" value={labelSheetLayout.margin.bottomInch}
                             disabled={!isEditing}
                             type="number"
                             className="margin-top-bottom"
                             onChange={(e) => editConfig(c => c.margin.bottomInch = Number(e.target.value))}
                  />
                </Col>
              </Row>
              {/*Page Width*/}
            </Col>
            {/*Page Height*/}
            <Col span="1" style={{display: 'flex'}}>
              {getVerticalLine()}
            </Col>
            <Col span="7" style={{display: 'flex'}}>
              <Row gutter={[0,24]}>
                <Row style={{height: '70%', width: '100%', border: 'solid black 0.1rem'}} justify="center">
                  <Row justify="center" style={{height: '10%', width: '100%'}}>
                    <div>
                      <b>Dimensions</b>
                    </div>
                  </Row>
                  <Row gutter={4} style={{height: '90%', width: '100%'}}>
                    <Row style={{width: '100%'}} gutter={2}>
                      <Col span={6} className="column-spacing">
                        {getHorizontalLine()}
                      </Col>
                      <Col span={18}>
                        <TextField label="Column Spacing" value={labelSheetLayout.columnSpacingInch}
                                   disabled={!isEditing}
                                   type="number"
                                   onChange={(e) => editConfig(c => c.columnSpacingInch = Number(e.target.value))}
                        />
                      </Col>
                    </Row>
                    <Row style={{width: '100%'}} gutter={2}>
                      <Col span={6} className="row-spacing">
                        {getHorizontalLine()}
                      </Col>
                      <Col span={18}>
                        <TextField label="Row Spacing" value={labelSheetLayout.rowSpacingInch}
                                   disabled={!isEditing}
                                   type="number"
                                   onChange={(e) => editConfig(c => c.rowSpacingInch = Number(e.target.value))}
                        />
                      </Col>
                    </Row>
                    <Row style={{width: '100%'}} gutter={2}>
                      <Col span={6} className="label-width">
                        {getHorizontalLine()}
                      </Col>
                      <Col span={18}>
                        <TextField label="Label Width"
                                   value={labelSheetLayout.label.widthInch}
                                   disabled={!isEditing}
                                   type="number"
                                   onChange={(e) => editConfig(c => c.label.widthInch = Number(e.target.value))}
                        />
                      </Col>
                    </Row>
                    <Row style={{width: '100%'}} gutter={2}>
                      <Col span={6} className="label-height">
                        {getHorizontalLine()}
                      </Col>
                      <Col span={18}>
                        <TextField label="Label Height"
                                   value={labelSheetLayout.label.heightInch}
                                   disabled={!isEditing}
                                   type="number"
                                   onChange={(e) => editConfig(c => c.label.heightInch = Number(e.target.value))}
                        />
                      </Col>
                    </Row>
                    {showTopHeader &&
                      <>
                        <Row style={{width: '100%'}} gutter={2}>
                          <Col span={6} className="header-height">
                            {getHorizontalLine()}
                          </Col>
                          <Col span={18}>
                            <TextField label="Header Height"
                                       value={labelSheetLayout.header?.heightInch ?? 0}
                                       disabled={!isEditing}
                                       type="number"
                                       onChange={(e) => editConfig(c => {
                                         c.header = {widthInch: 0, ...c.header, heightInch: Number(e.target.value)};
                                       })}
                            />
                          </Col>
                        </Row>
                        <Row style={{width: '100%'}} gutter={2}>
                          <Col span={6} className="header-vertical-offset">
                            {getHorizontalLine()}
                          </Col>
                          <Col span={18}>
                            <TextField label="Header Vertical Offset"
                                       value={labelSheetLayout.headerVerticalOffsetInch}
                                       disabled={!isEditing}
                                       type="number"
                                       onChange={(e) => editConfig(c => c.headerVerticalOffsetInch = toNumberOrUndefined(e.target.value))}
                            />
                          </Col>
                        </Row>
                      </>
                    }
                    {showRightHeader &&
                      <>
                        <Row style={{width: '100%'}} gutter={2}>
                          <Col span={6} className="header-width">
                            {getHorizontalLine()}
                          </Col>
                          <Col span={18}>
                            <TextField label="Header Width"
                                       value={labelSheetLayout.header?.widthInch ?? 0}
                                       disabled={!isEditing}
                                       type="number"
                                       onChange={(e) => editConfig(c => {
                                         c.header = {heightInch: 0, ...c.header, widthInch: Number(e.target.value)};
                                       })}
                            />
                          </Col>
                        </Row>
                        <Row style={{width: '100%'}} gutter={2}>
                          <Col span={6} className="header-horizontal-offset">
                            {getHorizontalLine()}
                          </Col>
                          <Col span={18}>
                            <TextField label="Header Horizontal Offset"
                                       value={labelSheetLayout.headerHorizontalOffsetInch}
                                       disabled={!isEditing}
                                       type="number"
                                       onChange={(e) => editConfig(c => c.headerHorizontalOffsetInch = toNumberOrUndefined(e.target.value))}
                            />
                          </Col>
                        </Row>
                      </>
                    }
                  </Row>
                </Row>
                <Row style={{height: '30%'}}>
                  <TextField label="Page Height" value={labelSheetLayout.page.heightInch}
                             disabled={!isEditing}
                             type="number"
                             onChange={(e) => editConfig(c => c.page.heightInch = Number(e.target.value))}
                  />
                </Row>
              </Row>
            </Col>
          </Row>
          {/*Page Width*/}
          <Row justify="center">
            <Col span="16" style={{alignItems: 'center'}}>
              {getHorizontalLine()}
              <Row justify="center">
                <TextField label="Page Width" value={labelSheetLayout.page.widthInch}
                           disabled={!isEditing}
                           type="number"
                           onChange={(e) => editConfig(c => c.page.widthInch = Number(e.target.value))}
                />
              </Row>
            </Col>
            <Col span="1"/>
            <Col span="7"/>
          </Row>
        </>
      </Card>
      <br/>
      <Modal status="info"
             title="Delete Layout"
             show={isDeleting}
             closeOnOutsideClick
             closeButton
             onRequestHide={() => setIsDeleting(false)}>
        <div>
          <Row>
            This layout cannot be deleted because it is associated with the following line/label configs:
          </Row>
          <Row>
            <ul>
              {lineLabelConfigNamesForLayout?.map((name, index) => <li key={index}>{name}</li>)}
            </ul>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default LabelSheetLayoutCard;
