import {
  Button,
  Card, Copy,
  FlexBox,
  TextField,
  Spinner,
} from '@cimpress/react-components';
import React, {ReactElement, useState} from 'react';
import {
  postWithResponse
} from '../clients/AuthClient';
import TenantSelector from '../components/TenantSelector';
import {ModalAlert} from '../types/modal-alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {button} from '../types/button-styles';

function SAPBarcodeGenerator({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [tenant, setTenant] = useState(null as unknown as string);

  const [sapBarcode, setSapBarcode] = useState('');
  const [itnOrSubstrateLineNbr, setItnOrSubstrateLineNbr] = useState('');
  const [manufacturerKey, setManufacturerKey] = useState('TestWindsorViper');
  const [sequenceNbr, setSequenceNbr] = useState('1');
  const [quantity, setQuantity] = useState('1');

  const generateSAPBarcode = () => {
    setIsLoading(true);
    postWithResponse<any, string>(
      `${process.env.REACT_APP_SAPBARCODE_GENERATOR_SERVICE_URL}/generatebarcode?tenant=${tenant}&key=${itnOrSubstrateLineNbr}&manufacturerKey=${manufacturerKey}&sequenceNbr=${sequenceNbr}&quantity=${quantity}`, null)
      .then(setSapBarcode).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the SAP Barcode Generator',
        type: 'danger'
      });
    }).finally(() => {
      setItnOrSubstrateLineNbr('');
      setManufacturerKey('TestWindsorViper');
      setSequenceNbr('1');
      setQuantity('1');
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox>
      }
      <Card header="SAP Barcode Generator">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <TenantSelector tenantSelected={setTenant} selectionCleared={() => setTenant(null as unknown as string)}/>
            </div>
            <div className="col-md-6">
              <TextField placeholder="TestWindsorViper" onChange={c => setManufacturerKey(c.target.value)}
                         value={manufacturerKey}
                         helpText="Manufacturer Key"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextField placeholder="ITN or Substrate:LineNbr" onChange={c => setItnOrSubstrateLineNbr(c.target.value)}
                         value={itnOrSubstrateLineNbr}
                         helpText="ITN or Substrate:LineNbr"/>
            </div>
            <div className="col-md-4">
              <TextField placeholder="SequenceNbr" onChange={c => setSequenceNbr(c.target.value)}
                         value={sequenceNbr.toString()}
                         helpText="Sequence Number"/>
            </div>
            <div className="col-md-4">
              <TextField placeholder="Quantity" onChange={c => setQuantity(c.target.value)}
                         value={quantity.toString()}
                         helpText="Quantity"/>
            </div>
          </div>
          <div className="row">
            <Button className={button} variant="primary" blockLevel={true} onClick={generateSAPBarcode}>
              <FontAwesomeIcon icon="barcode" /> Get Barcode
            </Button>
          </div>
          <div className="row" style={{marginTop: '1rem'}}>
            <TextField style={{height: '5vh'}} placeholder="SAP Barcode" onChange={c => setSapBarcode(c.target.value)}
                       value={sapBarcode}
                       disabled={true}
                       rightAddon={<Copy style={{height: '5vh'}} variant="button" value={sapBarcode}><FontAwesomeIcon icon="copy" /> Copy</Copy>}
            />
          </div>
        </div>
      </Card>
    </>
  );
}

export default SAPBarcodeGenerator;
