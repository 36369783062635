import React, {ReactElement, useEffect, useState} from 'react';
import {get} from '../clients/AuthClient';
import {LineLabelConfig} from '../types/line-label-config';
import {LabelSheetLayout} from '../types/label-sheet-layout';
import {LineWithName} from '../types/equipment';
import {ModalAlert} from '../types/modal-alert';
import {useHistory, useLocation} from 'react-router';
import {Col, Row, Space} from 'antd';
import {Alert, Button, Card, TabCard, TextField, Robot, Spinner} from '@cimpress/react-components';
import TenantSelector from '../components/TenantSelector';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LineLabelConfigSetup from '../components/LineLabelConfigSetup';
import LabelSheetLayoutSetup from '../components/LabelSheetLayoutSetup';
import {button} from '../types/button-styles';

const tabKeywords = [
  'layouts',
  'lines'
];

function LabelSheetsSetup({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>();
  const [sortAscending, setSortAscending] = useState(true);
  const [tenant, setTenant] = useState<string>('');
  const [lineLabelConfigs, setLineLabelConfigs] = useState<LineLabelConfig[]>([]);
  const [lines, setLines] = useState<LineWithName[]>([]);
  const [labelSheetLayouts, setLabelSheetLayouts] = useState<LabelSheetLayout[]>([]);
  const [headerLocations, setHeaderLocations] = useState<string[]>([]);

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    refresh();
  }, [tenant]);

  const refresh = () => {
    if (!tenant) {
      setLineLabelConfigs([]);
      return Promise.resolve();
    }
    setIsLoading(true);
    Promise.all([get<LineLabelConfig[]>(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/lineLabelConfiguration`),
      get<LabelSheetLayout[]>(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/labelSheetLayouts`),
      get<LineWithName[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/Lines?tenant=${tenant}`),
      get<string[]>(`${process.env.REACT_APP_LABEL_SHEET_SERVICE_URL}/v1/tenant/${tenant}/labelSheetLayouts/headerLocations`)
    ])
      .then(([lineLabelConfigResponse, labelSheetsResponse, lineResponse, headerLocationsResponse]) => {
        setLines(lineResponse);
        setLabelSheetLayouts(labelSheetsResponse);
        setLineLabelConfigs(lineLabelConfigResponse);
        setHeaderLocations(headerLocationsResponse);
      }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received a service error',
        type: 'danger'
      });
    }).finally(() => setIsLoading(false));
  };

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = 'Label Sheets Setup';
    setIsLoading(false);
    const queryParams = new URLSearchParams(location.search);
    filterChanged(queryParams.get('filter') ?? '');
    selectTabKeyword(queryParams.get('tab') ?? '');
  }, []);

  const filterChanged = (newFilter: string) => {
    setFilter(newFilter?.toLowerCase());
    const queryParams = new URLSearchParams(location.search);
    if (!newFilter) {
      queryParams.delete('filter');
    } else {
      queryParams.set('filter', newFilter.toLowerCase());
    }
    history.push({
      search: queryParams.toString()
    });
  };

  const selectTabKeyword = (tabKeyword: string) => {
    const tabIndex = tabKeywords.findIndex(k => k === tabKeyword);
    tabChanged(tabIndex >= 0 ? tabIndex : 0);
  };

  const tabChanged = (index: number) => {
    setSelectedTab(index);
    const tabKeyword = tabKeywords[index];
    if (tabKeyword) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('tab', tabKeyword);
      history.push({
        search: queryParams.toString()
      });
    }
  };

  return (<>
    {isLoading &&
      <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
        <Spinner size="medium"/>
      </Space>}
    <Row justify="center">
      <Col span="16">
        <Card header="Label Sheets Setup">
          <TenantSelector tenantSelected={setTenant} />
          <TextField placeholder="Filter" onChange={e => filterChanged(e.target.value)}
                     value={filter}
                     disabled={!tenant}
          />
        </Card>
        <br/>
        <Button className={button} onClick={() => setSortAscending(!sortAscending)} disabled={!tenant} variant="primary" blockLevel={true}>
          Sort by Name {sortAscending ? <FontAwesomeIcon icon="sort-alpha-up" /> : <FontAwesomeIcon icon="sort-alpha-down" />}
        </Button>
        <br />
        {
          tenant &&
          <>
            <TabCard tabs={[
              {
                name: 'Layouts',
                block: <LabelSheetLayoutSetup tenant={tenant} filter={filter} sortAscending={sortAscending}
                                              headerLocations={headerLocations} labelSheetLayouts={labelSheetLayouts}
                                              lineLabelConfigs={lineLabelConfigs} refresh={refresh} showAlert={showAlert} />
              },
              {
                name: 'Lines',
                block: <LineLabelConfigSetup tenant={tenant} filter={filter} sortAscending={sortAscending} lines={lines}
                                             labelSheetLayouts={labelSheetLayouts} lineLabelConfigs={lineLabelConfigs}
                                             refresh={refresh} showAlert={showAlert} />
              }
            ]}
            onSelect={(_, selectedKey) => {
              tabChanged(selectedKey);
            }}
           selectedIndex={selectedTab}
            />
          </>
        }

        {!tenant &&
          <Card>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Robot status="warning" size="lg"/>
            </div>
            <Alert
              status="info"
              message="Select a tenant to edit configuration"
              dismissible={false}
            />
          </Card>
        }
      </Col>
    </Row>

  </>);
}

export default LabelSheetsSetup;
