import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeAuth, getUserProfile } from './Auth';
import { GlobalStyles } from '@cimpress/react-components';
import './index.scss';

initializeAuth().then(() => {
  const fullStoryOrgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
  if (fullStoryOrgId) {
    const user = getUserProfile();
    FullStory.init({
      orgId: fullStoryOrgId,
    });
    FullStory.identify(user.name, { displayName: user.name, email: user.email });
  }

  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyles/>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
        integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
        crossOrigin="anonymous"
      />
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
