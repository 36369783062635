import {
  Button,
  Card,
  FlexBox,
  Select,
  Spinner,
  TextField
} from '@cimpress/react-components';
import React, {ReactElement, useState} from 'react';
import {
  postWithResponse
} from '../clients/AuthClient';
import {ModalAlert} from '../types/modal-alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SelectOption} from '../types/select';
import {button} from '../types/button-styles';

function OrderCloner({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const [orderNumber, setOrderNumber] = useState('');
  const [count, setCount] = useState('');
  const [source, setSource] = useState('prod');
  const [destination, setDestination] = useState('test');

  const mswOrdersServiceSourceEnvironments = [{label:'prod', value: 'prod'}, {label:'test', value: 'test'}, {label:'dev', value: 'dev'}] as SelectOption[];
  const mswOrdersServiceDestinationEnvironments = [{label:'test', value: 'test'}, {label:'dev', value: 'dev'}] as SelectOption[];
  const mswOrdersServiceUrls = {
    'test': process.env.REACT_APP_CIMPRESS_MSWORDERS_TEST_SERVICE_URL,
    'dev': process.env.REACT_APP_CIMPRESS_MSWORDERS_DEV_SERVICE_URL
  } as Record<string, any>;

  const getOrderLink = (orderNumber: string) : string => {
    const url = mswOrdersServiceUrls[destination];
    return `${url}/?query=${orderNumber}`;
  };

  const cloneOrder = () => {
    if (source && destination) {
      setIsLoading(true);
      const url = mswOrdersServiceUrls[destination];
      postWithResponse<any, string[]>(`${url}/Manufacturing/Order/Clone?count=${count}&fromEnv=${source}&anonymizeData=true`,
        [orderNumber]).then(orders => {
        showAlert({
          message: <div>
            {orders.map(order => {
              return <div key={order}>
                <p>This order was cloned with order number: {order}.</p>
                <div><a href={getOrderLink(order)}>Order Details</a></div>
              </div>;
            })}
          </div>,
          title: 'Response From MSW Orders Service',
          type: 'info'
        });
      }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error from the MSW Orders Service',
          type: 'danger'
        });
      }).finally(() => {
        setOrderNumber('');
        setSource('');
        setDestination('');
        setCount('');
        setIsLoading(false);
      });
    }
  };

  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox>
      }
      <Card header="Order Cloner">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <TextField placeholder="Order Number" onChange={c => setOrderNumber(c.target.value)}
                         value={orderNumber}
                         helpText="OrderNbr"/>
            </div>
            <div className="col-md-6">
              <TextField placeholder="Count" onChange={c => setCount(c.target.value)}
                         value={count}
                         helpText="Count"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Select
                isClearable
                isSearchable
                onChange={(value) => value? setSource(value.label) : setSource('')}
                label="Source"
                options={mswOrdersServiceSourceEnvironments}
                value={{label: source, value: source}}
              />
            </div>
            <div className="col-md-6">
              <Select
                isClearable
                isSearchable
                onChange={(value) => value? setDestination(value.label) : setDestination('')}
                label="Destination"
                options={mswOrdersServiceDestinationEnvironments}
                value={{label: destination, value: destination}}
              />
            </div>
          </div>
          <div className="row">
            <Button className={button} variant="primary" blockLevel={true} onClick={cloneOrder}>
              <FontAwesomeIcon icon="clone" /> Clone
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default OrderCloner;
