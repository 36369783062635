import {
  Alert,
  Button,
  Card,
  Copy,
  FlexBox,
  InlineEdit, Label, Modal,
  TextField, Tooltip
} from '@cimpress/react-components';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  CreateSettingRequest,
  SettingMetadata,
  UpdateSettingRequest
} from '../types/settings-types';
import SettingHistory from './SettingHistory';
import {ModalAlert} from '../types/modal-alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import ReactTags from 'react-tag-autocomplete';
import {button, buttonDanger} from '../types/button-styles';

function SettingCard({ setting, tenant, allTags, prettyPrintTenant, deleteSetting, updateSetting, createSetting, showAlert } :
                       {setting: SettingMetadata, tenant?: string, allTags: string[],
                         prettyPrintTenant: (tenant: string) => string,
                         deleteSetting: (settingKey: string, changeReason: string) => void,
                         updateSetting: (settingKey: string, updateRequest: UpdateSettingRequest) => void,
                         createSetting: (settingKey: string, createRequest: CreateSettingRequest) => void,
                         showAlert: (alert: ModalAlert) => void
  }): ReactElement {

  const [isCheckingHistory, setIsCheckingHistory] = useState(false);
  const [changedSettingsValue, setChangedSettingsValue] = useState('' as string | undefined);
  const [changedSettingsDescription, setChangedSettingsDescription] = useState('' as string | undefined);
  const [changedTags, setChangedTags] = useState([] as string[]);
  const [showChangePrompt, setShowChangePrompt] = useState(false);
  const [changeReason, setChangeReason] = useState('');
  const [changeType, setChangeType] = useState('');
  const [isEditingValue, setIsEditingValue] = useState(false);

  useEffect(() => {
    setChangedSettingsValue(setting.value);
    setChangedSettingsDescription(setting.description);
    setChangedTags(setting.tags);
  }, [setting]);


  const saveSettingDescription = ({ value }: { value?: string }) => {
    setChangedSettingsDescription(value);
    startUpdatingSetting(changedSettingsValue, value, changedTags);
  };

  const saveSettingValue = ({ value }: { value?: string }) => {
    setChangedSettingsValue(value);
    startUpdatingSetting(value, changedSettingsDescription, changedTags);
  };

  const startUpdatingSetting = (value?: string, description?: string, tags?: string[]) => {
    if (setting.isInherited) {
      createSetting(setting.settingKey, {value: value ?? '', description: description ?? '', tags});
    } else {
      setChangeType('update');
      setShowChangePrompt(true);
    }
  };

  function validateSettingValue(settingValue: string): string | null {
    if (settingValue?.trim() !== settingValue) {
      return 'The value contains leading or trailing whitespace.';
    }
    return null;
  }

  const performSave = () => {
    if (changeType === 'delete'){
      deleteSetting(setting.settingKey, changeReason);
    } else if (changeType === 'update') {
      updateSetting(setting.settingKey, {value: changedSettingsValue, description: changedSettingsDescription, tags: changedTags, changeReason: changeReason} as UpdateSettingRequest);
    }
    setChangeReason('');
    setShowChangePrompt(false);
  };

  const getLastUsedComponent = (lastUsed?: string) => {
    let lastUsedText: [string, 'danger' | 'default' | 'info' | 'primary' | 'success' | 'warning']
      = ['Never', 'warning'];
    if (lastUsed) {
      const daysDifference = (new Date().getTime() - new Date(lastUsed).getTime()) / (1000 * 3600 * 24);
      lastUsedText = daysDifference > 365 ? ['Beyond 1 Year', 'warning'] :
        daysDifference > 182 ? ['Past Year', 'warning'] :
          daysDifference > 30 ? ['Past 6 Months', 'warning'] :
            daysDifference > 7 ? ['Past Month', 'primary'] :
              daysDifference > 1 ? ['Past Week', 'primary'] :
                ['Past Day', 'primary'];
    }
    return (
      <Tooltip contents={lastUsed ? new Date(lastUsed).toLocaleDateString() : 'Never'}>
        <Label text={<> <FontAwesomeIcon icon="glasses" /> {lastUsedText[0]}</> as any} status={lastUsedText[1]} size="default" />
      </Tooltip>
    );
  };

  return (
    <>
      <Card header={
        <div className='row'>
          <div className='col-sm-9 dont-break-out'>
            <Tooltip contents={<>
              <div>
                <h6>Setting Key: {setting.settingKey} <Copy variant="button"
                  value={setting.settingKey}><FontAwesomeIcon icon="copy" /> Copy</Copy></h6>
                <h6>Setting Value: {setting.value} <Copy variant="button"
                  value={setting.value}><FontAwesomeIcon icon="copy" /> Copy</Copy></h6>
              </div>
            </>} tooltipInnerStyle={{maxWidth: '40vw'}}>
              {setting.settingKey}
            </Tooltip>
          </div>
          <div className='col-sm-3'>
            <FlexBox className="button-group" justifyContent="flex-end">
              {!setting.isInherited &&
                <Button variant="primary"
                        className={buttonDanger}
                        size="sm" onClick={() => {
                          setShowChangePrompt(true);
                          setChangeType('delete');
                        }}>
                  <FontAwesomeIcon icon="trash" /> Delete Setting
                </Button>}
              <Button className={button} variant="primary" size="sm"
                      onClick={() => setIsCheckingHistory(true)}>
                <FontAwesomeIcon icon="history" /> Settings History
              </Button>
            </FlexBox>
          </div>
        </div>}>
        <div className='row'>
          <div className="col-10">
            <InlineEdit
              name={setting.settingKey}
              type="textarea"
              label='Description'
              value={changedSettingsDescription}
              onSave={saveSettingDescription}
            />
          </div>
          <div className="col-2">
            <FlexBox justifyContent="flex-end">
              {getLastUsedComponent(setting.lastUsed)}
            </FlexBox>
          </div>
        </div>
        <InlineEdit
          label="Setting Value"
          value={changedSettingsValue}
          onSave={saveSettingValue}
          validateInput={validateSettingValue}
          onEditStateChange={setIsEditingValue}
        />
        <div className="row">
          <div className="col-10">
            <ReactTags autoresize={false} tags={changedTags.map(tag => {
              return {
                id: tag,
                name: tag
              };
            })} allowNew onAddition={tag => {
              const newTags = [...changedTags];
              newTags.push(tag.name);
              setChangedTags(newTags);
            }} onDelete={tagIndex => {
              const newTags = [...changedTags];
              newTags.splice(tagIndex, 1);
              setChangedTags(newTags);
            }} placeholderText="Add a tag" suggestions={allTags.map(t => {return {name: t, id: t};})} minQueryLength={1} />
          </div>
          {
            !_.isEqual(setting.tags, changedTags) && <div className="col-2">
              <Button className={button} onClick={() => startUpdatingSetting(changedSettingsValue, changedSettingsDescription, changedTags)}>
              Save Tags
              </Button>
            </div>
          }
        </div>
        <div className="row">
          {
            setting.isInherited && isEditingValue &&
              <div className="col-12">
                <Alert status="info"
                       message="Editing this inherited setting will create a new setting in this tenant."
                       dismissible={false} />
              </div>
          }
          {
            setting.isInherited &&
            <div className="col-12">
              <strong>Inherited From:</strong> {prettyPrintTenant(setting.inheritedFrom)}
            </div>
          }
          {
            setting.overrides &&
            <div className="col-12">
              <strong>Overrides:</strong> {setting.overrides?.map(t => prettyPrintTenant(t))?.join(', ')}
            </div>
          }
          {
            setting.overriddenBy &&
            <div className="col-12">
              <strong>Overridden By:</strong> {setting.overriddenBy?.map(t => prettyPrintTenant(t))?.join(', ')}
            </div>
          }
        </div>
        <Modal
          status="info"
          show={showChangePrompt}
          onRequestHide={() => setShowChangePrompt(false)}
          title="Enter A Change Reason"
          closeButton={true}
          footer={(
            <div className="button-group">
              <Button onClick={performSave}>
                Save
              </Button>
              <Button onClick={() => setShowChangePrompt(false)}>
                Close
              </Button>
            </div>
          )}
        >
          <TextField
            name="simple"
            label="Change Reason"
            value={changeReason}
            onChange={e => setChangeReason(e.target.value)}
            autoFocus={true}
          />
        </Modal>
        <Modal
          status="info"
          show={isCheckingHistory}
          onRequestHide={() => setIsCheckingHistory(false)}
          title="Settings History"
          closeButton={true}
          size={'lg'}
        >
          <SettingHistory setting={setting.settingKey} tenant={setting.isInherited ? setting.inheritedFrom : tenant} isActive={isCheckingHistory} showAlert={showAlert}/>
        </Modal>
      </Card>
      <br/>
    </>
  );
}

export default SettingCard;
