import React, { ReactElement, useEffect, useState } from 'react';
import { Alert, Button, Card, FlexBox, Modal, TextField, Robot, Spinner } from '@cimpress/react-components';
import {
  deleteResourceWithBody,
  get,
  post, put
} from '../clients/AuthClient';
import {
  CreateSettingRequest,
  DeleteSettingRequest,
  SettingMetadata,
  TenantHierarchy, UpdateSettingRequest
} from '../types/settings-types';
import { ModalAlert } from '../types/modal-alert';
import SettingsServiceTenantSelector from '../components/SettingsServiceTenantSelector';
import SettingCard from '../components/SettingCard';
import { TenantMetadata } from '../types/tenant';
import { useHistory, useLocation } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';

function SettingsAdministration({ showAlert }: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [allTenants, setAllTenants] = useState([] as TenantHierarchy[]);
  const [allTags, setAllTags] = useState([] as string[]);
  const [tenantMetadata, setTenantMetadata] = useState([] as TenantMetadata[]);
  const [tenant, setTenant] = useState(undefined as string | undefined);
  const [currentSettings, setCurrentSettings] = useState([] as SettingMetadata[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingSetting, setIsCreatingSetting] = useState(false);
  const [newSettingKey, setNewSettingKey] = useState('');
  const [newSettingValue, setNewSettingValue] = useState('');
  const [newSettingDescription, setNewSettingDescription] = useState('');
  const [settingsFilter, setSettingsFilter] = useState(undefined as string | undefined);
  const [tagFilter, setTagFilter] = useState([] as string[]);

  const history = useHistory();
  const location = useLocation();

  function PrettyPrintTenant(tenant: string): string {
    const selectedTenantMetaData = tenantMetadata.find(t => t.tenant === tenant);
    const region = selectedTenantMetaData?.region ? `: ${selectedTenantMetaData.region}` : '';
    const plantName = selectedTenantMetaData?.plant_name ? `: ${selectedTenantMetaData.plant_name}` : '';
    const environment = selectedTenantMetaData?.environment ? ` (${selectedTenantMetaData.environment})` : '';
    return selectedTenantMetaData ? `${selectedTenantMetaData.business_unit}${region}${plantName}${environment}` : tenant;
  }

  function DeleteSetting(settingKey: string, changeReason: string): void {
    setIsLoading(true);
    deleteResourceWithBody(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/settings/${tenant}/${settingKey}`, { changeReason: changeReason } as DeleteSettingRequest).then(() => {
      tenantSelectionChange(tenant);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Settings Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function UpdateSetting(settingKey: string, updateRequest: UpdateSettingRequest): void {
    setIsLoading(true);
    put(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/settings/${tenant}/${settingKey}`, updateRequest).then(() => {
      tenantSelectionChange(tenant);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Settings Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function CreateSetting(settingKey: string, createRequest: CreateSettingRequest): void {
    setIsLoading(true);
    post(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/settings/${tenant}/${settingKey}`, createRequest).then(() => {
      tenantSelectionChange(tenant);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Settings Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }


  useEffect(() => {
    Promise.all([get<TenantHierarchy[]>(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/tenants/hierarchy`),
      get<TenantMetadata[]>(`${process.env.REACT_APP_TENANT_SERVICE_URL}/tenants/metadata`)]).then(tenants => {
      // order matters!
      setTenantMetadata(tenants[1]);
      setAllTenants(tenants[0]);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an error from the Settings Service',
        type: 'danger'
      });
    });
  }, []);

  const tenantSelectionChange = (tenant: string | undefined) => {
    setTenant(tenant);
    if (tenant) {
      Promise.all([get<SettingMetadata[]>(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/settings/${tenant}`),
        get<string[]>(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/tags`)])
        .then(result => {
          setCurrentSettings(result[0]);
          setAllTags(result[1]);

          const queryParams = new URLSearchParams(location.search);
          if (queryParams.has('settingFilter')) {
            setSettingsFilter(queryParams.get('settingFilter') ?? '');
          }
        }).catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an error from the Settings Service',
            type: 'danger'
          });
        });
    }
  };

  const settingsFilterChanged = (filter: string) => {
    setSettingsFilter(filter);
    const queryParams = new URLSearchParams(location.search);
    if (!filter) {
      queryParams.delete('settingFilter');
    } else {
      queryParams.set('settingFilter', filter);
    }
    history.push({
      search: queryParams.toString()
    });
  };

  const createNewSetting = () => {
    setIsLoading(true);
    post(`${process.env.REACT_APP_SETTINGS_SERVICE_URL}/settings/${tenant}/${newSettingKey}`, { value: newSettingValue, description: newSettingDescription } as CreateSettingRequest).then(() => {
      tenantSelectionChange(tenant);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Settings Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
      setIsCreatingSetting(false);
      setNewSettingKey('');
      setNewSettingValue('');
      setNewSettingDescription('');
    });
  };

  const tagFilterChange = (tagId: string) => {
    const newTags = [...tagFilter];
    if (newTags.includes(tagId)) {
      _.remove(newTags, t => t === tagId);
    } else {
      newTags.push(tagId);
    }
    setTagFilter(newTags);
  };

  const getChipsForTags = (tags: string[]): ReactElement => {
    return (<FlexBox className="button-group" flexWrap="wrap" justifyContent="initial">
        {
          tags.map(t => {
            return <Button size="sm"
                           style={{
                             fontSize: '12px',
                             fontWeight: 500,
                             borderRadius: '16px',
                             border: '1px solid rgba(0, 0, 0, 0.25)'
                           }}
                           onClick={() => tagFilterChange(t)}
                           key={t}>
              {tagFilter.includes(t) && <span><FontAwesomeIcon icon="check" /> </span>}{t}
            </Button>;
          })
        }
      </FlexBox>
    );
  };

  useEffect(() => {
    document.title = 'Settings Administration';
  }, []);

  return (
    <div className="container">
      <Card header="Settings Administration">
        <div className="col-md-12">
          <SettingsServiceTenantSelector tenantSelected={tenantSelectionChange} tenantHierarchy={allTenants} prettyPrintTenant={PrettyPrintTenant} />
        </div>
      </Card>
      <br />
      {!tenant ?
        <Card>
          <FlexBox justifyContent="center">
            <Robot status="warning" size="lg" />
          </FlexBox>
          <Alert
            status="info"
            message="Select a tenant to edit settings"
            dismissible={false}
          />
        </Card> :
        <>
          <Card>
            <div className="row">
              <div className="col-12">
                <TextField placeholder="Filter Settings" onChange={c => settingsFilterChanged(c.target.value)} value={settingsFilter} rightAddon={
                  <Button onClick={() => setIsCreatingSetting(true)}> <FontAwesomeIcon icon="plus" /> Add Setting</Button>
                } />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {getChipsForTags(allTags)}
              </div>
            </div>
            <br />
            {currentSettings
              .filter(setting => {
                const filter = settingsFilter?.toLowerCase() ?? '';
                const textFilter = setting.settingKey.toLowerCase().includes(filter) || setting.description.toLowerCase().includes(filter)
                  || setting.value.toLowerCase().includes(filter);
                const tagFiler = tagFilter.length === 0 ? true : tagFilter.every(t => setting.tags.includes(t));
                return textFilter && tagFiler;
              })
              .map(setting => <SettingCard setting={setting} tenant={tenant} allTags={allTags} prettyPrintTenant={PrettyPrintTenant} key={setting.settingKey} deleteSetting={DeleteSetting} updateSetting={UpdateSetting} createSetting={CreateSetting} showAlert={showAlert} />)}
          </Card>
        </>}
      <Modal
        status="info"
        show={isCreatingSetting}
        onRequestHide={() => setIsCreatingSetting(false)}
        title="Add Setting"
        closeButton={true}
        footer={(
          <div className="button-group">
            <Button onClick={() => createNewSetting()}>
              Save
            </Button>
            <Button onClick={() => setIsCreatingSetting(false)}>
              Close
            </Button>
          </div>
        )}
      >
        <form onSubmit={() => createNewSetting()}>
          <TextField
            name="simple"
            label="New Setting Key"
            value={newSettingKey}
            onChange={e => setNewSettingKey(e.target.value)}
            autoFocus={true}
          />
          <TextField
            name="simple"
            label="New Value"
            value={newSettingValue}
            onChange={e => setNewSettingValue(e.target.value)}
          />
          <TextField
            name="simple"
            label="New Description"
            value={newSettingDescription}
            onChange={e => setNewSettingDescription(e.target.value)}
          />
        </form>
        {isLoading &&
          <FlexBox justifyContent="center">
            <Spinner size='medium' />
          </FlexBox>
        }
      </Modal>
    </div >
  );
}

export default SettingsAdministration;
