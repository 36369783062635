import {ModalAlert} from '../types/modal-alert';
import React, {ReactElement, useEffect, useState} from 'react';
import {
  Alert,
  Card,
  FlexBox,
  Robot,
} from '@cimpress/react-components';
import {auth} from '../Auth';
import {get} from '../clients/AuthClient';
import SAPBarcodeGenerator from '../components/SAPBarcodeGenerator';
import VLRAFilenameTester from '../components/VLRAFilenameTester';
import OrderCloner from '../components/OrderCloner';
import PrintJobStatusUpdater from '../components/PrintJobStatusUpdater';
import ConduitMonitor from '../components/ConduitMonitor';

function PressIntTools({showAlert} : {showAlert: (alert: ModalAlert) => void}): ReactElement {
  const [isMisDeveloper, setIsMisDeveloper] = useState(false);
  const [isMipiUser, setIsMipiUser] = useState(false);

  useEffect(() => {
    document.title = 'Press Int Tools';
  }, []);

  useEffect(() => {
    if (auth.getAccessToken()) {
      get<any>('https://api.cimpress.io/auth/access-management/v1/principals/self/groups').then(response => {
        if (response.groups.some((g: any) => g.id.toString() === '161107')) {
          setIsMisDeveloper(true);
        }
        if (response.groups.some((g: any) => g.id.toString() === '3268')) {
          setIsMipiUser(true);
        }
      }).catch(() => {
        setIsMisDeveloper(false);
        setIsMipiUser(false);
      });
    }
  }, [auth.getAccessToken()]);

  return (<>
    {isMisDeveloper ?
      <div className="container-fluid">
        <SAPBarcodeGenerator showAlert={showAlert}/>
        <br/>
        <OrderCloner showAlert={showAlert}/>
        <br/>
        <PrintJobStatusUpdater showAlert={showAlert}/>
        <br/>
        <VLRAFilenameTester showAlert={showAlert}/>
        <br/>
        {isMipiUser ? <ConduitMonitor showAlert={showAlert}/> : <></>}
        <br/>
      </div> :
      <Card>
        <FlexBox justifyContent="center">
          <Robot status="danger" size="lg" />
        </FlexBox>
        <Alert
          status="danger"
          message="You must be an MIS Developer to use this page."
          dismissible={false}
        />
      </Card>}
  </>);
}

export default PressIntTools;
