import {ModalAlert} from '../types/modal-alert';
import React, {ReactElement, useEffect, useState} from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  FlexBox,
  Select,
  TextField,
  Robot,
  Spinner,
} from '@cimpress/react-components';
import TenantSelector from '../components/TenantSelector';
import {get} from '../clients/AuthClient';
import {
  DecorationTechnology,
  Equipment, LineWithName,
  TenantPermission
} from '../types/equipment';
import {SelectOption} from '../types/select';
import EquipmentCard from '../components/EquipmentCard';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useHistory, useLocation} from 'react-router-dom';

function EquipmentAdministration({showAlert}: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [tenant, setTenant] = useState('');
  const [tenantPermissions, setTenantPermissions] = useState([] as TenantPermission[]);
  const [equipments, setEquipments] = useState([] as Equipment[]);
  const [selectedDecorationTechnology, setSelectedDecorationTechnology] = useState(null as SelectOption | null);
  const [allDecorationTechnologies, setAllDecorationTechnologies] = useState([] as DecorationTechnology[]);
  const [allLines, setAllLines] = useState([] as LineWithName[]);
  const [filter, setFilter] = useState('');
  const [sortOption, setSortOption] = useState('number' as 'number' | 'name');
  const [sortAscending, setSortAscending] = useState(true);
  const [isAdding, setIsAdding] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = 'Equipment Administration';
  }, []);

  const refresh = (): Promise<void> => {
    if (tenant) {
      setIsLoading(true);
      const queryParam = selectedDecorationTechnology ? `?decorationTechnology=${selectedDecorationTechnology.value}` : '';
      return get<Equipment[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v1/Tenant/${tenant}/Equipment${queryParam}`)
        .then(setEquipments)
        .catch(e => {
          console.error(e);
          showAlert({
            message: e.message,
            title: 'Received an error from the equipment service',
            type: 'danger'
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      setEquipments([]);
      return Promise.resolve();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([get<TenantPermission[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/v1/Tenant/Permissions`),
      get<DecorationTechnology[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/DecorationTechnologies`)]).then(info => {
        setTenantPermissions(info[0]);
        setAllDecorationTechnologies(_.orderBy(info[1], [d => d.name === 'generic', d => d.name], ['desc', 'asc']));

      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('filter')) {
        filterChanged(queryParams.get('filter') ?? '');
      }

      if (queryParams.has('decorationTechnology')) {
        const dt = info[1].find(dt => dt.name === queryParams.get('decorationTechnology'));
        if (dt) {
          setSelectedDecorationTechnology({
            value: dt.name,
            label: _.startCase(dt.name)
          });
        }
      }

    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an error from the equipment service',
        type: 'danger'
      });
    })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    refresh();
  }, [tenant, selectedDecorationTechnology]);

  useEffect(() => {
    if (!tenant) {
      setAllLines([]);
      return;
    }
    get<LineWithName[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/Lines?tenant=${tenant}`).then(lines => {
      setAllLines(lines);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an error from the equipment service',
        type: 'danger'
      });
    })
      .finally(() => setIsLoading(false));
  }, [tenant]);

  const includeTenant = (tenant: string): boolean => {
    return tenantPermissions ? tenantPermissions.some(t => t.tenant === tenant) : false;
  };

  const decorationTechnologySelectionChange = (selectedDecorationTechnology: any) => {
    setSelectedDecorationTechnology(selectedDecorationTechnology);
    const queryParams = new URLSearchParams(location.search);
    if (!selectedDecorationTechnology) {
      queryParams.delete('decorationTechnology');
    } else {
      queryParams.set('decorationTechnology', selectedDecorationTechnology.value);
    }
    history.push({
      search: queryParams.toString()
    });
  };

  const setSort = (sortType: 'number' | 'name') => {
    if (sortType === sortOption) {
      setSortAscending(!sortAscending);
    } else {
      setSortOption(sortType);
    }
  };

  const filterChanged = (filter: string) => {
    const lowerFilter = filter.toLowerCase();
    setFilter(lowerFilter);
    const queryParams = new URLSearchParams(location.search);
    if (!lowerFilter) {
      queryParams.delete('filter');
    } else {
      queryParams.set('filter', lowerFilter);
    }
    history.push({
      search: queryParams.toString()
    });
  };


  return (
    <>
      {isLoading &&
      <FlexBox justifyContent="center">
        <Spinner size="medium"/>
      </FlexBox>
      }
      <div className="container">
        <Card header="Equipment Administration">
          <div className="row">
            <div className="col-md-6">
              <TenantSelector tenantSelected={setTenant} includeTenant={includeTenant}/>
            </div>
            <div className="col-md-6">
              <Select
                isClearable
                label="Select Decoration Technology"
                isDisabled={!tenant}
                value={selectedDecorationTechnology}
                options={allDecorationTechnologies.map(dt => {
                  return {
                    label: _.startCase(dt.name),
                    value: dt.name
                  };
                })}
                onChange={decorationTechnologySelectionChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField placeholder="Filter" onChange={c => filterChanged(c.target.value)}
                         value={filter}
                         disabled={!tenant}
                         rightAddon={<Button onClick={() => setIsAdding(true)} disabled={!tenant}> <FontAwesomeIcon icon="plus" /> Add Equipment</Button>}
                         helpText="Search for Equipments"
              />
            </div>
          </div>
        </Card>
        <br/>
        {isAdding &&
        <>
          <EquipmentCard lines={allLines} tenant={tenant}
                         decorationTechnologies={allDecorationTechnologies} showAlert={showAlert}
                         refreshAll={refresh} finishedAdding={() => setIsAdding(false)}
                         defaultNewDecorationTechnology={selectedDecorationTechnology?.value}
          />
        </>
        }
        <div className="row">
          <div className="col-md-12">
            <ButtonGroup style={{ width: 'inherit' }} variant="justified">
              <Button style={{ minWidth: '50%' }} variant={sortOption === 'number' ? 'primary' : 'default'}
                      onClick={() => setSort('number')} disabled={!tenant}>
                Sort by Number {sortAscending ? <FontAwesomeIcon icon="sort-numeric-up" /> : <FontAwesomeIcon icon="sort-numeric-down" />}
              </Button>
              <Button style={{ minWidth: '50%' }}
                      variant={sortOption === 'name' ? 'primary' : 'default'}
                      onClick={() => setSort('name')} disabled={!tenant}>
                Sort by Name {sortAscending ? <FontAwesomeIcon icon="sort-alpha-up" /> : <FontAwesomeIcon icon="sort-alpha-down" />}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <br/>
        {_.orderBy(equipments.filter(e => {
          if (!filter) {
            return true;
          }
          const lineNbrsThatMatch = allLines.filter(l => l.lineName.toLowerCase().includes(filter))
            .map(l => l.lineNbr);
          return e.name.toLowerCase().includes(filter) || e.decorationTechnology.toLowerCase().includes(filter) ||
            e.equipmentNbr.toString().includes(filter) || e.equipmentId.toLowerCase().includes(filter) ||
            e.properties.find(p => p.name.toLowerCase().includes(filter) || (!!p.value && p.value.toLowerCase().includes(filter))) ||
            e.lines.find(l => lineNbrsThatMatch.includes(l.lineNbr) || (!!l.lineNbr && l.lineNbr.toString().includes(filter)));
        }), e => {
          switch (sortOption) {
            case 'name':
              return e.name;
            case 'number':
              return e.equipmentNbr;
          }
        }, [sortAscending ? 'asc' : 'desc'])
          .map(equipment => <EquipmentCard equipment={equipment} tenant={tenant} lines={allLines}
                                           decorationTechnologies={allDecorationTechnologies}
                                           showAlert={showAlert} key={equipment.equipmentId}
                                           refreshAll={refresh} />)}
        {!tenant &&
        <Card>
          <FlexBox justifyContent="center">
            <Robot status="warning" size="lg"/>
          </FlexBox>
          <Alert
            status="info"
            message="Select a tenant to edit configuration"
            dismissible={false}
          />
        </Card>
        }
      </div>
    </>
  );
}

export default EquipmentAdministration;
