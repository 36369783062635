import { get } from '../clients/AuthClient';
import React, { useEffect, useState } from 'react';
import { ReactElement } from 'react';
import { SelectOption } from '../types/select';
import { TenantMetadata } from '../types/tenant';
import { Select } from '@cimpress/react-components';
import { useHistory, useLocation } from 'react-router';
import _ from 'lodash';

export type TenantDisplayMetadata = TenantMetadata & {prettyTenant: string};

function TenantSelector({tenantSelected, setTenantMetadata, includeTenant, setPrettyTenant, setTenants, selectionCleared} : {tenantSelected?: (tenant: string) => void,
  setTenantMetadata?: (tenant: TenantDisplayMetadata) => void,
  includeTenant?: (tenant: string) => boolean, setPrettyTenant?: (prettyTenant: string) => void,
  setTenants?: (tenants: TenantDisplayMetadata[]) => void,
  selectionCleared?: () => void}): ReactElement {
  const [allTenants, setAllTenants] = useState([] as (SelectOption & TenantDisplayMetadata)[]);
  const [filteredTenants, setAllFilteredTenants] = useState([] as SelectOption[]);
  const [selectedTenant, setTenant] = useState(null as unknown as SelectOption);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const newFilteredTenants = includeTenant ? allTenants.filter(t => includeTenant(t.value)) : allTenants;
    setAllFilteredTenants(newFilteredTenants);
    if (setTenants) {
      setTenants(newFilteredTenants);
    }
  }, [allTenants, includeTenant]);

  useEffect(() => {
    if (filteredTenants.length === 1) {
      setTenant(filteredTenants[0]);
    }
  }, [filteredTenants]);

  useEffect(() => {
    get<TenantMetadata[]>(`${process.env.REACT_APP_TENANT_SERVICE_URL}/tenants/metadata`).then(tenants => {
      const tenantOptions = tenants.filter(t => t.plant_name).map(t => {
        return {
          ...t,
          prettyTenant: `${t.business_unit}: ${t.plant_name} (${t.environment})`,
          value: t.tenant,
          label: `${t.business_unit}: ${t.plant_name} (${t.environment})`
        };
      });
      const environmentOrder = ['prod', 'test', 'dev'];

      setAllTenants(_.orderBy(tenantOptions, [t => t.business_unit, t => t.plant_name, t => environmentOrder.indexOf(t.environment)]));
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('tenant')) {
        const querySelectedTenant = tenantOptions.find(t => t.value === queryParams.get('tenant'));
        if (querySelectedTenant) {
          tenantSelectionChange(querySelectedTenant);
        }
      }
    }).catch(e => {
      console.error(e);
    });
  }, []);

  const tenantSelectionChange = (selectedTenant: any) => {
    setTenant(selectedTenant);
    const tenantValue = selectedTenant?.value;
    if (!tenantValue) {
      selectionCleared && selectionCleared();
    }
    tenantSelected && tenantSelected(tenantValue);
    setPrettyTenant && setPrettyTenant(selectedTenant?.label);
    setTenantMetadata && setTenantMetadata(selectedTenant);
    const queryParams = new URLSearchParams(location.search);
    if (!tenantValue) {
      queryParams.delete('tenant');
    } else {
      queryParams.set('tenant', selectedTenant?.value);
    }
    history.push({
      search: queryParams.toString()
    });
  };

  return (
    <Select
      isClearable
      label="Select tenant"
      value={selectedTenant}
      options={filteredTenants}
      onChange={tenantSelectionChange}
    />
  );
}

export default TenantSelector;
