import React, {ReactElement} from 'react';
import {Button, Select, TextField} from '@cimpress/react-components';
import * as _ from 'lodash';
import {Condition} from '../types/pipeline';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {buttonDanger} from '../types/button-styles';

function FileNameCondition({condition, updateStepCondition, removeSelf, disabled}:
  { condition: Condition, updateStepCondition: (condition: Condition) => void, removeSelf: () => void, disabled: boolean }): ReactElement {
  const possibleOperatorTypes = ['endsWith', 'startsWith', 'contains', 'matchesRegex'];

  const onOperatorChange = (value: any): void => {
    const newCondition = _.cloneDeep(condition);
    newCondition.operatorType = value;
    updateStepCondition(newCondition);
  };

  const onFilenameChange = (value: any): void => {
    const newCondition = _.cloneDeep(condition);
    newCondition.value = value;
    updateStepCondition(newCondition);
  };

  return (
    <div className="row">
      {!disabled && <div className="col-md-1">
        <Button className={buttonDanger} onClick={removeSelf}><FontAwesomeIcon icon="trash"/></Button>
      </div>}
      <div className={disabled ? 'col-md-6' : 'col-md-5'}>
        <Select
          isClearable
          label="Select File Name Operator"
          isDisabled={disabled}
          value={condition.operatorType ? {
            label: _.startCase(condition.operatorType),
            value: condition.operatorType
          } : undefined}
          options={possibleOperatorTypes.map(ot => {
            return {
              label: _.startCase(ot),
              value: ot
            };
          })}
          onChange={(value) => {
            onOperatorChange(value?.value);
          }}
        />
      </div>
      <div className="col-md-6">
        <TextField
          name="simple"
          label="Value"
          value={condition.value}
          onChange={e => onFilenameChange(e.target.value)}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default FileNameCondition;
