import { ModalAlert } from '../types/modal-alert';
import React, { ReactElement, useEffect, useState } from 'react';
import { get } from '../clients/AuthClient';
import {Pipeline, PipelineEditMode, PipelineTag, StepDefinition} from '../types/pipeline';
import PipelineList from '../components/PipelineList';
import EditPipeline from '../components/EditPipeline';
import ReorderPipelines from '../components/ReorderPipelines';
import ManagePipelineTags from '../components/ManagePipelineTags';
import { FlexBox, Spinner } from '@cimpress/react-components';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { FormattedPropertyInfo, PropertyBag, PropertyInfo } from '../types/property-info';
import { DecorationTechnology, LineWithName } from '../types/equipment';
import PipelinePropertyOverview from '../components/PipelinePropertyOverview';
import {TenantDisplayMetadata} from '../components/TenantSelector';
import {TenantMetadata} from '../types/tenant';
import {MaconDevice} from '../types/macon-device';

export type TenantPropertyBags = {tenant: string, propertyBags: PropertyBag[], variablePropertyBags: PropertyBag[]};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

function PipelineManager({ showAlert }: { showAlert: (alert: ModalAlert) => void }): ReactElement {
  const [tenantMetadata, setTenantMetadata] = useState(null as TenantDisplayMetadata | null);
  const [isLoading, setIsLoading] = useState(false);
  const [pipelines, setPipelines] = useState([] as Pipeline[]);
  const [pipelineTags, setPipelineTags] = useState([] as PipelineTag[]);
  const [editingPipeline, setEditingPipeline] = useState(null as Pipeline | null);
  const [isManagingTags, setIsManagingTags] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [showPropertyOverview, setShowPropertyOverview] = useState(false);
  const [propertyBags, setPropertyBags] = useState([] as PropertyBag[]);
  const [variablePropertyBags, setVariablePropertyBags] = useState([] as PropertyBag[]);
  const [stepDefinitions, setStepDefinitions] = useState([] as StepDefinition[]);
  const [fileTypes, setFileTypes] = useState([] as string[]);
  const [tenants, setTenants] = useState([] as TenantDisplayMetadata[]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    document.title = 'Pipeline Manager';
  }, []);

  useEffect(() => {
    refreshPipelines();
  }, [tenantMetadata]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedPipelineId = queryParams.get('pipelineId');
    if (selectedPipelineId) {
      startEditing(selectedPipelineId);
    }
  }, [pipelines]);

  const refreshPipelines = (): Promise<void> => {
    if (!tenantMetadata) {
      setPipelines([]);
      return Promise.resolve();
    }
    setIsLoading(true);
    return Promise.all([getPipelines(tenantMetadata.tenant),
    get<PipelineTag[]>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata.tenant}/pipelines/tags`), refreshPropertyBags(),
    get<StepDefinition[]>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenantMetadata.tenant}/steps/definitions`),
    get<string[]>(`${process.env.REACT_APP_PRESSINTEGRATION_SERVICE_URL}/v1/Tenant/${tenantMetadata.tenant}/JobContainer/FileTypes`)]).then(info => {
      setPipelines(info[0]);
      setPipelineTags(info[1]);
      setStepDefinitions(info[3]);
      setFileTypes(info[4]);
    }).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: 'Received an Error from the Pipelines Service',
        type: 'danger'
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getPipelines = (tenant: string): Promise<Pipeline[]> => {
    return get<Pipeline[]>(`${process.env.REACT_APP_PIPELINES_SERVICE_URL}/v1/tenant/${tenant}/pipelines`);
  };

  const getEquipmentPropertyBag = (): Promise<PropertyBag> => {
    return get<DecorationTechnology[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/DecorationTechnologies`)
      .then(decorationTechnologies =>  {
        return {
          propertyBagName: 'equipmentData',
          properties: _.sortBy(decorationTechnologies.flatMap(dt => {
            return dt.optionalProperties.concat(dt.requiredProperties).map(prop => {
              return {
                propertyName: `${dt.name}.${prop.name}`,
                type: prop.possibleValues ? 'enum' : prop.type,
                displayValues: prop.possibleValues ? prop.possibleValues.map(v => {
                  return {
                    value: v,
                    display: v
                  };
                }) : undefined
              };
            }).concat({
              propertyName: `${dt.name}.Exists`,
              type: 'bool',
              displayValues: undefined
            });
          }), p => p.propertyName)
        };
      });
  };

  const getMetadataPropertyBag = (): PropertyBag => {
    return {
      propertyBagName: 'metadata',
      properties: [
        {
          propertyName: 'type',
          type: 'enum',
          displayValues: [
            {
              display: 'rip',
              value: 'rip'
            },
            {
              display: 'send-to-press',
              value: 'send-to-press'
            }
          ]
        }
      ]
    };
  };

  const getSchedulingPropertyBag = async (tenant: string): Promise<PropertyBag> => {
    const lines = await get<LineWithName[]>(`${process.env.REACT_APP_EQUIPMENT_SERVICE_URL}/Lines?tenant=${tenant}`);
    return {
      propertyBagName: 'scheduling',
      properties: [
        {
          propertyName: 'lineNbr',
          type: 'enum',
          displayValues: lines.map(l => {
            return {
              value: l.lineNbr.toString(),
              display: l.lineName
            };
          })
        },
        {
          propertyName: 'scheduledStartTime',
          type: 'string'
        },
      ]
    };
  };

  const getGangProductionDataProperties = async (): Promise<FormattedPropertyInfo[]> => {
    const gangProperties = await get<PropertyInfo>(`${process.env.REACT_APP_GANG_DEFINITION_SERVICE_URL}/v1/propertyInfo`);
    gangProperties['PrintQuantity'] = { type: 'integer' };
    return Object.keys(gangProperties).map(property => {
        return {
          propertyName: property,
          type: gangProperties[property].type,
          displayValues: gangProperties[property].displayValues?.map(v => {
            return {
              value: v.value.toString(),
              display: v.display
            };
          })
        };
      }
    );
  };

  const getViperPropertyBags = (tenants: string[]): Promise<TenantPropertyBags[]> => {
    const schedulingPromise = Promise.all(tenants.map(tenant => getSchedulingPropertyBag(tenant).then(propertyBag => {return {tenant, propertyBag};}).catch(e => {
      console.error(e);
      showAlert({
        message: e.message,
        title: `Failed to retrieve lines for ${tenant}`,
        type: 'danger'
      });
      return {
        tenant,
        propertyBag: {
          propertyBagName: 'scheduling',
          properties: []
        }
      };
    })));
    return Promise.all([getGangProductionDataProperties(), schedulingPromise, getEquipmentPropertyBag()])
      .then(results => {
        const gangProperties = results[0];
        const schedulingPropertyBags = results[1];
        const equipmentPropertyBag = results[2];
        // only allow enum and boolean types to be used as filters
        const enumAndBoolGangPropertyBag: PropertyBag = {
          propertyBagName: 'gangProductionData',
          properties: gangProperties.filter(property => property.type === 'enum' || property.type === 'boolean')
        };
        // allow all property types to be used as pipeline variables
        const allGangPropertyBag: PropertyBag = {
          propertyBagName: 'gangProductionData',
          properties: gangProperties
        };
        // Display values can be used as pipeline variables only -> should use the actual enum number
        // value for property bag filters
        const displayValueGangPropertyBag: PropertyBag = {
          propertyBagName: 'gangProductionDataDisplay',
          properties: gangProperties.filter(property => property.type === 'enum')
        };
        return tenants.map(tenant => {
          const schedulingPropertyBag = schedulingPropertyBags.find(p => p.tenant === tenant)?.propertyBag;
          return {
            tenant,
            propertyBags: [enumAndBoolGangPropertyBag, schedulingPropertyBag, equipmentPropertyBag, getMetadataPropertyBag()],
            variablePropertyBags: [allGangPropertyBag, displayValueGangPropertyBag, schedulingPropertyBag, equipmentPropertyBag, getMetadataPropertyBag()]
          };
        });
      }).catch(e => {
        console.error(e);
        showAlert({
          message: e.message,
          title: 'Received an Error',
          type: 'danger'
        });
        return e;
      });
  };

  const getPaperlessPropertyBags = async (tenants: TenantMetadata[]): Promise<TenantPropertyBags[]> => {
    const devices = await Promise.all(tenants.map(async t => {
      try {
        return {
          t,
          devices: await get<MaconDevice[]>(`${process.env.REACT_APP_MACON_SERVICE_URL}/v1/machines?accountId=${t.fulfillment_location_id}`)
        };
      } catch (e) {
        console.error(e);
        showAlert({
          message: e.message,
          title: `Failed to retrieve devices for ${t}`,
          type: 'danger'
        });
        return {
          t,
          devices: []
        };
      }
    }));
    return tenants.map(tenant => {
      const tenantDevices = devices.find(d => d.t.tenant === tenant.tenant)?.devices ?? [];
      const schedulingPropertyBag: PropertyBag = {
        propertyBagName: 'scheduling',
        properties: [
          {
            propertyName: 'deviceId',
            type: 'enum',
            displayValues: tenantDevices.map(device => {
              return {
                value: device.machineId,
                display: device.name
              };
            })
          }
        ]
      };
      const deviceParameters = _.uniq(tenantDevices.flatMap(d => d.parameters ?? []).map(p => p.key));
      deviceParameters.sort((a, b) => a.localeCompare(b));
      const schedulingVariablePropertyBag: PropertyBag = {
        propertyBagName: 'scheduling',
        properties: deviceParameters.map(p => {
          return {
            propertyName: p,
            type: 'string'
          };
        })
      };
      return {
        tenant: tenant.tenant,
        propertyBags: [schedulingPropertyBag],
        variablePropertyBags: [schedulingVariablePropertyBag]
      };
    });
  };

  const getPropertyBags = async (tenants: TenantDisplayMetadata[]): Promise<TenantPropertyBags[]> => {
    const viperTenants = tenants.filter(t => t.production_system_backend === 'viper');
    const viperPropertyBags = viperTenants.length > 0 ? await getViperPropertyBags(viperTenants.map(t => t.tenant)) : [];
    const paperlessTenants = tenants.filter(t => t.production_system_backend === 'paperless');
    const paperlessPropertyBags = paperlessTenants.length > 0 ? await getPaperlessPropertyBags(paperlessTenants) : [];
    return [...viperPropertyBags, ...paperlessPropertyBags];
  };


  const refreshPropertyBags = (): Promise<void> => {
    if (!tenantMetadata) {
      return Promise.resolve();
    }
    return getPropertyBags([tenantMetadata]).then(propertyBags => {
      setPropertyBags(propertyBags[0].propertyBags);
      setVariablePropertyBags(propertyBags[0].variablePropertyBags);
    });
  };

  const startEditing = (id: string): void => {
    scrollToTop();
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('pipelineId', id);
    history.push({
      search: queryParams.toString()
    });
    const selectedPipeline = pipelines?.find(p => p.id === id);
    if (selectedPipeline) {
      setEditingPipeline(selectedPipeline);
    }
  };

  const stopEditing = (): void => {
    scrollToTop();
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('pipelineId');
    history.push({
      search: queryParams.toString()
    });
    setEditingPipeline(null);
  };

  const startAdding = (pipeline: Pipeline): void => {
    scrollToTop();
    const newPipeline = _.cloneDeep(pipeline);
    newPipeline.id = '';
    newPipeline.name = '';
    setEditingPipeline(newPipeline);
  };

  const duplicate = (id: string): void => {
    scrollToTop();
    const existingPipeline = _.cloneDeep(pipelines.find(p => p.id === id));
    if (existingPipeline) {
      existingPipeline.id = '';
      existingPipeline.name = '';
      setEditingPipeline(existingPipeline);
    }
  };

  const startReordering = (): void => {
    scrollToTop();
    setIsReordering(true);
  };

  const stopReordering = (): void => {
    scrollToTop();
    setIsReordering(false);
  };

  const startManagingTags = (): void => {
    scrollToTop();
    setIsManagingTags(true);
  };

  const stopManagingTags = (): void => {
    scrollToTop();
    setIsManagingTags(false);
  };

  return (
    <>
      {isLoading &&
        <FlexBox justifyContent="center">
          <Spinner size="medium" />
        </FlexBox>
      }
      {editingPipeline &&
        <div className="container">
          <EditPipeline pipeline={editingPipeline} pipelineTags={pipelineTags} propertyBags={propertyBags} variablePropertyBags={variablePropertyBags} stepDefinitions={stepDefinitions} fileTypes={fileTypes} tenant={tenantMetadata?.tenant}
            showAlert={showAlert} setIsLoading={setIsLoading}
            stopEditing={stopEditing} refreshPipelines={refreshPipelines}
            editingMode={editingPipeline.id ? PipelineEditMode.edit : PipelineEditMode.add} />
        </div>
      }
      {isReordering &&
        <ReorderPipelines pipelines={pipelines}
          tenant={tenantMetadata?.tenant}
          prettyTenant={tenantMetadata?.prettyTenant}
          refreshPipelines={refreshPipelines}
          stopReordering={stopReordering}
          showAlert={showAlert}
          setIsLoading={setIsLoading} />
      }
      {isManagingTags &&
        <ManagePipelineTags tags={pipelineTags} prettyTenant={tenantMetadata?.prettyTenant} refreshPipelines={refreshPipelines} stopManagingTags={stopManagingTags}
          setIsLoading={setIsLoading} tenant={tenantMetadata?.tenant} showAlert={showAlert} />
      }
      {
        showPropertyOverview &&
          <PipelinePropertyOverview getPipelines={getPipelines} getPropertyBags={getPropertyBags} tenants={tenants} exitPropertyOverview={() => setShowPropertyOverview(false)} />
      }
      {!editingPipeline && !isReordering && !isManagingTags && !showPropertyOverview &&
        <PipelineList tenantMetadata={tenantMetadata} setTenantMetadata={setTenantMetadata} pipelines={pipelines} pipelineTags={pipelineTags} propertyBags={propertyBags} variablePropertyBags={variablePropertyBags}
                      stepDefinitions={stepDefinitions} fileTypes={fileTypes}
                      startEditing={startEditing} startAdding={startAdding} duplicate={duplicate}
                      startReordering={startReordering} setTenants={setTenants} startManagingTags={startManagingTags}
                      setIsLoading={setIsLoading} refreshPipelines={refreshPipelines} showAlert={showAlert} openPipeline={setEditingPipeline}
                      showPropertyOverview={() => setShowPropertyOverview(true)}/>
      }
    </>
  );
}

export default PipelineManager;

